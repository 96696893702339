import { Component, Input, TemplateRef, ViewChild } from "@angular/core"
import { Title } from "@angular/platform-browser"
import { ActivatedRoute, Router } from "@angular/router"
import { ModalController, PopoverController } from "@ionic/angular"
import { captureException } from "@sentry/angular"
import { isEqual } from "lodash-es"
import { Subscription } from "rxjs"
import { TimeQuerySettings } from "src/app/menus"
import { Asset, Device, EntityId } from "src/app/models/entity.model"
import { FarmerService } from "src/app/services/device.service"
import { EntityService } from "src/app/services/entity.service"
import { ISensorType, SENSOR_TYPE, TEMPERATURE_SENSOR } from "src/app/services/types.service"
import { UnitService } from "src/app/services/unit"
import { AgModal, clone, CropLabel, indexColor, LoadingState, timeDisplay } from "src/app/util"
import { AgTooltipService, IChartInfo } from "../ag-plot/ag-plot"
import { FarmerSiloSettings } from "./farmer-silo-settings"
import { ISensorSelection, ITableCell } from "./farmer-silo-table"
import { FarmerSilo } from "./farmer-silo.model"



@Component({
  selector: 'farmer-silo-page',
  template: `

<ng-template #sensorTooltip let-cell="cell" > 
  <div class="sensor-tooltip">
    <div class="sensor-tooltip-header">
      <div class="tooltip-cable-name">{{cell.column.name}}</div>
      <div class="tooltip-sensor-name">{{cell.row.name}}</div>
    </div>
    <div class="sensor-tooltip-content">
      <div class="tooltip-time">{{timeDisplay(cell.ts)}}</div>
      <div class="tooltip-time-since">
        <ag-time-since [ts]="cell.ts"></ag-time-since>
      </div>
    </div>
    <div class="sensor-tooltip-body">
      <div class="tooltip-value" [style]="cell.style">{{cell.value.toFixed(1)}}{{sensorUnit(cell.type)}}</div>
    </div>
    <a class="sensor-tooltip-link" (click)="openHistoryPage(null, cell)">show sensor history</a>
  </div>
</ng-template>

<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/"></ion-back-button>
    </ion-buttons>
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="primary">
    <ion-button (click)="openHistoryPage($event)">
    <ion-icon name="stats-chart-outline"></ion-icon>
      </ion-button>
      <ion-button (click)="openTimeWindowMenu($event)">
        <ion-icon slot="icon-only" name="time-outline" ></ion-icon>
      </ion-button> 
      <ion-button (click)="openSettings()" >
        <ion-icon slot="icon-only" name="settings-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
<ag-loading [loading]="loading" message="Loading Silo..." (retry)="reload()"></ag-loading>
  <div *ngIf="loading.is_success()" class="flex-auto" style="padding: 8px 4px 4px;" fxFlex fxLayout="column">
    
    <div *ngIf="gateway" fxLayout="column" class="ag-card stats-container flex-auto">
      <div fxFlex fxLayout="row" class="flex-auto">
        <div class="ag-value-header" fxFlex>TMS Gateway</div>
        <div style="font-size: 12px;"><ag-time-since [ts]="gateway.latestTs"></ag-time-since></div>
      </div>  
      <div class="ag-values-row flex-auto" style="margin: 0;" fxFlex fxLayout="row">
        <div class="ag-value-row" style="margin-left: 10px; margin-right: 100px;" fxFlex fxLayout="row" fxLayoutAlign="start center" >
          <div style="width: 50px;">
            <battery-level [percent]="gateway.battery_level"></battery-level>
          </div>
          <div fxLayout="column" class="ag-value-section">
            <span style="font-size: 20px;" class="ag-value">{{levelLabel(gateway.battery_level)}}</span> 
            <div class="ag-value-label">Battery</div>
          </div>
        </div>
  
        <div class="ag-value-row flex-auto" fxFlex fxLayout="row" fxLayoutAlign="start center" >
          <div style="width: 35px;">
            <signal-strength [value]="gateway.signal_value"></signal-strength>
          </div>
          <div fxLayout="column" class="ag-value-section">
            <!--<span style="font-size: 20px;" class="ag-value">{{levelLabel(signalValue.percent)}}</span>-->
            <span style="font-size: 16px;" class="ag-value">Signal</span>
            <div class="ag-value-label">{{gateway.signal_value.name}}</div>
          </div>
        </div>
      </div>
    </div>
  
    <div *ngIf="silo.hasTemperature()" class="ag-card flex-auto stats-container" fxFlex fxLayout="column">
      <div fxFlex fxLayout="row" class="flex-auto">
        <div class="ag-value-header" fxFlex>Latest Temperature</div>
        <div style="font-size: 12px;"><ag-time-since [ts]="silo.latestTemperatureTs"></ag-time-since></div>
      </div>
      <div class="ag-values-row flex-auto" fxFlex fxLayout="row">
        <ag-sensor-value label="Min Temperature" type='TEMPERATURE' [value]="data.current_min_temperature" ></ag-sensor-value>
        <ag-sensor-value label="Max Temperature" type='TEMPERATURE' [value]="data.current_max_temperature" ></ag-sensor-value>
      </div>
      
    </div>
    <div *ngIf="silo.hasMoisture()" class="ag-card flex-auto stats-container" fxFlex fxLayout="column" > 
      <div fxFlex fxLayout="row" class="flex-auto">
        <div class="ag-value-header" fxFlex>Latest Humidity</div>
        <div style="font-size: 12px;"><ag-time-since [ts]="silo.latestMoistureTs"></ag-time-since></div>
      </div>
      <div class="ag-values-row flex-auto" fxFlex fxLayout="row">
        <ag-sensor-value type='MOISTURE' [value]="data.current_min_emc" ></ag-sensor-value>
        <ag-sensor-value type='MOISTURE' [value]="data.current_max_emc" ></ag-sensor-value>
      </div>
    </div>
    
    

    <div *ngFor="let chart of charts; trackBy:chartIdentity" id="cellular-chart-card" 
      [class]="{'ag-plot-zoom': false, 'ag-plot-no-zoom': true}" 
      class="ag-card" fxLayout="column"> 
      
      <div class="md-padding chart-heading" translate>{{chart.name}} History</div>
      <!--<ion-icon name="expand-outline" (click)="showChart()" style="position: absolute; right: 20px; top: 20px;"></ion-icon>-->
      <div fxFlex class="temp-history-container md-padding">
        <ag-plot [height]="260" [figures]="chart.figures" [entity]="asset.id" [axis]="chart.axes" [timeseries]="chart.timeseries"></ag-plot>
      </div>
      <div class='ag-plot-bottom'>
        <div class="ag-plot-legend">
          <div class="ag-legend-item" *ngFor="let ts of chart.timeseries">
            <div class="ag-legend-icon" [style.background]="ts.color"></div>
            <div class="ag-legend-value">{{ts.label}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="ag-card flex-auto" style="padding: 10px;display: flex; flex-direction: column;">
    <div *ngIf="getSensorTypes().length > 1" class="sensors" style="display: flex">
        <div *ngFor="let type of getSensorTypes()" class="sensor" [class.active]="sensorType.id == type.id" (click)="setSensorType(type)">{{type.name}}</div>
        <!--<div class="sensor" [class.active]="sensorType == SENSOR_TYPE.HUM" (click)="setSensorType(SENSOR_TYPE.HUM)">Humidity</div>-->
      </div>
      <farmer-silo-table 
        (onSelect)="onSensorSelection($event)"
        [singleSelection]="true"
        [minValue]="minValue" [maxValue]="maxValue"
        [silo]="silo" [sensorType]="sensorType.id" ></farmer-silo-table>
      
      <div style="width: 100%">
        <div class="messurements">
          <div>{{minValue}}{{unitSymbol}}</div>
          <div>{{minValue + (maxValue - minValue) / 2}}{{unitSymbol}}</div>
          <div>{{maxValue}}{{unitSymbol}}</div>
        </div>
        <div [class]="{
          'temperature-gradient': isTemperature(),
          'moisture-gradient': isMoisture()
        }" style="width: 100%; height: 10px"></div>  
      </div>
    </div>

    <div class="ag-card" style="height: 350px;">
      <farmer-silo-drawing-3d [silo]="silo" [selection]="selection"
        [minValue]="minValue" [maxValue]="maxValue" [sensorType]="sensorType.id"></farmer-silo-drawing-3d>
    </div>

    <div class="ag-card" style="height: 300px;">
      <devices-map [devices]="[silo.entity]" ></devices-map>
    </div>
  </div>
</ion-content>
  `,
  styleUrls: ['../farmer-building/farmer-building.scss', 'farmer-silo.scss']
})
export class FarmerSiloPage {

  @Input() entityId: string
  selection: ISensorSelection
  loading = new LoadingState()
  asset: Asset
  title: string
  silo: FarmerSilo
  charts: IChartInfo[] = []
  @ViewChild('sensorTooltip') sensorTooltip: TemplateRef<any>

  timeDisplay = timeDisplay
  
  sensorType: ISensorType = TEMPERATURE_SENSOR
  //SENSOR_TYPE = SENSOR_TYPE

  minValue = 0
  maxValue = 0
  unitSymbol = 'C'

  get gateway () { return this.silo.gateway }

  levelLabel (x?: number) {
    if (x == undefined || x == null || isNaN(x)) return '-'
    return x.toFixed() + '%'
  }
  sensorUnit (type: SENSOR_TYPE) {
    if (type == SENSOR_TYPE.TEMP) return this.unit.temperatureUnitSymbol()
    return this.unit.moistureUnitSymbol()
  }

  async openTimeWindowMenu (ev) {
    let popover = await this.popoverController.create({
      component: TimeQuerySettings,
      event: ev,
      translucent: true
    });
    return popover.present();
  }
  async openHistoryPage (ev, cell?: ITableCell) {
    let params = {}
    if (cell) {
      let k = cell?.column.entityId.id + ':' + cell?.key
      params = {t: cell.type, k: k}
    }
    this.router.navigate(['history'], {relativeTo: this.route, 
      queryParams: params})
  }

  onSensorSelection (selection: ISensorSelection) {
    console.error('sensor selection changed', selection)
    let sensor = selection.sensors[0]
    this.selection = {...selection}
    if (sensor && sensor.cell) {
      let target = selection.event?.target as HTMLElement
      this.agtooltip.open(target, this.sensorTooltip, {
        cell: sensor.cell
      })
    } else {

    }
  }

  sensorUrl (entityId: EntityId<any>, key: string) {
    
  }
  /*
  let cable = this.farmer.getSiloCable(device)
  for (var i=1; i<cable.settings.sensor_count+1; i++) {
    let tempKey = 'temperature-' + i
    let humKey = 'humidity-' + i
    timeseries.push({
      name: tempKey, label: cable.shortName() + ' ' + tempKey, entityId: cable.device.id,
      color: indexColor(i), unit: this.unit.temperatureUnitSymbol()
    })
    timeseries.push({
      name: humKey, label: cable.shortName() + ' ' + humKey, entityId: cable.device.id,
      color: indexColor(i), unit: this.unit.moistureUnitSymbol()
    })
  }
  */
  chartIdentity (index: number, item: IChartInfo) {
    return item.name
  }

  getSensorTypes () { return this.silo.getSensorTypes() }
  isTemperature () { return this.sensorType.id == SENSOR_TYPE.TEMP }
  isMoisture () { return this.sensorType.id == SENSOR_TYPE.HUM }

  setSensorType (type: ISensorType) {
    this.sensorType = type
    this.updateGradient()
  }
  updateGradient () {
    if (this.isTemperature()) {
      this.minValue = this.silo.settings.sample_alarm_min_temperature
      this.maxValue = this.silo.settings.sample_alarm_max_temperature
      this.unitSymbol = 'C'
    } else if (this.isMoisture()) {
      this.minValue = this.silo.settings.sample_alarm_min_emc
      this.maxValue = this.silo.settings.sample_alarm_max_emc
      this.unitSymbol = '%'
    }
  }

  get data () { return this.silo.settings }
  get emcLabel () {
    return this.data.crop_type ? 'EMC: ' + CropLabel(this.data.crop_type) : 'Humidity'
  }
  
  constructor (
    private agtooltip: AgTooltipService,
    public titleService: Title,
    private modalController: ModalController,
    //private buildingService: FarmerBuildingService,
    private unit: UnitService,
    private entityService: EntityService,
    private popoverController: PopoverController,
    private farmer: FarmerService, 
    private router: Router,
    private route: ActivatedRoute,
    private agModal: AgModal
  ) {}
    
  _subscriptions: Subscription[] = []
  ngOnInit () {
    this._subscriptions = []
    this.loading.loading(true)
    this.route.params.subscribe(params => {
      this.entityId = params['entityId'];
    });
    this.reload()
  }
  async openCableList () {
    this.router.navigateByUrl('/silo/' + this.asset.id.id + '/cables')
  }
  async reload () {
    this.loading.loading(true)
    try {
      this.asset = await this.entityService.getAssetById(this.entityId)
    } catch (err) {
      captureException(err)
      return this.loading.error('failed to load asset')
    }
    this.silo = this.farmer.getSilo(this.asset)
    this.setTitle(this.asset.label)
    this._subscriptions.push(this.silo.subscribe(() => {
      this.setTitle(this.asset.label)
      this.updateGradient()
      this.setupCharts()
      this.loading.success()
    }))
  }
  ngOnDestroy () {
    this._subscriptions.map(sub => sub.unsubscribe())
    this._subscriptions = []
  }

  setTitle (label: string) {
    this.titleService.setTitle(label)
    this.title = label
  }

  setupCharts () {
    let settings = this.silo.settings
    //console.log('setup silo charts', clone(settings))
    let tempChart: IChartInfo = {
      name: 'Temperature',
      timeseries: [], axes: [ 
        {show: true, position: 'left'},
        {show: true, position: 'bottom'}
      ], figures: []
    }
    let humChart: IChartInfo = {
      name: 'EMC',
      timeseries: [], axes: [ 
        {show: true, position: 'left', tickFormatter: (val, obj) => val.toFixed() + '%'},
        {show: true, position: 'bottom'}
      ], figures: []
    }

    tempChart.figures.push({
      y: settings.sample_alarm_max_temperature, type: 'horizontal',
      color: "#D9461D", label: 'Max Temperature Alarm'
    })
    tempChart.figures.push({
      y: settings.sample_alarm_min_temperature, type: 'horizontal',
      color: "#D9461D", label: 'Min Temperature Alarm'
    })
    humChart.figures.push({
      y: settings.sample_alarm_max_emc, type: 'horizontal',
      color: "#D9461D", label: 'Max Moisture Alarm'
    })
    humChart.figures.push({
      y: settings.sample_alarm_min_emc, type: 'horizontal',
      color: "#D9461D", label: 'Min Moisture Alarm'
    })
    
    let index = 0
    this.silo.temperatureKeys().map(key => {
      tempChart.timeseries.push({
        name: key.key, label: key.name,
        color: indexColor(index), unit: this.unit.temperatureUnitSymbol()
      })
      index += 1
    })

    this.silo.moistureKeys().map(key => {

      humChart.timeseries.push({
        name: key.key, label: key.name + ' ' + CropLabel(this.data.crop_type),
        color: indexColor(index), unit: this.unit.moistureUnitSymbol()
      })
      index += 1
    })
    
    let newCharts = []
    if (this.silo.hasTemperature()) newCharts.push(tempChart)
    if (this.silo.hasMoisture()) newCharts.push(humChart)
    if (!isEqual(this.charts, newCharts)) {
      this.charts = newCharts
    }
    //console.log('silo chart', this.charts)
  }

  async openSettings () {
    const dialogRef = await this.modalController.create({
      component: FarmerSiloSettings,
      componentProps: {silo: this.silo, settings: clone(this.silo.settings)}
    });
    const { data } = await this.agModal.openModal(dialogRef)
  }
}




@Component({
  selector: 'farmer-silo-devices-page',
  template: `
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/"></ion-back-button>
    </ion-buttons>
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="primary">

    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="device-grid">
    <!--<farmer-device-item
      *ngFor="let device of devices" class="device-item"  [device]="device" 
      (onClick)="selectDevice(device)"></farmer-device-item>-->
  </div>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed" (click)="openSelectDeviceDialog()">
    <ion-fab-button color="primary">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
  `,
  styleUrls: ['../farmer-devices/farmer-devices.scss']
})
export class FarmerSiloDevicesPage {
  @Input() entityId: string
  
  _subscriptions: Subscription[] = []
  loading = new LoadingState()
  asset: Asset
  title = 'Cables'
  silo: FarmerSilo
  devices: Device[] = []

  constructor (
    private route: ActivatedRoute,
    private router: Router,
    private farmer: FarmerService,
    private entityService: EntityService
  ) {}

  ngOnInit () {
    this._subscriptions = []
    this.loading.loading(true)
    this.route.params.subscribe(params => {
      this.entityId = params['entityId'];
    });
    this.load()
  }
  async load () {
    this.asset = await this.entityService.getAssetById(this.entityId)
    this.silo = this.farmer.getSilo(this.asset)
    this._subscriptions.push(this.silo.subscribe(() => {
      this.title = this.asset.label + ' Cables'
      this.devices = this.silo.devices.sort((a, b) => a.label.localeCompare(b.label))
    }))
  }

  openSelectDeviceDialog() {
    
  }

  selectDevice (device) {
    this.router.navigateByUrl('/device/' + device.id.id)
  }
}
