
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { agTypes } from 'src/app/ag-types';
import { FarmerService } from 'src/app/services/device.service';
import * as THREE from 'three';
import { FarmerSilo } from './farmer-silo.model';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
//import { Geometry, Face3 } from "three/examples/jsm/geometries/Geometry"
import { ResizeObserver } from 'resize-observer';

declare var Geometry: any
declare var Face3: any

import tinycolor from "tinycolor2";
import { gradientColorValue, Index } from 'src/app/util';
import { ISensorSelection } from './farmer-silo-table';
import { SENSOR_TYPE } from 'src/app/services/types.service';
import { Subscription } from 'rxjs';

interface ISiloGeometry {
  eaveheight: number, totalheight: number, viewrotation: number
  diameter?: number, hopper?: boolean, hopperheight?: number,
  hopperangle?: number, width?: number, length?: number
  cables: ISiloCableGeometry[]
}
interface ISiloCableGeometry {
  id: string, sensorCount: number, length: number
  sensorDistance: number, positionX: number
  positionY: number, positionZ: number
}

@Component({
  selector: 'farmer-silo-drawing-3d',
  template: `
<div #container style="position: relative; height: 100%; width: 100%;">
  <canvas #sceneCanvas style="position: absolute; padding: 15px; width: 100%; height: 100%"></canvas>
</div>
  `
})
export class SiloDrawing3D {
  @ViewChild('container') drawingElement: ElementRef<HTMLDivElement>
  @ViewChild('sceneCanvas') sceneCanvas: ElementRef<HTMLCanvasElement>
  @Input() silo: FarmerSilo
  @Input() sensorType: SENSOR_TYPE = SENSOR_TYPE.TEMP
  @Input() minValue: number = 0
  @Input() maxValue: number = 25
  @Input() selection: ISensorSelection
  siloType = agTypes.siloType.cylinder
  agTypes = agTypes
  
  scene
  camera: THREE.PerspectiveCamera
  renderer
  animationId

  siloGeometry: ISiloGeometry
  cropGeometry = {};
  disabledSensors = {};

  silo_object = null;

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  
  constructor (private farmer: FarmerService) {}

  onSiloUpdated () {
    this.updateGeometry();
  }

  ngOnDestroy () {
    this._resize_observer.disconnect()
    this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = []
  }

  ngOnInit() {
    this.listen(this.silo.updated.subscribe(e => this.onSiloUpdated()))
    this.onSiloUpdated()
  }

  _resize_observer: ResizeObserver
  ngAfterViewInit () {
    let element = this.drawingElement.nativeElement
    this._resize_observer = new ResizeObserver(() => {
      this.resize()
    })
    this._resize_observer.observe(element)
    this.redraw()
    this.resize()
  }

  clear () {
    if (this.animationId) {
      cancelAnimationFrame(this.animationId); 
    }
    delete this.siloGeometry
    this.cropGeometry = {};
    this.disabledSensors = {};
    this.silo_object = null;
  }



  updateGeometry () {
    let silo = this.silo;
    this.siloType = agTypes.siloType.cylinder // silo.siloType;

    this.disabledSensors = [] // computeDisabledSensors(this.siloData);

    /*if (this.telemetryType == agTypes.telemetry.type.temperature) {
        computeTemperatureLimits(silo.maxTemperature);
    } else if (this.telemetryType == agTypes.telemetry.type.moisture) {
        computeTemperatureLimits(silo.maxMoisture);
    }*/
    
    let siloGeometry: ISiloGeometry = {
      cables: [],
      eaveheight: silo.settings.eave_height,
      totalheight: silo.settings.height,
      viewrotation: 20 // silo.viewrotation
    };
    if (this.siloType == agTypes.siloType.cylinder) {
        siloGeometry.diameter = silo.settings.diameter;
        siloGeometry.hopper = silo.settings.hopper;
        siloGeometry.hopperheight = silo.settings.hopper_height;
        siloGeometry.hopperangle = silo.settings.hopper_angle;
    } else if (this.siloType == agTypes.siloType.building) {
        //siloGeometry.width = silo.width;
        //siloGeometry.length = silo.length;
    }
    
    let sensorLinesGeometry: ISiloCableGeometry[] = [];
    silo.devices.forEach((device) => {
      let cable = this.farmer.getSiloCable(device)
      let sensorLine = cable.settings
      sensorLinesGeometry.push({
        id: device.id.id as string,
        //lineNum: sensorLine.line_num,
        sensorCount: sensorLine.sensor_count,
        length: sensorLine.length,
        sensorDistance: sensorLine.sensor_distance,
        positionX: sensorLine.pos_x,
        positionY: sensorLine.pos_y,
        positionZ: sensorLine.pos_z
      });
    });
    siloGeometry.cables = sensorLinesGeometry
    this.siloGeometry = siloGeometry
    this.redraw()
    /*if (this.siloData.cropData) {
        this.cropGeometry = angular.copy(this.siloData.cropData);
    }*/
    // this.displayValue = `<b>Silo Geometry:</b>\n\n${angular.toJson(this.siloGeometry, true)}\n\n<b>Sensor lines geometry:</b>\n\n${angular.toJson(this.sensorLinesGeometry, true)}`;
    
  }

  _state
  get _stateKey () {
    return this.silo.asset.id.id + ':state'
  }
  loadState () {
    let state: any = localStorage.getItem(this._stateKey)
    if (!state) return
    state = JSON.parse(state) as any
    let pos = state?.camera?.position
    let rot = state?.camera?.rotation
    //console.log('set camera pos', state)
    if (!pos || !rot) return
    this.camera.position.x = pos.x
    this.camera.position.y = pos.y
    this.camera.position.z = pos.z
    this.camera.rotation.x = rot.x
    this.camera.rotation.y = rot.y
    this.camera.rotation.z = rot.z
    this.camera.updateProjectionMatrix();
  }
  onCameraChange (e) {
    //console.log('CAMERA CHANGE', this.camera, this.camera.position)// this.camera.rotation)
    this._state = {camera: {
      position: this.camera.position.clone(), rotation: this.camera.rotation.toVector3().clone()
    }}
    // TODO: might be slow
    localStorage.setItem(this._stateKey, JSON.stringify(this._state))
  }

  redraw () {
    if (!this.drawingElement) return console.warn('no canvas element')
    //console.log(' -- DRAW', this.siloGeometry)
    if (this.animationId) {
      cancelAnimationFrame(this.animationId); //eslint-disable-line no-undef
    }
    let sceneCanvas = this.sceneCanvas.nativeElement // this. angular.element('#sceneCanvas', $element)[0];
    let bound = this.getContainerBound()
    
    if (!this.renderer) {
      // Renderer and canvas
      this.renderer = new THREE.WebGLRenderer({
        antialias: true, canvas: sceneCanvas, alpha: true
      });
      this.renderer.setPixelRatio(window.devicePixelRatio); //eslint-disable-line
      this.renderer.setSize(bound.width, bound.height);
      this.renderer.setClearColor( 0x000000, 0 );
      //this.renderer.setClearColor(0xffffff, 1);
    }

    this.scene = new THREE.Scene();
    
    let silo_height = this.siloGeometry.totalheight;
    let silo_base_length;
    if (this.siloType == agTypes.siloType.cylinder) {
      silo_base_length = this.siloGeometry.diameter * 0.8;
    } else if (this.siloType == agTypes.siloType.building) {
      silo_base_length = Math.sqrt(Math.pow(this.siloGeometry.width, 2) + Math.pow(this.siloGeometry.length, 2));
    }

    // Camera
    let zoom = 75.0 // 55.0
    this.camera = new THREE.PerspectiveCamera(zoom, bound.width / bound.height, 0.5, 3000);
    //this.camera.addEventListener('')
    //let camera_aim = new THREE.Vector3(0.0, silo_height * 0.5, 0.0);
    let camera_aim = new THREE.Vector3(0.0, silo_height * 0.35, 0.0);
    let camera_position;
    if (this.siloType == agTypes.siloType.cylinder) {
      camera_position = new THREE.Vector3(silo_height, silo_height * 1.2, silo_height);
    }  else if (this.siloType == agTypes.siloType.building) {
      camera_position = new THREE.Vector3(this.siloGeometry.length, this.siloGeometry.width, this.siloGeometry.width);
    }
    this.scene.add( this.camera );
    this.camera.position.set(camera_position.x, camera_position.y, camera_position.z);
    this.camera.lookAt(camera_aim);
    // Initialize Orbit control
    
    let controls = new OrbitControls(this.camera, this.renderer.domElement);
    //controls.enablePan = false;
    //controls.enableZoom = false;
    controls.minPolarAngle = 0.0;
    controls.maxPolarAngle = Math.PI * 0.5;
    //controls.userPanSpeed = 0.0;
    controls.minDistance = 0;
    controls.maxDistance = 2000.0;
    controls.object.position.set(camera_position.x, camera_position.y, camera_position.z);
    controls.target = camera_aim;
    controls.update();

    controls.addEventListener('change', (e) => this.onCameraChange(e))

    this.camera.updateProjectionMatrix();

    // helper
    // this.scene.add( new THREE.AxisHelper( 20 ) );

    // Base material
    let material = new THREE.MeshLambertMaterial({side: THREE.DoubleSide});
    material.transparent = true;
    material.opacity = 0.4;
    //material.side = THREE.BackSide;

    // Ground PLane
    /*
    let ground_plane_geometry = new THREE.PlaneGeometry( silo_diameter, silo_diameter, 32 );
    let plane = new THREE.Mesh( ground_plane_geometry, material );
    plane.receiveShadow = false;
    plane.rotation.x = Math.PI / 2;
    this.scene.add( plane );
    */

    // Create ground compass
    let compass = new Compass(silo_base_length);
    this.scene.add(compass.objects);

    // Create Silo and Cabels
    this.silo_object = new Silo(this.siloGeometry, this.siloGeometry.cables, [], this.cropGeometry);
    this.silo_object.viewRotate(this.siloGeometry.viewrotation);
    this.scene.add(this.silo_object.objects);

    // Light
    let light1 = new THREE.PointLight( 0xffffff, 0.25);
    light1.position.set( 100, 200, 100 );
    this.scene.add( light1 );

    let light2 = new THREE.PointLight(0xffffff, 0.25);
    light2.position.set( -100, 200, -100 );
    this.scene.add( light2 );

    let light = new THREE.AmbientLight( 0x606060 ); // soft white light
    this.scene.add( light );

    this.animate();
    this.updateSensorColors()
    if (this.selection) {
      this.silo_object.deHightlightSensors()
      console.log('3D Selection update', this.selection)
      this.selection.sensors.map(sensor => {
        let col_id = sensor.entityId.id
        let index = sensor.key
        // hightlightSensor deHightlightSensors
        this.silo_object.hightlightSensor(col_id, sensor.cell.rowIndex)
      })
      
    }
    this.loadState()
  }
  ngOnChanges () {
    this.redraw()
  }

  getContainerBound () {
    return this.drawingElement.nativeElement.getBoundingClientRect()
  }

  resize () {
    //return
    let sceneCanvas = this.sceneCanvas.nativeElement // this. angular.element('#sceneCanvas', $element)[0];
    let bound = this.getContainerBound()
    //let [width, height] = [sceneCanvas.width, sceneCanvas.height]
    //console.log('RESIZE', bound.width, bound.height, {cam: this.camera})
    if (this.camera) {
      this.camera.aspect = bound.width / bound.height;
      this.camera.updateProjectionMatrix();
    }
    if (this.renderer) {
      this.renderer.setSize( bound.width, bound.height );
    }
  }

  animate () {
    this.animationId = requestAnimationFrame(() => this.animate()); //eslint-disable-line no-undef
    //group.rotation.y += 0.005;
    this.renderer.render( this.scene, this.camera );
  }
  getValueKey () {
    if (this.sensorType == SENSOR_TYPE.HUM) { 
      let crop = this.silo.settings.crop_type
      return crop ? 'emc-' + crop : 'humidity'
    } else return 'temperature'
  }
  getColor (value: number) {
    let matrix = agTypes.colors.tempMatrixColors
    if (this.sensorType == SENSOR_TYPE.HUM) matrix = agTypes.colors.moistureMatrixColors
    return gradientColorValue(value, this.minValue, this.maxValue, matrix)
  }
  updateSensorColors() {
    if (this.silo_object !== null) {
      let data: Index<Index<number>> = {}
      this.silo.devices.map(d => {
        let cable = this.farmer.getSiloCable(d)
        data[d.id.id] = {}
        for (var i=0; i<cable.settings.sensor_count; i++) {
          let key = this.getValueKey() + '-' + (i+1)
          let value = cable.latest[key]
          if (value && value.value && value.ts) {
            let rgb = this.getColor(value.value)
            data[d.id.id][i] = rgb
          } else {
            let v = 100
            data[d.id.id][i] = {r: v, g: v, b: v} as any
          }
        }
      })
      this.silo_object.updateSensorColors(data)
    }
  }
}


function deg_to_rad (d) {
  return d * (Math.PI / 180.0)
}

function Silo(siloGeometry: ISiloGeometry, sensorLinesGeometry: ISiloCableGeometry[], colors: string[], cropGeometry){
    this.objects = new THREE.Object3D();
    this.cabels = {};
    this.colors = colors;
    this.siloType = agTypes.siloType.cylinder
    this.material = new THREE.MeshPhongMaterial();
    this.material.transparent = true;
    this.material.opacity = 0.2;
    this.material.color = new THREE.Color(0xbdc3c7);

    let hopperHeight = 0.0;
    if (this.siloType == agTypes.siloType.cylinder) {
        //Hopper
        if(siloGeometry.hopper){
            hopperHeight = siloGeometry.hopperheight;
            let alpha = deg_to_rad(siloGeometry.hopperangle);
            let alpha_off_r = hopperHeight * Math.tan(alpha);
            let radius_top = siloGeometry.diameter * 0.5;
            let radius_bottom = radius_top - alpha_off_r;

            let hopper_geometry = new THREE.CylinderGeometry( radius_top, radius_bottom, hopperHeight, 32 , 1, false);
            //let hopper_material = new THREE.MeshBasicMaterial( {color: 0xffff00} );
            let hopper = new THREE.Mesh( hopper_geometry, this.material );
            hopper.position.y = hopperHeight * 0.5;
            hopper.renderOrder = 2;
            this.objects.add( hopper );
        }

        // Tube
        let path = new THREE.LineCurve3( new THREE.Vector3( 0, hopperHeight, 0 ), new THREE.Vector3( 0, siloGeometry.eaveheight,  0) );
        let tube_geometry = new THREE.TubeGeometry( path, 20, siloGeometry.diameter * 0.5, 32, false );
        this.tube = new THREE.Mesh( tube_geometry, this.material );
        this.tube.renderOrder = 2;

        // Top
        let cone_height = siloGeometry.totalheight - siloGeometry.eaveheight;
        let cone_geometry = new THREE.ConeGeometry( siloGeometry.diameter * 0.5, cone_height, 32 );
        this.top = new THREE.Mesh( cone_geometry, this.material );
        this.top.openEnded = false;
        this.top.position.y = siloGeometry.eaveheight + cone_height * 0.5;
        this.top.renderOrder = 2;
        this.objects.add(this.tube);
        this.objects.add(this.top);

        this.computeSealingOffset = function(totalHeight, eaveHeight, silo_diameter, posX, posY){
            let r = Math.sqrt(posX*posX + posY*posY);
            let h = totalHeight - eaveHeight;
            let R = silo_diameter * 0.5;
            return h*(R - r)/R;
        };
    } else if (this.siloType == agTypes.siloType.building) {
        //Box
        let box_geometry = new THREE.BoxGeometry( siloGeometry.length, siloGeometry.eaveheight, siloGeometry.width );
        let transparent_material = new THREE.MeshPhongMaterial({transparent: true, opacity: 0.2, });
        let box_materials = [this.material, this.material, transparent_material, this.material, this.material, this.material];
        this.box = new THREE.Mesh( box_geometry, box_materials );
        this.box.position.y = siloGeometry.eaveheight * 0.5;
        this.objects.add(this.box);

        //Roof
        var roof_geometry = new Geometry();
        roof_geometry.vertices.push(
            new THREE.Vector3( siloGeometry.length/2, siloGeometry.eaveheight, siloGeometry.width/2 ),
            new THREE.Vector3( siloGeometry.length/2, siloGeometry.eaveheight, -siloGeometry.width/2 ),
            new THREE.Vector3( siloGeometry.length/2, siloGeometry.totalheight, 0 ),
            new THREE.Vector3( -siloGeometry.length/2, siloGeometry.eaveheight, siloGeometry.width/2 ),
            new THREE.Vector3( -siloGeometry.length/2, siloGeometry.eaveheight, -siloGeometry.width/2 ),
            new THREE.Vector3( -siloGeometry.length/2, siloGeometry.totalheight, 0 )
        );
        let backside_material = this.material.clone();
        backside_material.side = THREE.BackSide;
        let roof_materials = [this.material, backside_material];
        roof_geometry.faces.push(new Face3(0, 1, 2));
        roof_geometry.faces.push(new Face3(3, 4, 5));
        roof_geometry.faces.push(new Face3(0, 2, 5));
        roof_geometry.faces.push(new Face3(0, 3, 5));
        roof_geometry.faces.push(new Face3(1, 2, 5));
        roof_geometry.faces.push(new Face3(4, 1, 5));
        roof_geometry.faces[1].materialIndex = 1;
        roof_geometry.faces[3].materialIndex = 1;
        roof_geometry.faces[4].materialIndex = 1;
        roof_geometry.faces[5].materialIndex = 1;

        this.roof = new THREE.Mesh( roof_geometry.toBufferGeometry(), roof_materials );
        this.objects.add(this.roof);

        this.computeSealingOffset = function(totalHeight, eaveHeight, silo_width, posY) {
            let h = totalHeight - eaveHeight;
            let distance_to_cable = silo_width * 0.5 - Math.abs(posY);
            return h*distance_to_cable/(silo_width * 0.5);
        }
    }

    // Highlighter
    this.highlighter = new Highlighter(2.0, 2.0, 1.0);
    this.objects.add(this.highlighter.objects);
    this.highlighter.hide();

    function isValidCableGeometry (sl: ISiloCableGeometry) {
      return sl.id != null && sl.length != null &&
        sl.sensorDistance != null && sl.positionX != null &&
        sl.positionY != null && sl.positionZ != null
    }

    // Temperature Sensor Lines
    let sensor_radius = 1;
    let sensor_geometry = new THREE.SphereGeometry( sensor_radius, 8, 8 );
    for (let sl of sensorLinesGeometry) {
        if (!isValidCableGeometry(sl)) { 
          continue; 
        }

        // Use the center of the eave as a base / origin
        let cable_origin = new THREE.Vector3(0.0, siloGeometry.eaveheight, 0.0);
        let cable_offset_snap_to_sealing, cable_offset;
        if (this.siloType == agTypes.siloType.cylinder) {
            cable_offset_snap_to_sealing = new THREE.Vector3(0,
                this.computeSealingOffset(
                    siloGeometry.totalheight,
                    siloGeometry.eaveheight,
                    siloGeometry.diameter,
                    sl.positionX,
                    sl.positionY
                ),
                0);
            cable_offset = new THREE.Vector3(sl.positionX, sl.positionZ, sl.positionY);
        } else if (this.siloType == agTypes.siloType.building) {
            cable_offset_snap_to_sealing = new THREE.Vector3(0,
                this.computeSealingOffset(
                    siloGeometry.totalheight,
                    siloGeometry.eaveheight,
                    siloGeometry.width,
                    sl.positionY - siloGeometry.width/2
                ),
                0);
            cable_offset = new THREE.Vector3(sl.positionX - siloGeometry.length/2, sl.positionZ, sl.positionY - siloGeometry.width/2);
        }
        let base_position = cable_origin.add(cable_offset).add(cable_offset_snap_to_sealing);
        
        let max_length = siloGeometry.eaveheight + cable_offset_snap_to_sealing.y
        let line_length = Math.min(sl.length, max_length)
        line_length = max_length
        //console.log('POSITION CABLE', cable_offset_snap_to_sealing.y, max_length, line_length, {origin: cable_origin, cable_offset: cable_offset, snap: cable_offset_snap_to_sealing})
        let sl_object = new TLine(base_position, line_length, sl.sensorCount, sl.sensorDistance, 0.0, sensor_geometry);
        this.objects.add(sl_object.objects);
        this.cabels[sl.id] = sl_object;
    }

    // Measure points
    if (cropGeometry.measurePoints) {
        let measure_point_geometry = new THREE.BoxGeometry( 0.5, 1, 0.5 );
        let measure_point_material =  new THREE.MeshPhongMaterial({color: 0xa1dfff, transparent: false});

        if (cropGeometry.measurePoints.length) {
            for (var i = 0; i < cropGeometry.measurePoints.length; i++) {
                var measure_point = new THREE.Mesh( measure_point_geometry, measure_point_material );
                measure_point.position.x = cropGeometry.measurePoints[i].pos.posX;
                measure_point.position.y = cropGeometry.measurePoints[i].pos.posZ;
                measure_point.position.z = cropGeometry.measurePoints[i].pos.posY;
            }
            this.objects.add(measure_point);
        }
    }

    // Crop model
    if (cropGeometry.cropLevel != null) {
        let crop_material = new THREE.MeshPhongMaterial({color: 0xffb079, transparent: true, opacity: 0.5});
        // Crop tube
        if (cropGeometry.sideCropHeight && cropGeometry.cropLevel && (cropGeometry.sideCropHeight > 0)) {
            let crop_tube_path = new THREE.LineCurve3( new THREE.Vector3( 0, hopperHeight, 0 ), new THREE.Vector3( 0, cropGeometry.sideCropHeight,  0));
            let crop_tube_geometry = new THREE.TubeGeometry( crop_tube_path, 20, siloGeometry.diameter * 0.49, 32, false );
            this.cropTube = new THREE.Mesh( crop_tube_geometry, crop_material );
            this.cropTube.renderOrder = 1;
            if (siloGeometry.hopper) {
                if ((cropGeometry.cropLevel > cropGeometry.distanceFromHopperSurfaceToBottom) && (cropGeometry.sideCropHeight > siloGeometry.hopperheight)) {
                    this.objects.add(this.cropTube);
                }
            } else {
                this.objects.add(this.cropTube);
            }
        }
        // Crop hopper
        if (siloGeometry.hopper && (cropGeometry.cropLevel > cropGeometry.distanceFromHopperSurfaceToBottom)) {
            let alpha = deg_to_rad(siloGeometry.hopperangle);
            let alpha_off_r = hopperHeight * Math.tan(alpha);
            let radius_top = siloGeometry.diameter * 0.49;
            let radius_bottom = radius_top - alpha_off_r;
            if (radius_bottom < 0) {
                radius_bottom = 0;
            }
            let hopper_crop_geometry = new THREE.CylinderGeometry( radius_top, radius_bottom, hopperHeight, 32 , 1, true);
            let crop_hopper = new THREE.Mesh( hopper_crop_geometry, crop_material );
            crop_hopper.position.y = hopperHeight * 0.5;

            if (cropGeometry.hopperCrossingRadius) {
                hopper_crop_geometry = new THREE.CylinderGeometry( cropGeometry.hopperCrossingRadius * 0.98, radius_bottom, cropGeometry.cropHopperHeight, 32 , 1, true);
                crop_hopper = new THREE.Mesh( hopper_crop_geometry, crop_material );
                crop_hopper.position.y = cropGeometry.cropHopperHeight * 0.5;
            }

            crop_hopper.renderOrder = 1;
            this.objects.add( crop_hopper );
        }
        // Crop cone
        if (cropGeometry.cropConeHeight && cropGeometry.cropLevel) {
            let crop_cone_geometry;
            if (cropGeometry.angleDirection == "up") {
                crop_cone_geometry = new THREE.ConeGeometry( cropGeometry.cropConeBottomRadius * 0.98, cropGeometry.cropConeHeight, 32, 1, true );
                this.cropCone = new THREE.Mesh( crop_cone_geometry, crop_material );
                if (cropGeometry.sideCropHeight > 0) {
                    this.cropCone.position.y = cropGeometry.sideCropHeight + cropGeometry.cropConeHeight * 0.5;
                } else {
                    this.cropCone.position.y = cropGeometry.cropConeHeight * 0.5;
                }

                if (cropGeometry.hopperCrossingRadius) {
                    crop_cone_geometry = new THREE.ConeGeometry( cropGeometry.hopperCrossingRadius * 0.98, cropGeometry.cropConeHeight, 32, 1, true );
                    this.cropCone = new THREE.Mesh( crop_cone_geometry, crop_material );
                    this.cropCone.position.y = cropGeometry.cropHopperHeight + cropGeometry.cropConeHeight * 0.5;
                }
            } else if (cropGeometry.angleDirection == "down") {
                crop_cone_geometry = new THREE.CylinderGeometry( siloGeometry.diameter * 0.49, cropGeometry.cropConeBottomRadius, cropGeometry.cropConeHeight, 32 , 1, true);
                let crop_cone_material = new THREE.MeshPhongMaterial({color: 0xffb079, transparent: true, opacity: 0.5, side: THREE.DoubleSide});
                this.cropCone = new THREE.Mesh( crop_cone_geometry, crop_cone_material );
                this.cropCone.position.y = (cropGeometry.sideCropHeight - cropGeometry.cropConeHeight) + cropGeometry.cropConeHeight * 0.5;

                if (cropGeometry.hopperCrossingRadius) {
                    crop_cone_geometry = new THREE.CylinderGeometry( cropGeometry.hopperCrossingRadius * 0.98, cropGeometry.cropConeBottomRadius, cropGeometry.cropConeHeight, 32 , 1, true);
                    this.cropCone = new THREE.Mesh( crop_cone_geometry, crop_cone_material );
                    this.cropCone.position.y = (cropGeometry.cropHopperHeight - cropGeometry.cropConeHeight) + cropGeometry.cropConeHeight * 0.5;
                }
            }
            if (siloGeometry.hopper) {
                if (cropGeometry.cropLevel > cropGeometry.distanceFromHopperSurfaceToBottom) {
                    this.objects.add(this.cropCone);
                }
            } else {
                this.objects.add(this.cropCone);
            }
        }
        // Measure Ray
        let ray_material = new THREE.MeshBasicMaterial({color: 0xff4747, transparent: false});
        let ray_first_vector = new THREE.Vector3( cropGeometry.measurePoints[0].pos.posX, cropGeometry.measurePoints[0].pos.posZ, cropGeometry.measurePoints[0].pos.posY );
        let ray_second_vector;
        if (cropGeometry.angleDirection) {
            if (cropGeometry.angleDirection == "up") {
                ray_second_vector = new THREE.Vector3(cropGeometry.measurePoints[0].pos.posX, cropGeometry.cropLevel*0.995, cropGeometry.measurePoints[0].pos.posY );
            } else if (cropGeometry.angleDirection == "down") {
                ray_second_vector = new THREE.Vector3(cropGeometry.measurePoints[0].pos.posX, cropGeometry.cropLevel*1.005, cropGeometry.measurePoints[0].pos.posY );
            }
            let ray_path = new THREE.LineCurve3( ray_first_vector, ray_second_vector);
            let ray_geometry = new THREE.TubeGeometry( ray_path, 20, 0.05, 32, false );
            this.ray = new THREE.Mesh( ray_geometry, ray_material );
            this.objects.add(this.ray);
        }
    }

    this.updateSensorColors = function(sensorsData){ //eslint-disable-line no-undef
      //console.log('update colors', {colors: sensorsData, cables: this.cabels})  
      for (let key in sensorsData) {
            if (key in this.cabels){
                this.cabels[key].updateSensorColors(sensorsData[key]);
            }
        }
    };

    this.disableSensors = function(disabledSensors){
        for (let key in disabledSensors) {
            if (key in this.cabels){
                this.cabels[key].disableSensors(disabledSensors[key]);
            }
        }
    };

    this.disableSensors(this.disabledSensors);

    this.hightlightSensor = function(cableNum, sensorIndex){
        this.deHightlightSensors();
        if (cableNum in this.cabels){
            let pos = this.cabels[cableNum].getSensorPosition(sensorIndex);
            this.highlighter.showAt(pos);
        }
    };
    
    this.deHightlightSensors = function(){
        this.highlighter.hide();
    };

    this.viewRotate = function(rotation){
        this.objects.rotateY(deg_to_rad(rotation))
    }

}

function TLine(base_position, length, n_sensors, sensor_distance, sensor_offset, sensor_geometry){
    this.n_sensors = n_sensors;
    this.objects = new THREE.Object3D();
    this.material = new THREE.MeshPhongMaterial();
    this.sensors = [];

    // Tube line
    let path = new THREE.LineCurve3( base_position, new THREE.Vector3( base_position.x, base_position.y - length,  base_position.z) );
    let tube_geometry = new THREE.TubeGeometry( path, 20, 0.1, 32, false );
    this.cable_mesh = new THREE.Mesh( tube_geometry, this.material );
    this.objects.add(this.cable_mesh);

    let sp = new THREE.Vector3( base_position.x, base_position.y - length + sensor_offset,  base_position.z);
    for(let i=0; i<n_sensors; i++)
    {
        let step_y = i * sensor_distance;
        let n_sensor_pos = new THREE.Vector3( sp.x, sp.y + step_y,  sp.z);
        let sensor = new TSensor(n_sensor_pos, sensor_geometry);
        this.objects.add(sensor.objects);
        this.sensors.push(sensor);
    }

    this.updateSensorColors = function(data){
        for(let i = 0; i < this.sensors.length; i++){
            this.sensors[i].updateSensorColor(data[i]);
        }
    };

    this.getSensorPosition = function(sensorIndex){
        return this.sensors[sensorIndex].getPosition();
    };


    this.highlightSensor = function(sensorIndex){
        this.sensors[sensorIndex].highlight();
    };

    this.disableSensors = function(sensorIndexes){
        for(let i = 0; i < sensorIndexes.length; i++){
            this.sensors[sensorIndexes[i]].disable();
        }
    }

}

function TSensor(n_sensor_pos, sensor_geometry){
    this.objects = new THREE.Object3D();
    this.geometry = sensor_geometry;

    this.material = new THREE.MeshPhongMaterial();
    this.material.color = new THREE.Color(0x2ecc71);
    this.material.emissive = new THREE.Color(0x2ecc71);
    this.material.transparent = false;
    this.mesh = new THREE.Mesh( this.geometry, this.material );
    this.objects.add(this.mesh);

    this.objects.position.x = n_sensor_pos.x;
    this.objects.position.y = n_sensor_pos.y;
    this.objects.position.z = n_sensor_pos.z;

    this.isDisabled = false;
    this.color = new THREE.Color(0, 0, 0);
    
    // set as disabled color by default, until received colors
    let g = 85;
    this.material.color.setRGB(g, g, g);
    this.objects.add(this.mesh);

    this.updateSensorColor = function(col){
        //console.log('set sensor color', col)
        if (!col) return
        //let col = this.mapTempToColor(sensorValue);
        this.color.r = col.r / 255;
        this.color.g = col.g / 255;
        this.color.b = col.b / 255;

        if(!this.isDisabled){
          //console.log('set rgb', this.color)
            this.material.color.setRGB(this.color.r, this.color.g, this.color.b);
            this.material.emissive.setRGB(this.color.r, this.color.g, this.color.b);
        }

    };

    this.__mapTempToColor = function(sensorValue){
        if (sensorValue == 'E' || sensorValue == null) {
            return tinycolor(this.agTypes.colors.errorValueColor);
        } else if (sensorValue == 'T' && this.telemetryType == agTypes.telemetry.type.moisture) {
            return tinycolor(this.agTypes.colors.disableColor);
        } else {
            let color;
            let val = Number(sensorValue);
            if (val <= this.telemetryLimits[0]) {
                color = tinycolor(this.telemetryColors[0]);
            } else if (val >= this.telemetryLimits[this.telemetryLimits.length - 1]) {
                color = tinycolor(this.telemetryColors[this.telemetryColors.length - 1]);
            } else {
                let firstIndex;
                let secondIndex;
                for (let i = 0; i < this.telemetryLimits.length; i++) {
                    if (val <= this.telemetryLimits[i + 1]) {
                        firstIndex = i;
                        secondIndex = i + 1;
                        break;
                    }
                }
                let firstColor = this.telemetryColors[firstIndex];
                let secondColor = this.telemetryColors[secondIndex];
                let percent = ((val - this.telemetryLimits[firstIndex]) /
                    (this.telemetryLimits[secondIndex] - this.telemetryLimits[firstIndex])) * 100;
                color = tinycolor.mix(firstColor, secondColor, percent);
            }
            return color.setAlpha(0.75);
        }
    };

    this.getPosition = function(){
        return this.objects.position;
    };

    this.disable = function () {
        this.isDisabled = true;
        let g = 85;
        this.material.color.setRGB(g, g, g);
        this.material.emissive.setRGB(0, 0, 0);
    }

}

function Compass(silo_base_length){
    this.objects = new THREE.Object3D();
    this.material = new THREE.MeshLambertMaterial({side: THREE.DoubleSide});
    this.material.color = new THREE.Color(0x2c3e50);
    this.siloType = agTypes.siloType.cylinder

    // Ring
    let ring_radius;
    let ring_radius_out;
    if (this.siloType == agTypes.siloType.cylinder) {
        ring_radius = silo_base_length * 0.5 * 1.5;
        ring_radius_out = ring_radius + ring_radius * 0.1;
    } else if (this.siloType == agTypes.siloType.building) {
        ring_radius = silo_base_length * 0.5 * 1.2;
        ring_radius_out = ring_radius + ring_radius * 0.05;
    }
    let ring_geometry = new THREE.RingGeometry(ring_radius, ring_radius_out, 32, 1, 0, Math.PI * 2.0);
    let ring_mesh = new THREE.Mesh( ring_geometry, this.material );
    ring_mesh.rotation.x = Math.PI / 2;
    this.objects.add(ring_mesh);

    // North Arrow
    let arrow_geometry = new Geometry();
    let arrow_size;
    if (this.siloType == agTypes.siloType.cylinder) {
        arrow_size = ring_radius * 0.75;
    } else if (this.siloType == agTypes.siloType.building) {
        arrow_size = ring_radius * 0.35;
    }
    arrow_geometry.vertices.push(
        new THREE.Vector3( 0, 0, -arrow_size * 0.5 ),
        new THREE.Vector3( 0, 0,  arrow_size * 0.5 ),
        new THREE.Vector3( arrow_size, 0, 0 )
    );

    arrow_geometry.faces.push( new Face3( 0, 1, 2 ) );
    arrow_geometry.computeBoundingSphere();
    arrow_geometry.computeFaceNormals();
    let arrow_mesh = new THREE.Mesh( arrow_geometry.toBufferGeometry(), this.material );
    arrow_mesh.position.x = ring_radius_out * 1.1;

    this.objects.add(arrow_mesh);
}


function Highlighter(center_diamter, length, base_radius){
    this.objects = new THREE.Object3D();
    let center_radius = center_diamter * 0.5;

    this.material = new THREE.MeshPhongMaterial(); // TODO: 0x000000
    this.material.transparent = false;
    let pointer_geometry = new THREE.ConeGeometry(base_radius, length, 8);

    let pointer1 = new THREE.Mesh(pointer_geometry, this.material);
    pointer1.rotateZ(Math.PI);
    pointer1.position.setY(center_radius + length * 0.5);

    let pointer2 = new THREE.Mesh(pointer_geometry, this.material);
    pointer2.rotateZ(Math.PI * 0.5);
    pointer2.position.setX(center_radius + length * 0.5);

    let pointer3 = new THREE.Mesh(pointer_geometry, this.material);
    pointer3.position.setY(-(center_radius + length * 0.5 ));

    let pointer4 = new THREE.Mesh(pointer_geometry, this.material);
    pointer4.rotateZ(-Math.PI * 0.5);
    pointer4.position.setX(-(center_radius +  + length * 0.5));

    this.objects.add(pointer1);
    this.objects.add(pointer2);
    this.objects.add(pointer3);
    this.objects.add(pointer4);

    //this.objects.position.set(new THREE.Vector3(0, 0, 0));

    this.objects.position.x = 0;
    this.objects.position.y = 2;
    this.objects.position.z = 0;

    this.objects.rotateX(Math.PI * 0.5);

    this.hide = function(){
        this.objects.visible = false
    };

    this.show = function () {
        this.objects.visible = true;
    };

    this.showAt = function (position) {
        this.objects.position.x = position.x;
        this.objects.position.y = position.y;
        this.objects.position.z = position.z;

        this.show();
    }

}



/*

  computeDisabledSensors (siloData) {
    let enabledSensorsAttr = siloData.silo.enabledSensors;
    let disabledSensors = {};
    if (enabledSensorsAttr) {
      let enabledSensors = JSON.parse(enabledSensorsAttr) // angular.fromJson(enabledSensorsAttr);
      siloData.sensorLines.forEach((sensorLine) => {
        let sensorLineDisabledSensors = [];
        let sensorLineEnabledSensors;
        if (enabledSensors[sensorLine.id.id]) {
          sensorLineEnabledSensors = enabledSensors[sensorLine.id.id];
        } else {
          sensorLineEnabledSensors = [];
        }
        for (let sensorId=0;sensorId<sensorLine.sensorCount;sensorId++) {
          if (sensorLineEnabledSensors.indexOf(sensorId) == -1) {
            sensorLineDisabledSensors.push(sensorId);
          }
        }
        disabledSensors[sensorLine.lineNum] = sensorLineDisabledSensors;
      });
    } else {
      siloData.sensorLines.forEach((sensorLine) => {
        disabledSensors[sensorLine.lineNum] = [];
      });
    }
    return disabledSensors;
  }

  computeTemperatureLimits (maxTemperature) {
      if (angular.isUndefined(maxTemperature) || maxTemperature == null){
          maxTemperature = 25;
      }
      this.telemetryLimits = [];
      let tempLimitStep = maxTemperature / tempLimitInterval;
      for (let t=0;t<=maxTemperature;t+=tempLimitStep) {
          this.telemetryLimits.push(t);
      }
  }

*/