import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { Geolocation, PositionError } from "@ionic-native/geolocation/ngx";
import { Platform, ToastController } from "@ionic/angular";
import { BehaviorSubject, Subscription } from "rxjs";
import { first, debounceTime } from "rxjs/operators";
import { LocationAccuracy } from '@ionic-native/location-accuracy';
import { AuthService } from "./auth.service";
import { onAuthenticationChange, onResume } from "../util";
import { AppState } from "../state";
import { Store } from "@ngrx/store";
import { AttributeService } from "./entity.service";
import { AttributeScope } from "../models/telemetry.model";
import { PermissionService } from "../menus";
import { OpenNativeSettings } from "@awesome-cordova-plugins/open-native-settings/ngx";

import * as Geo from '@capacitor/geolocation'

export interface ILocation {
  coords?: {latitude: number, longitude: number, accuracy: number}, error?: number
  type?: string, ts?: number, date?: string
}

// GeolocationPosition is not available: https://stackoverflow.com/questions/65916073/angular-11-why-cant-the-compiler-find-geolocationposition-during-compiling
function parseLoc (loc: any, type: string): ILocation {
  let c = loc.coords
  return {
    coords: {
      latitude: c.latitude, longitude: c.longitude,
      accuracy: c.accuracy
    },
    type: type,
    ts: loc.timestamp, date: new Date(loc.timestamp).toString()
  }
}

function isPositionError (x): x is PositionError {
  return x && x.code != undefined
}


@Injectable()
export class LocationService {
  onLocation = new BehaviorSubject<ILocation>({})
  userId

  constructor (
    private geolocation: Geolocation, 
    private permissions: AndroidPermissions,
    private toast: ToastController,
    private store: Store<AppState>,
    private attributes: AttributeService,
    private nativeSettings: OpenNativeSettings,
    private platform: Platform,
    private permService: PermissionService
  ) {
    this.store.select(state => state.auth?.userDetails?.id).subscribe(userId => {
      //console.log('location user id updated', userId, this.userId, userId != this.userId)
      if (userId != this.userId) {
        this.userId = userId
        this.logLocation()
      }
    })
    this.onLocation.subscribe(x => {
      let userId = this.userId
      //console.log('save location', userId, x.coords)
      if (!x.coords?.latitude || !x.coords?.longitude || !userId) return
      this.attributes.saveEntityAttributes(
        userId, AttributeScope.SERVER_SCOPE, [
          {key: 'latitude', value: x.coords.latitude},
          {key: 'longitude', value: x.coords.longitude}
        ]
      ).toPromise()
    })
    this.onLocation.subscribe(x => {
      if (x.coords) {
        localStorage.setItem('__LOCATION__', JSON.stringify(x))
      }
    })
    try {
      let data = localStorage.getItem('__LOCATION__')
      if (data) {
        let loc = JSON.parse(data)
        console.log('loading local stored location', loc)
        this.onLocation.next(loc)
      }
    } catch (err) {
      console.error('failed to load inital location')
    }
    this.autoLogLocation()
    onResume().subscribe(x => this.logLocation())
  }

  async logLocation () {
    let p = await this.checkPermissions()
    if (p == 'granted') {
      this.getCurrentLocation()
    }
  }

  async autoLogLocation () {
    let ahour = 1000 * 60 * 60 * 24
    await this.logLocation()
    setTimeout(() => this.autoLogLocation(), ahour)
  }

  get current () { return this.onLocation.getValue() }
  
  userErrorMessage () {
    if (!this.current.error) return ''
    else if (this.current.error == 1) return "gps location is blocked in app"
    return "unable to get gps location"
  }

  /*
  _watcher: Subscription
  async watch() {
    if (this._watcher) return console.log('GPS: already watching location')
    try {
      console.log('GPS: Start watching position!')
      this._watcher = Geo.Geolocation.watchPosition({enableHighAccuracy: true}, 
        pos => console.log('GPS: ios received pos', pos.coords.latitude)
      )

      this._watcher = this.geolocation.watchPosition().subscribe(resp => {
        console.log('GPS: response', resp)
        if (isPositionError(resp)) {
          console.error('GPS: failed to get location', resp.code, resp.message)
          this.onLocation.next({error: resp.code, type: 'BROWSER'})
        } else {
          console.log('GPS: got location', resp.coords, resp.timestamp)
          let loc = parseLoc(resp, 'BROWSER')
          this.onLocation.next(loc) 
        }
      })
    }
    catch (err) { 
      console.log('err', err) 
      this._watcher = null
    }
  }

  clearWatch() {
    if (this._watcher)
      this._watcher.unsubscribe()
    this._watcher = null
  }

  reset () {
    this.clearWatch()
    this.watch()
  }*/

  async requestWebPermission () {
    let state = await this.getWebPermissionState()
    if (state == 'granted' || state == 'prompt') {
      return true
    } else {
      //this.permService.notifyPermissionDenied('GPS is blocked in app')
      return false
    }
  }

  async getWebPermissionState (): Promise<string> {
    console.log('request gps permission', this.current)
    if (navigator?.permissions?.query) {
      let state = await navigator.permissions.query({
        name: 'geolocation'
      })
      console.log('request result', state)
      return state.state
    } else if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          pos => {
            console.log('getWebPermissionState: position', pos)
            this.onLocation.next(parseLoc(pos, 'BROWSER'))
            resolve('granted')
            
          },
          e => {
            console.log('getWebPermissionState: error', e)
            resolve('denied')
          }
        );
      })
    } else {
      return 'denied'
    }
  }
  
  emitError () {
    //if (err?.error == 1)
    //this.onLocation.next({error: 1})
  }
  /*emitLocation (loc: ILocation) {
    this.onLocation.next({
      coords: {latitude: coord.latitude, longitude: coord.longitude, accuracy: 1}
    })
  }*/

  async getCurrentLocation (): Promise<ILocation | null> {
    console.log('GPS: get current location')
    let success = await this.activateGPS()
    this.permService.setPermission('GPS', success)
    if (success) {
      let pos: ILocation
      try {
        if (Capacitor.isNativePlatform()) {
          pos = await this.getMobileLocation()
        } else {
          pos = await this.getBrowserLocation()
        }
      } catch (err) {
        console.error('failed to get geo location')
        this.emitError()
        return null
      }
      //let loc = parseLoc(pos, 'BROWSER')
      this.onLocation.next(pos)
      return pos
    } else {
      this.emitError()
    }
    return null
  }

  private async getMobileLocation () {
    let pos = await Geo.Geolocation.getCurrentPosition()
    return parseLoc(pos, 'BROWSER')
  }

  private async getBrowserLocation () {
    
    return new Promise<ILocation>((_resolve, _reject) => {
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            _resolve(parseLoc(position, 'BROWSER'))
          },
          (err) => {
            console.error('gps error', err)
            _resolve({error: err.code, type:'BROWSER'})
            //_reject('Geolocation: ' + err.message)
          }, { enableHighAccuracy: true, timeout: 8000, maximumAge: 0}
        );
      } else {
        _reject("Your browser doesn't support geolocation")
      }
    })
  }

  async checkPermissions () {
    if (Capacitor.isNativePlatform()) {
      let p = await Geo.Geolocation.checkPermissions()
      return p.location
    } else {
      let state = await this.getWebPermissionState()
      return state
    }
  }

  // https://enappd.com/blog/ionic-5-complete-guide-on-geolocation/141/#15f0
  async activateGPS () {
    //console.log('GPS: activate')
    if (Capacitor.isNativePlatform()) {
      //console.log('GPS: ios request permission')
      let r = await Geo.Geolocation.requestPermissions()
      console.log('GPS: native permission result:', r.location, r.coarseLocation)
      return r.location == 'granted'
    } else {
      return await this.requestWebPermission()
    }
    /*const hasPermission = await this.checkGPSPermission();
    return hasPermission
    
    if (hasPermission) {
      //console.log('GPS: has permission')
      if (Capacitor.isNativePlatform()) {
        //console.log('GPS: activate gps')
        const canUseGPS = await this.askToTurnOnGPS();
        this.postGPSPermission(canUseGPS);
      }
      else { this.postGPSPermission(true); }
    }
    else {
      if (!Capacitor.isNativePlatform()) {
        return false
      }
      
      const permission = await this.requestGPSPermission();
      //console.log('GPS: will request permission', permission)
      if (permission === 'CAN_REQUEST' || permission === 'GOT_PERMISSION') {
        //console.log('GPS: activate got permission', Capacitor.isNativePlatform())
        if (Capacitor.isNativePlatform()) {
          const canUseGPS = await this.askToTurnOnGPS();
          this.postGPSPermission(canUseGPS);
        }
        else { this.postGPSPermission(true); }
      }
      else {
        console.log('GPS: show location denied permission toast')
      }
    }
    return hasPermission*/
  }
  /*
  async postGPSPermission (canUseGPS: boolean) {
    if (canUseGPS) {  }
    else {
      console.log('GPS: show turn on gps toast')
      let toast = await this.toast.create({
        message: 'Please turn on GPS to get location',
        duration: 5000, position: 'top',
        cssClass: 'checkout-toast', buttons: [
          {
            side: 'end',
            //icon: 'refresh',
            text: 'Open Settings',
            handler: () => { 
              console.log('GPS: open location settings')
              this.nativeSettings.open('application_details')
              //this.activateGPS()
            }
          }
        ]
      });
      await toast.present();
    }
  }*/
  /*
  async checkGPSPermission(): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      //console.log('GPS: checking permissions')
      if (Capacitor.isNativePlatform()) {
        //console.log('GPS: checking android fine-location-access')
        this.permissions.checkPermission(this.permissions.PERMISSION.ACCESS_FINE_LOCATION).then(
          result => {
            //console.log('GPS: check-permission result:', result.hasPermission)
            if (result.hasPermission) {
              // If having permission show 'Turn On GPS' dialogue
              console.log('GPS: has permission')
              resolve(true);
            } else {
              // If not having permission ask for permission
              console.error('GPS: failed to request permissions')
              resolve(false);
            }
          },
          err => {alert(err);}
        );}
      else {
        console.log('GPS: not native platform')
        this.requestWebPermission().then(x => resolve(x))
        //resolve(true)
      }
    })
  }

  async requestGPSPermission(): Promise<string> {
    console.log('GPS: request permission')
    
    return await new Promise((resolve, reject) => {
      
      LocationAccuracy.canRequest().then((canRequest: boolean) => {
        console.log('GPS: can request =', canRequest)
        if (canRequest) {
          resolve('CAN_REQUEST');
        } else {
          // Show 'GPS Permission Request' dialogue
          console.log('GPS: request using android-permissions')
          this.permissions.requestPermission(this.permissions.PERMISSION.ACCESS_FINE_LOCATION)
            .then((result) => {
              if (result.hasPermission) {
                // call method to turn on GPS
                console.log('GPS: got permission')
                resolve('GOT_PERMISSION');
              } else {
                console.error('GPS: denied permission')
                resolve('DENIED_PERMISSION');
              }
            },
            error => {
              // Show alert if user click on 'No Thanks'
              alert('requestPermission Error requesting location permissions ' + error);
            });
        }
      });
    })
  }
  async askToTurnOnGPS(): Promise<boolean> {
    console.log('GPS: ask to turn on gps')
    return await new Promise((resolve, reject) => {
      // the accuracy option will be ignored by iOS
      //if (this.platform.is('ios')) return resolve(true)
      LocationAccuracy.canRequest().then((canRequest: boolean) => {
        console.log('GPS: ask-turn-on canRequest=', canRequest)
        if (canRequest) {
          LocationAccuracy.request(LocationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
            () => {
              console.log('GPS: ask to turn on success')
              resolve(true);
            },
            error => {
              console.error('GPS: ask to turn on error')
              resolve(false);
            }
          );
        } else {
          console.error('GPS: cannot request high-accuracy gps')
          resolve(true);
        }
      });
    })
  }*/
}
