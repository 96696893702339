
import { EventEmitter, Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Asset, Device, Entity, EntityId, EntityType, IAlarm, PageData } from '../models/entity.model';
import { AggregationType, AttributeData, AttributeScope } from '../models/telemetry.model';
import { AGUserService } from './user.services';
import { map, switchMap, tap } from 'rxjs/operators';
import { defaultHttpOptionsFromConfig, RequestConfig } from '../models/auth.model';
import { AuthService } from './auth.service';


function isAssetEntity (entity: Entity<any>): entity is Asset {
  return entity.id.entityType == EntityType.ASSET
}
function isDeviceEntity (entity: Entity<any>): entity is Device {
  return entity.id.entityType == EntityType.DEVICE
}

@Injectable({
  providedIn: 'root'
})
export class AttributeService {
  constructor(
    private http: HttpClient
  ) { }


  public getEntityAttributes(entityId: EntityId<any>, attributeScope: AttributeScope,
                             keys?: Array<string>, config?: RequestConfig): Observable<Array<AttributeData>> {
    let url = `/api/plugins/telemetry/${entityId.entityType}/${entityId.id}/values/attributes/${attributeScope}`;
    if (keys && keys.length) {
      url += `?keys=${keys.join(',')}`;
    }
    return this.http.get<Array<AttributeData>>(url, defaultHttpOptionsFromConfig(config));
  }

  public deleteEntityAttributes(entityId: EntityId<any>, attributeScope: AttributeScope, attributes: Array<AttributeData>,
                                config?: RequestConfig): Observable<any> {
    const keys = attributes.map(attribute => encodeURI(attribute.key)).join(',');
    return this.http.delete(`/api/plugins/telemetry/${entityId.entityType}/${entityId.id}/${attributeScope}` +
      `?keys=${keys}`,
      defaultHttpOptionsFromConfig(config));
  }

  public deleteEntityTimeseries(entityId: EntityId<any>, timeseries: Array<AttributeData>, deleteAllDataForKeys = false,
                                config?: RequestConfig): Observable<any> {
    const keys = timeseries.map(attribute => encodeURI(attribute.key)).join(',');
    return this.http.delete(`/api/plugins/telemetry/${entityId.entityType}/${entityId.id}/timeseries/delete` +
      `?keys=${keys}&deleteAllDataForKeys=${deleteAllDataForKeys}`,
      defaultHttpOptionsFromConfig(config));
  }

  public saveEntityAttributes(entityId: EntityId<any>, attributeScope: AttributeScope, attributes: Array<AttributeData>,
                              config?: RequestConfig): Observable<any> {
    const attributesData: {[key: string]: any} = {};
    const deleteAttributes: AttributeData[] = [];
    attributes.forEach((attribute) => {
      if (attribute.value != null) {
        attributesData[attribute.key] = attribute.value;
      } else {
        deleteAttributes.push(attribute);
      }
    });
    let deleteEntityAttributesObservable: Observable<any>;
    if (deleteAttributes.length) {
      deleteEntityAttributesObservable = this.deleteEntityAttributes(entityId, attributeScope, deleteAttributes, config);
    } else {
      deleteEntityAttributesObservable = of(null);
    }
    let saveEntityAttributesObservable: Observable<any>;
    if (Object.keys(attributesData).length) {
      saveEntityAttributesObservable = this.http.post(`/api/plugins/telemetry/${entityId.entityType}/${entityId.id}/${attributeScope}`,
        attributesData, defaultHttpOptionsFromConfig(config));
    } else {
      saveEntityAttributesObservable = of(null);
    }
    return forkJoin([saveEntityAttributesObservable, deleteEntityAttributesObservable]);
  }
  
  public saveEntityTimeseries(entityId: EntityId<any>, timeseriesScope: string, timeseries: Array<AttributeData>,
                              config?: RequestConfig): Observable<any> {
    const timeseriesData: {[key: string]: any} = {};
    const deleteTimeseries: AttributeData[] = [];
    timeseries.forEach((attribute) => {
      if (attribute.value != null) {
        timeseriesData[attribute.key] = attribute.value;
      } else {
        deleteTimeseries.push(attribute);
      }
    });
    let deleteEntityTimeseriesObservable: Observable<any>;
    if (deleteTimeseries.length) {
      deleteEntityTimeseriesObservable = this.deleteEntityTimeseries(entityId, deleteTimeseries, true, config);
    } else {
      deleteEntityTimeseriesObservable = of(null);
    }
    let saveEntityTimeseriesObservable: Observable<any>;
    if (Object.keys(timeseriesData).length) {
      saveEntityTimeseriesObservable =
        this.http.post(`/api/plugins/telemetry/${entityId.entityType}/${entityId.id}/timeseries/${timeseriesScope}`,
        timeseriesData, defaultHttpOptionsFromConfig(config));
    } else {
      saveEntityTimeseriesObservable = of(null);
    }
    return forkJoin([saveEntityTimeseriesObservable, deleteEntityTimeseriesObservable]);
  }

  public getEntityTimeseries(entityId: EntityId<any>, keys: Array<string>, startTs: number, endTs: number,
                             limit: number = 100, agg: AggregationType = AggregationType.NONE, interval?: number,
                             useStrictDataTypes: boolean = false,
                             config?: RequestConfig): Observable<any> {
    let url = `/api/plugins/telemetry/${entityId.entityType}/${entityId.id}/values/timeseries?keys=${keys.join(',')}&startTs=${startTs}&endTs=${endTs}`;
    if (limit != null) {
      url += `&limit=${limit}`;
    }
    if (agg != null) {
      url += `&agg=${agg}`;
    }
    if (interval != null) {
      url += `&interval=${interval}`;
    }
    if (useStrictDataTypes != null) {
      url += `&useStrictDataTypes=${useStrictDataTypes}`;
    }

    return this.http.get<any>(url, defaultHttpOptionsFromConfig(config));
  }
}


@Injectable()
export class EntityService {
  onEntityUpdated = new EventEmitter<Entity<any>>()

  constructor (
    private userService: AGUserService,
    private http: HttpClient, private auth: AuthService
  ) {}

  private async saveDevice (device: Device, config={}) {
    var url = '/api/device';
    await this.http.post(url, device, config).toPromise()
  }

  private async saveAsset (device: Asset, config={}) {
    var url = '/api/asset';
    await this.http.post(url, device, config).toPromise()
  }

  async saveEntity (entity: Entity<any>, config={}) {
    if (isAssetEntity(entity)) {
      await this.saveAsset(entity, config)
    } else if (isDeviceEntity(entity)) {
      await this.saveDevice(entity, config)
    } else {
      throw new Error('entity is not a device or asset')
    }
    this.onEntityUpdated.emit(entity)
  }

  async getDeviceById (id: string) {
    return this.makeDevice(await this.http.get<Device>('/api/device/' + id).toPromise())
  }

  async fetchCustomerAlarms (since: number) {
    let result = await this.auth.getAuth().pipe(
      switchMap(auth => {
        let url = `/api/alarm/CUSTOMER/${auth.authUser.customerId}?pageSize=100&startTime=${since}&page=0&fetchOriginator=true&ascOrder=false&status=ACTIVE_UNACK`
        return this.http.get<PageData<IAlarm>>(url)
      })
    ).toPromise()
    return result.data
  }
  async fetchEntityAlarms (entityId: EntityId<any>, since: number) {
    let url = `/api/alarm/${entityId.entityType}/${entityId.id}?pageSize=100&startTime=${since}&page=0&fetchOriginator=true&ascOrder=false&status=ACTIVE_UNACK`
    let result = await this.http.get<PageData<IAlarm>>(url).toPromise()
    return result.data
  }

  async getDevicesByType (type: string=null) {
    return await this.auth.getAuth().pipe(
      switchMap(auth => {
        return this.getCustomerDevices(auth.authUser.customerId, {limit: 1000}, null, null, type)
      })
    ).toPromise()
  }
  private getCustomerDevices(customerId, pageLink, applyCustomersInfo, config, type) {
    //var url = '/api/customer/' + customerId + '/devices?limit=' + pageLink.limit;
    var url = '/api/customer/' + customerId + '/devices?pageSize=50&page=0' // + pageLink.limit;
    if (pageLink.textSearch != null) {
        url += '&textSearch=' + pageLink.textSearch;
    }
    if (pageLink.idOffset != null) {
        url += '&idOffset=' + pageLink.idOffset;
    }
    if (pageLink.textOffset != null) {
        url += '&textOffset=' + pageLink.textOffset;
    }
    if (type != null && type.length) {
        url += '&type=' + type;
    }
    // include config?
    return this.http.get<PageData<Device>>(url).pipe(
      tap(x => {
        x.data.map(e => this.makeDevice(e))
      })
    )
  }

  async getCustomerAssets () {
    return await this.auth.getAuth().pipe(
      switchMap(auth => {
        let customerId = auth.authUser.customerId
        let url = '/api/customer/' + customerId + '/assets?pageSize=50&page=0'
        return this.http.get<PageData<Asset>>(url)
      }),
      map(x => x.data)
    ).toPromise()
  }

  async getAssetById (id: string) {
    return this.makeDevice(await this.http.get<Asset>('/api/asset/' + id).toPromise())
  }

  assetById (id: string) {
    return this.http.get<Asset>('/api/asset/' + id).pipe(
      map((v) => this.makeDevice(v))
    )
  }


  makeDevice<T extends Device | Asset> (entity: T): T {
    if (!entity.label)
      entity.label = entity.name
    //this.alarms.add_device(entity)
    return entity
  }
  
  async getTenantDevices(pageLink, applyCustomersInfo, config, type=null) {
    var url = '/api/tenant/devices?limit=' + pageLink.limit;
    if (pageLink.textSearch != null) {
      url += '&textSearch=' + pageLink.textSearch;
    }
    if (pageLink.idOffset != null) {
      url += '&idOffset=' + pageLink.idOffset;
    }
    if (pageLink.textOffset != null) {
      url += '&textOffset=' + pageLink.textOffset;
    }
    if (type != null && type.length) {
      url += '&type=' + type;
    }
    return await this.http.get<PageData<Device>>(url).toPromise()
  }
}

