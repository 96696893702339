import { ModalController, Platform } from "@ionic/angular";
import moment from "moment-es6";
import { agTypes } from "./ag-types";
import tinycolor from 'tinycolor2'
import { Entity, EntityType } from "./models/entity.model";
import { BehaviorSubject, fromEvent, merge, Observable } from "rxjs";

import { install } from 'resize-observer';
import { map, mergeMap, takeWhile, tap } from "rxjs/operators";

import * as shortid from 'shortid'
import { AppState, selectAuth } from "./state";
import { select, Store } from "@ngrx/store";
import { Injectable } from "@angular/core";

declare var ResizeObserver
if (!window['ResizeObserver']) install();

export interface Index<T> { [key: string]: T }

import { ActivatedRoute, Router, NavigationStart } from "@angular/router";
import { TEXT } from "./texts";
import { ISensorData } from "./services/types.service";
import { ATTRIBUTES, CROPS, DEFAULT_LOCALE, ENTITIES, FARMER_CROPS, LOCALES } from "./constant";

/*
export interface ILanguage {
  locale: string, name: string
}

export const LOCALES = [
  'en', 'da', 'fr'
]
export const DEFAULT_LOCALE = 'en'

export const LANGUAGES: ILanguage[] = LOCALES.map(x => {
  return {locale: x, name: getLanguageTranslateKey(x)}
})*/


export function removeNullValues (values: object): object {
  let result = {}
  for (var k in values) {
    let value = values[k]
    if (value != null && value != undefined) {
      result[k] = value
    }
  }
  return result
}


@Injectable()
export class AgModal {
  constructor (private route: Router) {}
  async openModal (modal: HTMLIonModalElement) {
    window.history.pushState(null, "Back", window.location.href);
      
    modal.onDidDismiss().then(x => {
      window.onpopstate = null;
      if (x.data?.redirect) {
        this.route.navigateByUrl(x.data.redirect)
        //window.history.pushState(null, "Back", x.data.redirect);
      } else {
        window.history.go(-1);
      }
    })

    window.onpopstate = () => {
      modal.dismiss();
      window.history.pushState(null, "Back", window.location.href);
    };
    await modal.present();
    return await modal.onWillDismiss();
  }
}

export function onElementResize (elm: HTMLElement) {
  return new Observable(subscriber => {
    var ro = new ResizeObserver(entries => {
      subscriber.next(entries);
    });

    // Observe one or multiple elements
    subscriber.next([])
    ro.observe(elm);
    return function unsubscribe() {
      ro.unobserve(elm);
    }
    
  })
}

export function onConnectionState () {
  return new Observable<boolean>(subscriber => {
    let online = fromEvent(window, 'online').subscribe(x => {
      subscriber.next(navigator.onLine)
    })
    let offline = fromEvent(window, 'offline').subscribe(x => {
      subscriber.next(navigator.onLine)
    })
    subscriber.next(navigator.onLine)
    return () => {
      online.unsubscribe()
      offline.unsubscribe()
    }
  })
}

export function onAuthenticationChange (store: Store<AppState>) {
  return new Observable<boolean>(sub => {
    let state = {customerId: 'initial-value'}
    let s = store.pipe(select(selectAuth)).subscribe(
      (e) => {
        if (e.isAuthenticated && e.authUser.customerId != state.customerId) {
          state.customerId = e.authUser.customerId
          sub.next(true)
        }
        if (!e.isAuthenticated && state.customerId) {
          state.customerId = null
          sub.next(false)
        }
      }
    );
    return () => s.unsubscribe()
  })
}

export function onResume () {
  return new Observable(subscriber => {
    function handler (evt) {
      console.warn('system resumed', evt)
      subscriber.next()
    }
    document.addEventListener('resume', handler)
    return () => document.removeEventListener('resume', handler)
  })
}

interface IDragEvent {
  start: TouchEvent | MouseEvent, move: TouchEvent | MouseEvent
}
export function onMouseDrag (el: HTMLElement, opt: AddEventListenerOptions) {
  return new Observable<IDragEvent>(subscriber => {
    //let up = merge(fromEvent(el, 'mouseup', opt), fromEvent(el, 'touchend', opt))
    opt = {capture: true}
    let move = merge(
      fromEvent(window, 'mousemove', opt), fromEvent(window, 'touchmove', opt), 
      fromEvent(window, 'mouseup', opt), fromEvent(window, 'touchend', opt)
    )
    let uptype = ['mouseup', 'touchend']
    let down = merge(fromEvent(el, 'mousedown', opt), fromEvent(el, 'touchstart', opt))
    let s = down.pipe(
      tap(x => x.preventDefault()),
      mergeMap(st => move.pipe(takeWhile(x => !uptype.includes(x.type), true), map(x => { 
        return {start: st, move: x} 
      })))
    ).subscribe(x => {
      let evt = x as IDragEvent
      subscriber.next(evt) 
    })
    return () => {
      //console.warn('UNSUBSCIBE')
      s.unsubscribe()
    }
  })
}

export function timeDisplay (ts: number) {
  if (!ts) return "-"
  return moment(ts).format("MMMM Do YYYY, HH:mm")
}

export enum DATASTATE {
  LOADING='LOADING', INITIAL='INITIAL', SUCCESS='SUCCESS', ERROR='ERROR',
  RETRYING='RETRYING'
}
export class LoadingState {
  events = new BehaviorSubject<LoadingState>(this) // EventEmitter<LoadingState>()
  state: DATASTATE = DATASTATE.INITIAL
  message: string = ''

  is_initial () { return this.state == DATASTATE.INITIAL }
  is_success () { return this.state == DATASTATE.SUCCESS }
  is_complete () { return this.is_success() || this.is_failed() }
  is_loading () { return !this.is_success() && !this.is_failed() }
  is_failed () { return this.state == DATASTATE.ERROR }
  success () { this.state = DATASTATE.SUCCESS; this.events.next(this) }
  loading (isLoading=true, msg?: string) { 
    if (isLoading)
      this.state = DATASTATE.LOADING; 
    else
      this.state = DATASTATE.SUCCESS;
    this.message = msg
    this.events.next(this)
  }
  error (msg?: string | Error) {
    if (msg instanceof Error) {
      msg = msg.message
    } 
    this.message = msg
    this.state = DATASTATE.ERROR; this.events.next(this) }

  async waitLoaded () {
    if (this.is_complete()) return
    return new Promise<void>((resolve, reject) => {
      const _do_resolve = () => {
        if (sub) sub.unsubscribe()
        resolve()
      }
      const _do_reject = () => {
        if (sub) sub.unsubscribe()
        reject()
      }
      let sub = this.events.subscribe(e => {
        if (e.is_success()) _do_resolve()
        else if (e.is_failed()) _do_reject()
      })
    })
    
  }

}


export function isSensorLinesDevice (type: string) {
  return type == agTypes.farmerDeviceTypes.slmCable.value ||
    type == agTypes.farmerDeviceTypes.sltCable.value
}
export function isTMSDevice (type: string) {
  return type == agTypes.farmerDeviceTypes.tmsGateway.value
}


const IMAGES = '/assets/images/'

const sensorsSpearImg = IMAGES + 'cellular_sensor_spear_03.png';
const cProImg = IMAGES + 'c_pro.png';
const superProImg = IMAGES + 'super_pro.png';
const farmProImg = IMAGES + 'farm_pro.png';
const superPointImg = IMAGES + 'super_point.png';
const farmPointImg = IMAGES + 'farm_point.png';
const superCombiImg = IMAGES + 'combi.png';
const buildingImg = IMAGES + 'building.svg';
const siloImg = IMAGES + 'silo.svg'
const slmImg = IMAGES + 'moisture_sensor_cable.png'
const sltImg = IMAGES + 'temperature_sensor_cable.png'


export function deviceImage (deviceType: string) {
  switch (deviceType) {
    case agTypes.farmerDeviceTypes.cellularSensorSpear.value:
      return sensorsSpearImg;
    case agTypes.farmerDeviceTypes.cPro.value:
      return cProImg;
    case agTypes.farmerDeviceTypes.cProBT.value:
      return cProImg;
    case agTypes.farmerDeviceTypes.superPro.value:
      return superProImg;
    case agTypes.farmerDeviceTypes.farmPro.value:
      return farmProImg;
    case agTypes.farmerDeviceTypes.superPoint.value:
      return superPointImg;
    case agTypes.farmerDeviceTypes.farmPoint.value:
      return farmPointImg;
    case agTypes.farmerDeviceTypes.superproCombi.value:
      return superCombiImg
    case agTypes.farmerDeviceTypes.building.value:
      return buildingImg
    case agTypes.farmerDeviceTypes.silo.value:
      return siloImg
    case agTypes.farmerDeviceTypes.slmCable.value:
      return slmImg
    case agTypes.farmerDeviceTypes.sltCable.value:
      return sltImg   
  }
  return null;
}

const spearSensorSvg = "/assets/svg/spear_sensor.svg";
const farmerMoistureSvg = "/assets/svg/farmer_moisture.svg";
export function deviceIcon (deviceType: string) {
  switch (deviceType) {
    case agTypes.farmerDeviceTypes.cellularSensorSpear.value:
      return spearSensorSvg
    case agTypes.farmerDeviceTypes.cPro.value:
      return farmerMoistureSvg
    case agTypes.farmerDeviceTypes.cProBT.value:
      return farmerMoistureSvg
    case agTypes.farmerDeviceTypes.superPro.value:
      return farmerMoistureSvg
    case agTypes.farmerDeviceTypes.farmPro.value:
      return farmerMoistureSvg
    case agTypes.farmerDeviceTypes.superPoint.value:
      return farmerMoistureSvg
    case agTypes.farmerDeviceTypes.farmPoint.value:
      return farmerMoistureSvg
    case agTypes.farmerDeviceTypes.superproCombi.value:
      return farmerMoistureSvg
    case agTypes.farmerDeviceTypes.building.value:
      return "/assets/svg/building_icon2.svg"
    case agTypes.farmerDeviceTypes.silo.value:
      return "/assets/svg/building_icon2.svg"
    case agTypes.farmerDeviceTypes.slmCable.value:
      return farmerMoistureSvg
    case agTypes.farmerDeviceTypes.sltCable.value:
      return farmerMoistureSvg 
  }
}


export function makeMarkerSVG (content: string, color: string) {
  let svg = `
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="svg4408" x="0" y="0" 
  viewBox="0 0 150 150" xml:space="preserve">
  <style>.st2{fill:${color}} }.st4{fill:#333173}</style>
  <g id="layer1">
    <g id="path6881-3-5-5-1-8-4-4-7-8" transform="translate(-146.438 -276.028)" opacity=".892">
      <radialGradient id="SVGID_1_" cx="2190.864" cy="2461.432" r="49.901" gradientTransform="matrix(.6793 .0076 -.509 .5612 9.937 -1002.532)" gradientUnits="userSpaceOnUse">
        <stop offset="0"/><stop offset="1" stop-opacity=".188"/>
      </radialGradient>
      <path d="M285.6 388.5c10.3-12.4 4.4-22.4-14.4-22.4-18.9 0-42.4 10-53.9 22.4-16.8 18 .4 23.5-.2 35-.1 1.8 3.9 1.8 7 0 19.8-11.5 46.5-17 61.5-35" fill="url(#SVGID_1_)"/>      
    </g>
    <path id="path6881-3-5-5-1-8-4-4" class="st2" d="M124.7 69.1c-.9-27.5-22.3-49.8-49.8-49.8s-49 22.3-49.8 49.8c-1.3 40.1 30.7 52.2 44.7 78 2.2 4 8 4 10.1 0 14.1-25.8 46.1-37.9 44.8-78"/>
  </g>
  <g id="g4928">
    <circle id="path4978" class="st2" cx="74.9" cy="69.1" r="49.9"/>
    <g id="g4915">
      <path id="path6883-2-3-5-2-4-9-4-9" d="M74.8 106.4c-20.6 0-37.4-16.7-37.4-37.4 0-20.6 16.7-37.4 37.4-37.4 20.6 0 37.4 16.7 37.4 37.4s-16.7 37.4-37.4 37.4" fill="#fff"/>
    </g>
  </g>
  <foreignObject x="35" y="28" width="80" height="80">
   ${content}
  </foreignObject>
  <!--<image x="35" y="28" width="80" height="80" [attr.href]="icon" />-->
  
</svg>
  `
  return svg
}

const _ICON_URL_CACHE = {}
export function makeMarkerURL (content: string, color: string='#514f9d') {
  let key = color+content
  if (_ICON_URL_CACHE[key]) return _ICON_URL_CACHE[key]
  let xml = makeMarkerSVG(content, color)
  var svg64 = btoa(xml); //for utf8: btoa(unescape(encodeURIComponent(xml)))
  var b64start = 'data:image/svg+xml;base64,';
  var image64 = b64start + svg64;
  _ICON_URL_CACHE[key] = image64
  return image64
}

export const BUILDING_SVG = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:serif="http://www.serif.com/" width="100%" height="100%" viewBox="0 0 274 274" version="1.1" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;">
<g transform="matrix(0.93524,0,0,0.959899,9.15694,5.61565)">
    <path d="M47.792,111.171L47.792,188.431L225.604,188.431L225.604,111.171L136.698,65.03L47.792,111.171Z" style="fill:none;stroke:rgb(56,64,68);stroke-width:7.39px;"/>
</g>
<g transform="matrix(0.957069,-3.43103e-18,-3.43103e-18,1,5.86851,3.43314)">
    <path d="M47.792,111.171L225.604,111.171" style="fill:none;stroke:rgb(56,64,68);stroke-width:2.04px;"/>
</g>
</svg>`

export const SPEAR_SVG = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0" y="0" viewBox="0 0 82 82" xml:space="preserve">
<style>.st0{fill:#2e3337}</style>
<path d="M45.1 37.4l-2.8 3.8h-2.8l-2.8-3.8h8.4zM48 13l-2.8 16h-8.5l-2.8-16H48zm-2.9 16v8.5h-8.5V29h8.5z" fill="#2e3c4f"/>
<path class="st0" d="M45.8 38c-.2.2-.4.3-.6.3h-8.5c-.5 0-.9-.5-.9-.9V29c0-.5.5-.9.9-.9h8.5c.5 0 .9.4.9.9v8.5c-.1.2-.2.4-.3.5zm-8.2-1.6h6.6v-6.6h-6.6v6.6z"/><path class="st0" d="M45.8 29.7c-.2.2-.4.3-.6.3h-8.5c-.5 0-.8-.4-.9-.8l-2.8-16c-.1-.5.3-1 .7-1.1.5-.1 1 .3 1.1.7L37.5 28h6.8L47 12.8c.1-.5.5-.8 1.1-.7.5.1.8.5.7 1.1L46 29.1l-.2.6zm-2.9 12.1c-.2.2-.4.3-.6.3h-2.8c-.3 0-.5-.2-.7-.4L36 38c-.2-.3-.3-.6-.1-1 .2-.4.5-.5.8-.5h8.5c.4 0 .7.2.8.5.2.4.1.7-.1 1l-2.8 3.8c-.1-.1-.1-.1-.2 0zm-3-1.6h1.9l1.4-1.9h-4.7c.1 0 1.4 1.9 1.4 1.9z"/><path class="st0" d="M49.6 13.7c-.1.2-.3.3-.6.3H33c-.5 0-.9-.4-.9-.9s.5-.9.9-.9h16.1c.5 0 .9.4.9.9-.1.2-.2.5-.4.6zm-9.4 58.1c-.1.1-.3.2-.5.2-.4 0-.6-.3-.6-.6l-.3-29.6c0-.4.3-.6.6-.6s.6.3.6.6l.3 29.6c.1.1 0 .3-.1.4zm2.5-2.8c-.1.1-.3.2-.4.2-.4 0-.6-.3-.6-.6V40.7c0-.4.3-.6.6-.6.4 0 .6.3.6.6v27.8c0 .2-.1.3-.2.5z"/><path class="st0" d="M40.4 71.8c-.3.3-.6.3-.9 0-.3-.3-.3-.6 0-1l2.4-2.7c.3-.3.7-.4 1-.1.3.3.3.6 0 1l-2.5 2.8c0-.1 0 0 0 0z"/></svg>`

//<path d="M136.696,255.342c-65.525,-0 -118.642,-53.121 -118.642,-118.646c0,-65.521 53.117,-118.638 118.642,-118.638c65.525,0 118.641,53.117 118.641,118.638c0,65.525 -53.112,118.646 -118.641,118.646Zm-0,-255.342c-75.496,-0 -136.696,61.2 -136.696,136.696c0,75.496 61.2,136.696 136.696,136.696c75.496,-0 136.7,-61.2 136.7,-136.696c-0,-75.496 -61.204,-136.696 -136.7,-136.696Z" style="fill:#303c42;fill-rule:nonzero;"/>

export const BUILDING_FILL_SVG = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:serif="http://www.serif.com/" width="100%" height="100%" viewBox="0 0 274 274" version="1.1" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
<path d="M225.604,111.171l-177.812,-0l-0,77.26l177.812,0l0,-77.26Zm-88.906,-46.141l88.906,46.141l-177.812,-0l88.906,-46.141Z" style="fill:#303c42;"/></svg>`

export const MOISTURE_ICON = `
<svg xmlns="http://www.w3.org/2000/svg" width="82.012" height="82.025">
<g fill="#2e3337">
<path d="M32.223 59.702a.962.962 0 01-.334-.733.936.936 0 01.304-.756l1.536-1.483a.971.971 0 01.67-.285.958.958 0 01.685.253c1.845 1.575 3.978 2.384 6.401 2.427 2.424.042 4.585-.693 6.483-2.205a.966.966 0 01.693-.228.975.975 0 01.66.308l1.483 1.537c.213.22.305.474.278.765a.922.922 0 01-.302.666l-.057.055a14.196 14.196 0 01-4.367 2.448c-1.602.556-3.25.818-4.942.781a13.967 13.967 0 01-4.912-.953 14.185 14.185 0 01-4.279-2.597zm-5.848 5.605a.916.916 0 01-.311-.733.936.936 0 01.303-.734l1.627-1.57a.988.988 0 01.699-.29.951.951 0 01.69.269 17.677 17.677 0 005.638 3.537c2.093.816 4.202 1.243 6.327 1.28 2.125.036 4.248-.316 6.368-1.059a17.935 17.935 0 005.769-3.33.949.949 0 01.698-.244c.268.011.494.113.677.302l1.572 1.628a.933.933 0 01.278.742.947.947 0 01-.303.689l-.034.033a21.944 21.944 0 01-7.026 4.146 22.182 22.182 0 01-8.072 1.332 22.198 22.198 0 01-8.023-1.611 21.96 21.96 0 01-6.877-4.387M40.898 18.367c6.109 7.595 11.701 14.503 12.254 20.205.36 6.118-5.549 11.986-12.252 11.997h-.005c-6.703-.01-12.611-5.879-12.252-11.997.553-5.703 6.145-12.611 12.254-20.206zm0-5.418C33.927 21.77 25.888 29.993 25.1 38.56c-.538 8.396 7.065 15.8 15.789 15.816h.016c8.724-.015 16.326-7.42 15.79-15.816-.788-8.566-8.826-16.789-15.797-25.61h-.001"/>
</g></svg>
`
//<path d="M41.01 76.609c-19.658 0-35.593-15.936-35.593-35.593 0-19.657 15.935-35.592 35.592-35.592 19.657 0 35.593 15.935 35.593 35.592 0 19.657-15.934 35.593-35.593 35.593zm0-76.602C18.36.007 0 18.367 0 41.016s18.36 41.01 41.01 41.01c22.648 0 41.009-18.361 41.009-41.01 0-22.65-18.36-41.01-41.01-41.01"/>

export function markerIcon (deviceType: string, alarm: boolean) {
  let color = alarm ? '#ED6D05' : '#514f9d'
  switch (deviceType) {
    case agTypes.farmerDeviceTypes.building.value:
      return makeMarkerURL(BUILDING_FILL_SVG, color)
    case agTypes.farmerDeviceTypes.silo.value:
      return makeMarkerURL(BUILDING_FILL_SVG, color)
    default:
      return makeMarkerURL(SPEAR_SVG, color)
  }
}

export function deviceHasCrop (deviceType: string) {
  return deviceType != agTypes.farmerDeviceTypes.superproCombi.value
}

//export function deviceDescription (deviceType: string) { return '' }
export function deviceLabel (deviceType: string) {
  return deviceTypeItem(deviceType).name
}

export function deviceTypeItem (key: string) {
  return ENTITIES.find(x => x.key == key)
  /*for (var k in agTypes.farmerDeviceTypes) {
    if (agTypes.farmerDeviceTypes[k].value == key)
      return agTypes.farmerDeviceTypes[k]
  }
  throw new Error('device item not found: ' + key)*/
}

export function isSpear (deviceType: string) {
    return deviceType == agTypes.farmerDeviceTypes.cellularSensorSpear.value
}

export function isAsset (deviceType: string) {
  return agTypes.farmerDeviceTypes[deviceType].entityType == EntityType.ASSET
}

export function isQrDevice (deviceType: string) {
  return (deviceType == agTypes.farmerDeviceTypes.cProBT.value ||
          deviceType == agTypes.farmerDeviceTypes.cellularSensorSpear.value ||
          deviceType == agTypes.farmerDeviceTypes.tmsGateway.value ||
          deviceType == agTypes.farmerDeviceTypes.slmCable.value ||           
          deviceType == agTypes.farmerDeviceTypes.sltCable.value
        )
}

export const MEASUREMENT_PROFILES = [
  agTypes.farmerDeviceTypes.farmPro.value,
  agTypes.farmerDeviceTypes.superPro.value,
  agTypes.farmerDeviceTypes.cPro.value,
  agTypes.farmerDeviceTypes.cProBT.value,
  agTypes.farmerDeviceTypes.superPoint.value,
  agTypes.farmerDeviceTypes.farmPoint.value,
  agTypes.farmerDeviceTypes.superproCombi.value
]

export function isMoisture (deviceType: string) {
    if (deviceType === agTypes.farmerDeviceTypes.farmPro.value ||
        deviceType === agTypes.farmerDeviceTypes.superPro.value ||
        deviceType === agTypes.farmerDeviceTypes.cPro.value ||
        deviceType === agTypes.farmerDeviceTypes.cProBT.value ||
        deviceType === agTypes.farmerDeviceTypes.superPoint.value ||
        deviceType === agTypes.farmerDeviceTypes.farmPoint.value ||
        deviceType === agTypes.farmerDeviceTypes.superproCombi.value) {
        return true;
    }
}



export function isMobile (platform: Platform) {
  return platform.is('mobile') && !platform.is('mobileweb')
  //return (!document.URL.startsWith('http') || document.URL.startsWith('http://localhost:8080'));
}
export function isBrowser (platform: Platform) {
  return !isMobile(platform)
}
export function isIOS (platform: Platform) {
  return platform.is('ios')
}

// TODO: should maybe be settings

const _COLORS = {
  'current_min_temperature': "#ed6d05", 
  'current_avg_temperature': "#ed6d05", 
  'current_max_temperature': "#ed6d05",
  'current_min_emc': "#3498db", 
  'current_avg_emc': "#3498db", 
  'current_max_emc': "#3498db"
}
export function TelemetryKeyLabel (key: string) {
  if (key.startsWith('temperature-')) return [TEXT.general.temperature]
  else if (key.startsWith('humidity-')) return [TEXT.general.humidity]
  else if (key.startsWith('emc-')) {
    let crop = key.split('-')[1]
    return [TEXT.general.emc, CropLabel(crop)]
  }
  return [ATTRIBUTES.find(x => x.key == key)?.name || '']
}
export function TelemetryKeyColor (key: string) {
  if (key.startsWith('temperature-')) return "#ed6d05"
  else if (key.startsWith('humidity-') || key.startsWith('emc-')) return "#3498db"
  return _COLORS[key] || 'black'
}

// TODO: should properly have some more generic solution
export function entityURL (entity: Entity<any>) {
  if (entity.type == agTypes.farmerDeviceTypes.cellularSensorSpear.value) {
    return '/device/' + entity.id.id
  } else if (entity.type == agTypes.farmerDeviceTypes.silo.value) {
    return '/silo/' + entity.id.id
  } else if (entity.type == agTypes.farmerDeviceTypes.building.value) {
    return '/building/' + entity.id.id
  }
  return ''
}

export const COLORS = [
  "#F47521", "#07BBDA", "#2F3B41", "#FCB53B", "#4C58A6",
  "#A0B427", "#007FA5", "#D9461D"
]


/*
export type CROP_TYPE = "Oil Rape Seed" | "Oats" | "Corn" | "Wheat" | "Durum Wheat" | "Barley" | 
"Sorghum" | "Soy Bean" | "Rice" | "Rye" | "Peas Yellow" | "Grass Seeds" | "";

export const CROP_NAMES = [
"Oil Rape Seed", "Oats", "Corn", "Wheat", "Durum Wheat", "Barley", 
"Sorghum", "Soy Bean", "Rice", "Rye", "Peas Yellow", "Grass Seeds"
]
export type CROP_KEYS = "Oil Rape Seed" | "Oats" | "Corn" | "Wheat" | "Durum Wheat" | "Barley" | 
"Sorghum" | "Soy Bean" | "Rice" | "Rye" | "Peas Yellow" | "Grass Seeds";
*/

/*
export type CROP_TYPE = string

export interface ICrop { name: string, title: string, key: string, placement: number }
const CROP_DATA = {
    "barley":{
        "grain_id":14,
        "name":"Barley",
        "placement":100
    },
    "beans":{
        "grain_id":16,
        "name":"Beans - Soy",
        "placement":200
    },
    "blackbean":{
        "grain_id":22,
        "name":"Black Bean",
        "placement":300
    },
    "broadbean":{
        "grain_id":200,
        "name":"Broadbean",
        "placement":400
    },
    "canary":{
        "grain_id":23,
        "name":"Canary Seed",
        "placement":500
    },
    "durum_wheat":{
        "grain_id":7,
        "name":"Durum Wheat",
        "placement":600
    },
    "flax":{
        "grain_id":17,
        "name":"Flax Seed",
        "placement":700
    },
    "grass_seeds":{
        "grain_id":100,
        "name":"Grass Seeds",
        "placement":800
    },
    "lentils":{
        "grain_id":21,
        "name":"Lentils",
        "placement":900
    },
    "corn":{
        "grain_id":3,
        "name":"Maize",
        "placement":1000
    },
    "mustard_oriental":{
        "grain_id":26,
        "name":"Mustard Oriental",
        "placement":1100
    },
    "oats":{
        "grain_id":2,
        "name":"Oats",
        "placement":1200
    },
    "oil_rape_seed":{
        "grain_id":1,
        "name":"Oil Rape Seed",
        "placement":1300
    },
    "peanut":{
        "grain_id":20,
        "name":"Peanut",
        "placement":1400
    },
    "chick_peas":{
        "grain_id":24,
        "name":"Peas - Chickpeas",
        "placement":1500
    },
    "kidneybean":{
        "grain_id":25,
        "name":"Peas - Kidney Bean",
        "placement":1600
    },
    "peas_yellow":{
        "grain_id":31,
        "name":"Peas Yellow",
        "placement":1700
    },
    "pintobean":{
        "grain_id":28,
        "name":"Pinto Bean",
        "placement":1800
    },
    "pistachio":{
        "grain_id":29,
        "name":"Pistachio",
        "placement":1900
    },
    "rice":{
        "grain_id":18,
        "name":"Rice",
        "placement":2000
    },
    "rye":{
        "grain_id":19,
        "name":"Rye",
        "placement":2100
    },
    "sorghum":{
        "grain_id":15,
        "name":"Sorghum",
        "placement":2200
    },
    "soy_bean":{
        "grain_id":16,
        "name":"Soy Bean",
        "placement":2300
    },
    "sunflower":{
        "grain_id":11,
        "name":"Sunflower",
        "placement":2400
    },
    "sunflower_oil":{
        "grain_id":12,
        "name":"Sunflower Oil",
        "placement":2500
    },
    "wheat":{
        "grain_id":6,
        "name":"Wheat",
        "placement":2600
    },
    "yellow_mustard":{
        "grain_id":27,
        "name":"Yellow Mustard",
        "placement":2700
    }  
}
export const CROPS: Index<ICrop> = {}
for (var k in CROP_DATA) {
  let crop = CROP_DATA[k]
  CROPS[k] = {name: crop.name, title: crop.title, key: k, placement: crop.placement}
}

export let FARMER_CROPS: Index<ICrop> = {}
let p = 100
for (var k in agTypes.farmerCropNames) {
  p += 100
  FARMER_CROPS[k] = {key: k, title: getCropTranslateKey(k), name: agTypes.farmerCropNames[k], placement: p}
}*/

export function ListCrops () {
  return Object.values(CROPS).sort((a, b) => a.placement - b.placement)
}

export function CropLabel (crop_type: string) {
  if (CROPS[crop_type]) return CROPS[crop_type].title // CROPS[crop_type].name
  else if (FARMER_CROPS[crop_type]) return FARMER_CROPS[crop_type].title // getCropTranslateKey(crop_type) // FARMER_CROPS[crop_type].name
  else return TEXT.general.humidity
}

export function CropType (crop_type: string) {
  if (CROPS[crop_type]) return CROPS[crop_type].key
  else if (FARMER_CROPS[crop_type]) return FARMER_CROPS[crop_type].key
  else return null
}

export type TIME_UNIT = 'year' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second'

export interface ITimeInterval {
  minutes: number, unit: TIME_UNIT, value: number //name: string
}

export const ALL_HOURS: ITimeInterval[] = [...Array(23).keys()].map(hour => {
  return {minutes: (hour+1) * 60, unit: 'hour', value: (hour+1)}
})
export const DAY: ITimeInterval = {minutes: 60 * 24, unit: 'day', value: 1}

export interface IRangeValue {
  lower: number, upper: number
}



export function shortId (): string {
  return shortid.generate()
}

export function indexColor (index: number) {
  return COLORS[index % COLORS.length]
}

//export function equal (x, y) { return JSON.stringify(x) == JSON.stringify(y) }

export function isNumeric(val) {
  return (val - parseFloat( val ) + 1) >= 0;
}


function timeDuration (ts: number) {
  
  let ms = new Date().getTime() - ts
  let totalSeconds = ms / 1000
  var totalMinutes = totalSeconds / 60
  if (totalMinutes <= 0) return {days: 0, hours: 0, minutes: 0, total: 0}
  var hours = (totalMinutes / 60);
  var days = Math.floor(totalMinutes / 60 / 24)
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return {days: days, hours: rhours, minutes: rminutes, total: totalMinutes}
}

export function formatSinceNow (lang: string, ts: number) {
  if (!ts && typeof ts != 'number' || isNaN(ts)) return '-'
  let duration = moment.duration(moment().diff(moment(ts)));
  let d = duration
  if (d.asMilliseconds() < 0) return ''

  if (LOCALES.indexOf(lang) == -1) {
    lang = DEFAULT_LOCALE
  }

  // NOTE: using "narrow" in style can in some languages produce text like "-2 h" for 2 hours ago
  const rtf = new Intl.RelativeTimeFormat(lang, { numeric: 'auto', style: 'short' });
  if (d.years()) return rtf.format(-d.years(), 'year')
  if (d.months()) return rtf.format(-d.months(), 'month')
  if (d.weeks()) return rtf.format(-d.weeks(), 'week')
  if (d.days()) return rtf.format(-d.days(), 'day')
  if (d.hours()) return rtf.format(-d.hours(), 'hour')
  if (d.minutes()) return rtf.format(-d.minutes(), 'minute')
  return rtf.format(0, 'minute')
}

export function formatValue(value, dec, units, showZeroDecimals?) {
  if (value == null) return '-'

  if (isNumeric(value)) {
      var formatted = value
      if (dec != null) {
          formatted = Number(formatted).toFixed(dec);
      }
      if (!showZeroDecimals) {
          formatted = (formatted * 1);
      }
      formatted = formatted.toString();
      if (units != null && units.length > 0) {
          formatted += ' ' + units;
      }
      return formatted;
  } else {
      return value;
  }
}

export function guid (sep='-') {
  function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
  }
  return s4() + s4() + sep + s4() + sep + s4() + sep +
      s4() + sep + s4() + s4() + s4();
}

export function fromJson (value: string) {
    return JSON.parse(value)
}
export function toJson (value) {
    return JSON.stringify(value)
}
export function clone (value) {
    return JSON.parse(JSON.stringify(value))
}

export function getQueryParam (name: string): string {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function updateQueryParam (name: string, value: string | null) {
    const baseUrl = [window.location.protocol, '//', window.location.host, window.location.pathname].join('');
    const urlQueryString = window.location.search;
    let newParam = '';
    let params = '';
    if (value !== null) {
      newParam = name + '=' + value;
    }
    if (urlQueryString) {
      const keyRegex = new RegExp('([\?&])' + name + '[^&]*');
      if (urlQueryString.match(keyRegex) !== null) {
        if (newParam) {
          newParam = '$1' + newParam;
        }
        params = urlQueryString.replace(keyRegex, newParam);
      } else if (newParam) {
        params = urlQueryString + '&' + newParam;
      }
    } else if (newParam) {
      params = '?' + newParam;
    }
    window.history.replaceState({}, '', baseUrl + params);
}



let _COLOR_CACHE = {}
export function gradientColorValue (sensorValue: number, min: number, max: number, colors: string[]) {
  let cacheKey = sensorValue + ':' + min + ':' + max + ':' + colors.join('.')
  if (_COLOR_CACHE[cacheKey]) return _COLOR_CACHE[cacheKey]
  
  let tempLimitInterval = 4
  let tempLimitStep = (max - min) / tempLimitInterval;
  var val = Number(sensorValue);
  let telemetryLimits = []
  for (var t=min;t<=max;t+=tempLimitStep) {
    telemetryLimits.push(t);
  }

  let telemetryColors = colors
  let color
  if (val <= telemetryLimits[0]) {
      color = tinycolor(telemetryColors[0]);
  } else if (val >= telemetryLimits[telemetryLimits.length - 1]) {
      color = tinycolor(telemetryColors[telemetryColors.length - 1]);
  } else {
      var firstIndex;
      var secondIndex;
      for (var i = 0; i < telemetryLimits.length; i++) {
          if (val <= telemetryLimits[i + 1]) {
              firstIndex = i;
              secondIndex = i + 1;
              break;
          }
      }
      var firstColor = telemetryColors[firstIndex];
      var secondColor = telemetryColors[secondIndex];
      var percent = ((val - telemetryLimits[firstIndex]) /
          (telemetryLimits[secondIndex] - telemetryLimits[firstIndex])) * 100;
      color = tinycolor.mix(firstColor, secondColor, percent);
  }
  let result = color.toRgb() // setAlpha(0.75).
  _COLOR_CACHE[cacheKey] = result
  return result
}


