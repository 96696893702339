import 'leaflet/dist/leaflet.css';

import * as leaflet from 'leaflet'
import 'leaflet-providers';
import 'leaflet.markercluster'

import { Component, Directive, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { Asset, Device, Entity } from 'src/app/models/entity.model';

import { entityURL, Index, LoadingState, markerIcon, TelemetryKeyColor, TelemetryKeyLabel } from 'src/app/util';
import { EntityService } from 'src/app/services/entity.service';
import { ITelemetryValue } from 'src/app/models/telemetry.model';
import { FarmerService } from 'src/app/services/device.service';
import { LocationService } from 'src/app/services/location.service';
import { AlarmService } from 'src/app/services/alarm.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { TEXT } from 'src/app/texts';
import { TranslateService } from '@ngx-translate/core';


const DEFAULT_MARKER_ICON = '/assets/svg/spear_marker.svg'
export interface ILeafsetMarker {
  tooltip: string, type: string, icon: string,
  latitude: number, longitude: number
}
export interface ILeafletOptions {
  latitude: number | null, longitude: number | null
  markers: {[key: string]: ILeafsetMarker}
}
export interface IMarkerEvent {
  id: string, latlng: leaflet.LatLng
}
interface ICoord {
  latitude: number, longitude: number
}

@Directive({
  selector: '[devicesMap]'
})
export class DevicesMapDirective { 
  @Input('options') options: ILeafletOptions
  @Output('onMarkerMove') onMarkerMove: EventEmitter<IMarkerEvent> = new EventEmitter()
  @Output() onViewReset = new EventEmitter<void>()

  private map: leaflet.Map
  private markerGroup = leaflet.markerClusterGroup({
    maxClusterRadius: 20, 

  });
  private markers: Index<leaflet.Marker> = {}
  
  constructor (
    private location: LocationService,
    private el: ElementRef
  ) {}

  $refresh = new ReplaySubject(1)

  //private _refresh_timer
  //private _lastRefresh = -1
  //private _interval
  //private _refreshCounter = 0
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }
  
  setView (latng: leaflet.LatLng) {
    //console.error('SET VIEW')
    this.map.setView(latng, 18);
    this.invalidate()
  }

  invalidate () {
    //console.warn('invalidate map')
    this.map.invalidateSize()
    window.setTimeout(() => this.map.invalidateSize(), 1000)
  }

  addMarkers () {
    let opt = this.options
    let markers: Index<leaflet.Marker> = {}
    let haveChanges = false
    for (var k in opt.markers) {
      let mopt = opt.markers[k]
      let marker = this.markers[k]
      if (!marker) {
        marker = leaflet.marker([mopt.latitude, mopt.longitude], {
          draggable: true
        })
        this.markerGroup.addLayer(marker)
        marker.on("dragend", (evt) => {
          let e = evt as any
          if (!e.target) return
          this.onMarkerMove.emit({id: e.target['_device_id'], latlng: e.target.getLatLng()})
        });
        let [width, height] = [50, 50]
        let icon = leaflet.icon({
          iconUrl: mopt.icon, 
          iconSize: [width, height],
          iconAnchor: [width / 2, height],
          popupAnchor: [0, -height]
        });
        marker['_device_id'] = k
        marker.setIcon(icon)
        
        
        if (mopt.tooltip) {
          //console.log('MARKER TOOLTIP:', mopt.type, mopt.tooltip)
          marker.bindPopup(mopt.tooltip)
          .openPopup();
        }
        
        haveChanges = true
      }
      let pos = marker.getLatLng()
      if (pos.lat != mopt.latitude || pos.lng != mopt.longitude) {
        marker.setLatLng([mopt.latitude, mopt.longitude])
      }
      if (marker.getIcon()['iconUrl'] != mopt.icon) {
        let [width, height] = [50, 50]
        marker.setIcon(leaflet.icon({
          iconUrl: mopt.icon, 
          iconSize: [width, height],
          iconAnchor: [width / 2, height],
          popupAnchor: [0, -height]
        }));
      }
      markers[k] = marker
    }
    // TODO: only remove the markers that no longer exists
    for (var k in this.markers) {
      if (!markers[k]) {
        //console.error('remove marker')
        this.markers[k].remove()
        haveChanges = true
      }
    }
    this.markers = markers
    if (haveChanges) {
      //console.error('markers have changed')
      this.$refresh.next(1)
    }
  }
  

  async locate () { 
    let loc = await this.location.getCurrentLocation()
    if (loc?.coords) {
      let pos = new leaflet.LatLng(loc.coords.latitude, loc.coords.longitude)
      this.setView(pos)
    }
  }
  getCenter (): leaflet.LatLng {
    return this.map.getCenter()
  }

  fitView () {
    if (Object.keys(this.markers).length > 0) {
      let bounds: leaflet.LatLngBounds
      Object.values(this.markers).map(marker => {
        if (!bounds) {
          bounds = leaflet.latLngBounds(marker.getLatLng(), marker.getLatLng())
        }
        bounds.extend(marker.getLatLng())
      })
      this.map.fitBounds(bounds)
    } else if (this.options.latitude && this.options.longitude) {
      this.map.setView([this.options.latitude, this.options.longitude], 18);
    }
    this.invalidate()
  }
  
  ngOnInit () {
    //console.error('onInit')
    // NOTE: tab: false is needed on iphone
    this.map = leaflet.map(this.el.nativeElement, { "tap": false })
  }
  onViewReady () {
    //console.error('onViewReady')
    let TOKEN = "pk.eyJ1IjoibWlra2VsamFucyIsImEiOiJja3F0ZmhxMXIwNjBjMnBxdWdqNnN2cjFlIn0.9vcUa3KYjdR3J7CXNr-l0Q"
    var satelliteLayer = leaflet.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
      tileSize: 512,
      maxZoom: 18,
      zoomOffset: -1,
      id: 'mapbox/satellite-streets-v11',
      accessToken: TOKEN
    })
    satelliteLayer.addTo(this.map);
    this.markerGroup.addTo(this.map)
    satelliteLayer.on('tileerror', e => {
      console.error('TILE ERROR', e, e.error.message, e.error.name, e.error.stack)
      // TODO: the map sometimes freezes with tile error, this fixes it for some reason
      setTimeout(() => this.fitView(), 1000)
    })

    this.listen(this.$refresh.pipe(debounceTime(100)).subscribe(x => {
      if (this.map && this.options) {
        this.fitView()
        
      }
    }))
    this.refresh()
    //console.log('on view ready')
  }
  refresh () { this.$refresh.next(1) }
  
  ngOnChanges (changes) {
    //console.log('MAP CHANGED', changes)
    if (this.options)
      this.addMarkers()
  }

  hasMarkers () { return Object.values(this.markers).length > 0 }
 
}



@Component({
  selector: 'devices-map',
  template: `<div style="height: 100%; width: 100%">
    <div style="width: 100%; height: 100%; position: relative;">
      <!--[message]="location.userErrorMessage()" (retry)="getLocation()"-->
      <!--<ag-loading [loading]="loading" [inline]="true" [showError]="false" message="" ></ag-loading>-->
      
      <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);">
        <div *ngIf="permission == 'prompt'">
          <button class="small-button" (click)="locate()">{{text.general.activate_location|translate}}</button>
        </div>
        <permission-error *ngIf="permission == 'denied'" 
          type="GPS"
          message="{{text.permission.location_denied|translate}}" (onRetry)="locate()" ></permission-error>
      </div>
      <span [hidden]="!canShow">
        <div style="width: 100%; height: 100%;" devicesMap (onMarkerMove)="onMarkerMove($event)" [options]="options"></div>      
        <ion-icon class="map-button" style="top: 10px; right: 10px;" (click)="fitView()" name="expand-outline"></ion-icon>
        <ion-icon class="map-button" style="right: 10px; bottom: 10px;" (click)="locate()" name="locate"></ion-icon>
        <div *ngIf="canPlaceMarkers" style="position: absolute; z-index: 99999" [class.center]='!isMarkerPlaced'>
          <ion-icon class="map-button" 
            [class.centered]="!isMarkerPlaced"
            style="left: 10px; bottom: 10px;" (click)="placeMarker()" name="location"></ion-icon>
          <div class="button-text" *ngIf="!isMarkerPlaced">{{text.general.place_on_map|translate}}</div>
        </div>
      </span>
    </div>
  </div>`,
  styles: [`
.map-button {
  position: absolute; z-index: 9999;
  background: white;
  border-radius: 50px;
  width: 25px; height: 25px;
  padding: 5px;
}
.center {
  left: 50% !important;
  top: 50% !important;
}
.centered {
  transform: translate(-50%, -50%);
  left: 0 !important; top: 0 !important;
}
.button-text {
  background: white;
  padding: 5px;
  font-weight: bold;
  transform: translate(-50%, 20px);
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 12px;
}
  `]
})
export class DevicesMapComponent {
  @Input() devices: Entity<any>[] = []
  @Input() canPlaceMarkers: boolean = true
  @Output() onReady: EventEmitter<this> = new EventEmitter()
  @Output() onPlace: EventEmitter<leaflet.LatLng> = new EventEmitter()
  @Output() onMove: EventEmitter<IMarkerEvent> = new EventEmitter()
  @ViewChild(DevicesMapDirective) map:DevicesMapDirective;

  @Input() options: ILeafletOptions = {
    markers: {}, latitude: null, longitude: null
  }
  isLoading = false
  loading = new LoadingState()

  isLoaded = false
  isFailed = false
  isNative = Capacitor.isNativePlatform()
  text = TEXT

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  //isMarkerPlaced = false

  constructor (
    private farmer: FarmerService,
    private alarmService: AlarmService,
    public location: LocationService,
    private ngZone: NgZone,
    private translate: TranslateService
  ) {}

  //resetPermissions () { this.location.reset() }
  //hasLocation () { return this.options.latitude != null && this.options.longitude != null }
  //async getLocation () { await this.location.getCurrentLocation() }
  
  onMarkerMove (event: IMarkerEvent) {
    let device = this.devices.find(d => d.id.id == event.id)
    if (device)
      this.saveLocation(device, event.latlng)
    this.onMove.emit(event)
  }
  fitView () { this.map.fitView() }


  setLocation (loc: {latitude: number, longitude: number}) {
    //console.warn('MAP: setLocation', loc)
    let pos = new leaflet.LatLng(loc.latitude, loc.longitude)
    this.map.setView(pos)
  }

  async locate () { 
    //console.warn('MAP: locate')
    if (this.latestCoords) this.setLocation(this.latestCoords)
    let loc = await this.location.getCurrentLocation()
    //console.log('locate:', loc, this.latestCoords)
    if (loc && loc?.coords) this.setLocation(loc.coords)
    else if (this.latestCoords) this.setLocation(this.latestCoords)
  }

  async placeMarker () {
    let device = this.devices[0]
    let latlng = this.map.getCenter()
    if (device) {
      
      this.saveLocation(device, latlng)
    }
    this.onPlace.emit(latlng)
  }

  saveLocation (device: Device, latlng: leaflet.LatLng) {
    // TODO: save should be handled by caller
    //console.log('SAVE LOCATION', device, this.farmer.getEntityModel(device))
    this.farmer.getEntityModel(device).saveSettings({
      latitude: latlng.lat, longitude: latlng.lng
    })
  }

  createTooltip (device: Entity<any>, values: ITelemetryValue<number>[]) {
    function round (x: number) {
      if (!x) return '-'
      return Math.round(x * 100) / 100
    }
    let items = values.map(value => {
      //console.error('LABEL', TelemetryKeyLabel(value.key))
      let label = TelemetryKeyLabel(value.key).map(x => this.translate.instant(x)).join(' ')
      return `<b>${label}</b>
      <span style='color:${TelemetryKeyColor(value.key)};font-weight:600'> ${round(value.value)}</span>`
    })
    let tooltip = `
        <b><a href="${entityURL(device)}">${device.label}</a></b><br/>
        ${items.join('<br />')}
    `
    
    return tooltip
  }

  ngOnInit () {
    this.listen(this.loading.events.subscribe(x => {
      this.isFailed = this.loading.is_failed()
      this.isLoaded = this.loading.is_success()
    }))
    this.loading.loading(true)
    
    this.listen(this.alarmService.subscribe(alarms => {
      this.createDeviceMarkers()
    }))
    
    this.devices.map(device => {
      let model = this.farmer.getEntityModel(device)
      if (!model) return
      model.load()
      this.listen(model.subscribe(() => {
        this.createDeviceMarkers()
      }))
    })
    
  }

  ngOnChanges (changes) {
    console.log('device map changed', changes)
    //this.refresh()
    //console.log('MAP COMPONENT CHANGED', changes)
  }

  createDeviceMarkers () {
    //console.log('create device markers', this.devices)
    this.devices.map(entity => {
      let device = this.farmer.getEntityModel(entity)
      if (!device) return
      let marker: ILeafsetMarker = {
        latitude: parseFloat(device.settings.latitude as any), 
        longitude: parseFloat(device.settings.longitude as any), 
        tooltip: '', type: device.entity.type, icon: DEFAULT_MARKER_ICON}
      delete this.options[device.entity.id.id]
      if (marker.latitude && marker.longitude) {
        let hasAlarm = this.alarmService.listAlarms().find(x => x.originator.id == entity.id.id) != undefined
        let icon = markerIcon(device.entity.type, hasAlarm)
        let values = device.getValues()
        let leafletMarker: ILeafsetMarker = {
          type: device.entity.type, icon: icon,
          tooltip: this.createTooltip(device.entity, values),
          latitude: marker.latitude, longitude: marker.longitude
        }
        this.options.markers[device.entity.id.id] = leafletMarker
      }
      this.options = Object.assign({}, this.options)
    })
    //console.log('UPDATED MARKERS', Object.keys(this.options.markers).length, this.options)
    this.refresh()
  }

  hasInitialized = false
  permission: string | null = null
  canShow = false
  canPlaceMarker = false
  error: string | null = null
  latestCoords: ICoord
  isMarkerPlaced: boolean = false
  ngAfterContentInit () {
    this.listen(this.location.onLocation.subscribe(loc => {
      if (loc.coords) {
        this.latestCoords = loc.coords
      }
      if (loc.error) {
        this.error = this.location.userErrorMessage()
      }
      this.refresh()
    }, err => {
      this.loading.error(this.location.userErrorMessage())
    }))
    this.checkPermissions()
  }

  async checkPermissions () {
    this.permission = await this.location.checkPermissions()
    this.ngZone.run(() => {
      this.refresh()
    })
  }

  async refresh () {
    // NOTE: do not check for permissions here, it can hang the function for a very long time on some systems
    let hasMarker = Object.keys(this.options.markers).length > 0
    this.isMarkerPlaced = hasMarker
    let hasLocation = (this.latestCoords != undefined || this.permission == 'granted') || hasMarker
    this.canShow = hasLocation
    
    //console.log('MAP:', this.canShow, this.hasInitialized, this.isMarkerPlaced, hasLocation)
    if (!this.loading.is_success() && this.canShow) {
      this.loading.success()
    }

    if (this.map && this.canShow && !this.hasInitialized) { 
      //console.warn('INITIALIZE MAP', {...this.options})
      this.map.onViewReady()
      this.hasInitialized = true
      if (hasMarker) {
        this.fitView()
      } else {
        this.locate()
      }
    }
    //console.log('map refreshed', this.permission, this.canShow)
  }
}

@Component({
  template: `
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button defaultHref="/"></ion-back-button>
      </ion-buttons>
      <ion-title [translate]="text.menu.map_view"></ion-title>
      
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <devices-map *ngIf="loaded" [canPlaceMarkers]="false" [devices]="devices"></devices-map>
  </ion-content>
  `
})
export class DevicesMapPage {
  devices: (Device|Asset)[]
  loaded = false
  text = TEXT
  constructor ( 
    private entityService: EntityService
  ) {}

  ngOnInit () {
    this.load()
  }
  async load () {
    let assets = await this.entityService.getCustomerAssets()
    let devices = (await this.entityService.getDevicesByType()).data
    this.devices = [...assets, ...devices]
    this.loaded = true
  }
}