import { types } from '../../types'
import { agTypes } from '../../ag-types'
import { AgModal, clone, CropLabel, LoadingState, makeMarkerURL, MOISTURE_ICON, removeNullValues, TelemetryKeyColor } from '../../util'
import { Component, Input, ViewChild } from '@angular/core';
import { AGUserService } from 'src/app/services/user.services';
import { ITelemetryData, TelemetryService, TimeseriesSubsciption } from 'src/app/services/telemetry';
import { Device, EntityId, EntityType } from 'src/app/models/entity.model';
import { AggregationType } from 'src/app/models/telemetry.model';
import { FarmerService } from 'src/app/services/device.service';
import { AlertController, ModalController } from '@ionic/angular';
import { DevicesMapDirective, ILeafletOptions, ILeafsetMarker, IMarkerEvent } from '../device-map/devices-map';
import { HttpClient } from '@angular/common/http';

import { Camera, CameraResultType } from '@capacitor/camera';
import * as leaflet from 'leaflet'
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment-es6';
import { AttributeService } from 'src/app/services/entity.service';
import { LocationService } from 'src/app/services/location.service';
import { CPRO_KEYS, IDeviceMessurement, LoadMeasurementTelemetry, SAMoistureMeasurement, uuidv4 } from './super-pro.model';

import { Subscription } from 'rxjs'

import { format, formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { parseISO } from 'date-fns'
import { TEXT } from 'src/app/texts';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LOCALE, FARMER_CROPS } from 'src/app/constant';


interface Index<T> { [key: string]: T}

// TODO: .. don't know how to handle type for non-entity for map
const MEASUREMENT_TYPE = 'measurement'
const MARKER_ICON = makeMarkerURL(MOISTURE_ICON)

export function SubscribeMeasurements (
  http: HttpClient,
  telemetry: TelemetryService, deviceId: EntityId<EntityType.DEVICE>, 
  onData: (x: IDeviceMessurement[]) => boolean, onLoading: (isLoading: boolean) => void) {
    //console.log('subscribe measurements', CPRO_KEYS)
    // NOTE: subscription does sometimes not work, always fetch everything manually to begin with
    
    let state: {measurements: IDeviceMessurement[]} = {measurements: []}
    function emitData (measurements: IDeviceMessurement[]) {

      let allMeasurements = state.measurements.concat(measurements)
      let uniqMeasurements: Record<any, IDeviceMessurement> = {}
      allMeasurements.map(x => {
        uniqMeasurements[x.ts] = x
      })
      let finalMeasurements = Object.values(uniqMeasurements)
      finalMeasurements.sort((a, b) => b.ts - a.ts)
      state.measurements = finalMeasurements
      onData(finalMeasurements)
    }

    
    FetchMeasurements(http, deviceId).then(measurements => {
      emitData(measurements)
    })
    return telemetry.subscribe(deviceId, {
      agg: types.aggregation.none.value as AggregationType,
      timeWindow: (1000 * 60 * 60 * 24 * 365 * 10),
      keys: CPRO_KEYS, interval: 0, limit: 100
    }, {
    onData: (data) => {
      let items = LoadMeasurementTelemetry(data)
      emitData(items)
      return true
    },
    onLoading: (isLoading) => onLoading(isLoading)
  }, {})
}

export async function FetchMeasurements (http: HttpClient, deviceId: EntityId<EntityType.DEVICE>) {
  let keys = CPRO_KEYS
  //keys=['temperature']
  let aYearAgo = Math.round(new Date().getTime() - (1000 * 60 * 60 * 24 * 365))
  let nextDay = Math.round(new Date().getTime() + (1000 * 60 * 60 * 24))
  let url = `/api/plugins/telemetry/${deviceId.entityType}/${deviceId.id}/values/timeseries?keys=${keys.join(',')}&startTime=${aYearAgo}&endTime=${nextDay}`;
  // &limit=1000&agg=NONE&interval=1
  let data: any = await http.get(url).toPromise()
  //console.log(url)
  //console.log('fetched measurements:', data)
  let tel: ITelemetryData = {
    data: {}
  }
  for (var k in data) {
    tel.data[k] = []
    for (var value of data[k]) {
      tel.data[k].push([value.ts, value.value])
    }
  }
  
  return LoadMeasurementTelemetry(tel)
}


@Component({
  selector: 'measurement-map',
  template: `
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button defaultHref="/"></ion-back-button>
      </ion-buttons>
      <ion-title [translate]="text.general.measurement_map"></ion-title>
      
    </ion-toolbar>
  </ion-header>
  <ion-content>
  <ag-loading [loading]="loading" message="{{text.general.loading|translate}}"></ag-loading>
  <div style="width: 100%; height: 100%; position: relative;">
    <!--<devices-map [canPlaceMarkers]="false" [devices]="devices"></devices-map>-->
    <div style="width: 100%; height: 100%;" devicesMap (onMarkerMove)="onMarkerMove()" [options]="options"></div>
    <ion-icon class="map-button" style="top: 10px; right: 10px;" (click)="fitView()" name="expand-outline"></ion-icon>
    <ion-icon class="map-button" style="right: 10px; bottom: 10px;" (click)="locate()" name="locate"></ion-icon>
  </div>
</ion-content>`,
styles: [`
.map-button {
    position: absolute; z-index: 9999;
    background: white;
    border-radius: 50px;
    width: 25px; height: 25px;
    padding: 5px;
  }
`]
})
export class MeasurementMapComponent {
  @Input() entityId: string

  @ViewChild(DevicesMapDirective) map:DevicesMapDirective;

  loading = new LoadingState()
  subscription
  options: ILeafletOptions
  text = TEXT

  constructor (
    private http: HttpClient,
    private telemetry: TelemetryService, 
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {}

  load_measurements (measurements: IDeviceMessurement[]) {
    console.warn('got measurements:', measurements)
    if (measurements.length == 0) {
      this.locate()
    }
    let markers: Index<ILeafsetMarker> = {}
    measurements.map(m => {
      if (!m.latitude || !m.longitude) return
      let temperatureHtml = ''

      let temperatureText = this.translate.instant(this.text.general.temperature)
      let cropText = this.translate.instant(this.text.general.crop)
      let moistureText = this.translate.instant(this.text.general.moisture)

      if (m.temperatureContent != null) {
        temperatureHtml = `
        <b>${temperatureText}:</b>
        <span style='color:${TelemetryKeyColor('temperature-')};font-weight:600'> ${m.temperatureContent.toFixed(2)}%</span>
        <br/>
        `
      }

      let cropLabel = this.translate.instant(CropLabel(m.cropType))
      let tooltip = `
        <b>${moment(m.ts).format("MMM DD YYYY - hh:mm")}</b>
        <br/>
        <b>${cropText}:</b>
        <span style='font-weight:600'> ${cropLabel}</span>
        <br/>
        <b>${moistureText}:</b>
        <span style='color:#3498db;font-weight:600'> ${m.moistureContent.toFixed(2)}%</span>
        <br/>
        ${temperatureHtml}
        <span>${m.comment}</span>
      `
      
      let marker: ILeafsetMarker = {
        latitude: m.latitude, longitude: m.longitude,
        tooltip: tooltip, type: MEASUREMENT_TYPE, icon: MARKER_ICON
      }
      markers[m.ts] = marker
    })
    this.options = {markers: markers, latitude: null, longitude: null}
    
  }

  onMarkerMove () {

  }
  locate () {
    console.warn('locate')
    this.map.locate()
  }
  fitView () { this.map.fitView() }
  placeMarker () {}

  ngOnDestroy () {
    if (this.subscription) this.subscription.unsubscribe()
  }

  resubscribe () {
    if (this.subscription) this.subscription.unsubscribe()
    let id: EntityId<EntityType.DEVICE> = {
      id: this.entityId, entityType: EntityType.DEVICE
    }
    this.subscription = SubscribeMeasurements(this.http, this.telemetry, id, (measurements) => {
      this.load_measurements(measurements)
      return true
    }, (isLoading) => this.onLoadingChange(isLoading))
  }
  onLoadingChange (isLoading) {
    this.loading.loading(isLoading)
  }
  
  ngAfterViewInit() {
    this.map.onViewReady()
    this.locate()
  }

  ngOnInit () {
    
    this.route.params.subscribe(params => {
      this.entityId = params['entityId'];
    });
    this.resubscribe()
  }
}


export interface MeasurementDeviceSettings {
  label: string
}

@Component({
  selector: 'superpro-settings',
  template: `
<ion-header>
  <ion-toolbar>
    <ion-title>{{settings.label}} {{text.general.settings|translate}}</ion-title>
    <ion-buttons slot="primary">
      <ion-button (click)="dismiss()" >
          <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="dialog-content">
    <!--<ion-list>
        <ion-item>
            <ion-label>Serial: {{entity.name}}</ion-label>
        </ion-item>
    </ion-list>-->
    <ion-item>
      <ion-label position="stacked" [translate]="text.general.device_name"></ion-label>
      <ion-input [(ngModel)]="settings.label" placeholder="{{text.general.label|translate}}"></ion-input>
    </ion-item>

    <div style="margin-top: 50px;">
      <ion-button color="danger" (click)="unclaimDevice()" [translate]="text.device.remove_device"></ion-button>
    </div>
  </div>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed" (click)="saveSettings()">
    <ion-fab-button color="primary">
        <ion-icon name="checkmark-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
  `,
  styleUrls: ['super-pro.scss']
})
export class SuperProSettingsComponent {
  @Input() settings: MeasurementDeviceSettings
  @Input() entity: Device

  constructor (
    private farmer: FarmerService, 
    private dialogRef: ModalController,
    public alertController: AlertController, 
    private route: Router,
    private translate: TranslateService
  ) {}

  text = TEXT

  dismiss (data?) { this.dialogRef.dismiss(data) }

  async unclaimDevice () {
    let unclaimPrompt = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant(this.text.settings.confirm_remove_device_title),
      message: '<strong>' + this.translate.instant(this.text.settings.confirm_remove_device) + '</strong>',
      buttons: [
        {
          text: this.translate.instant(this.text.general.no), // 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: this.translate.instant(this.text.general.yes), // 'Yes',
          handler: async () => {
            await this.farmer.unclaimDevice(this.entity)
            this.dismiss({redirect: '/'})
          }
        }
      ]
    });

    await unclaimPrompt.present();
  }

  async saveSettings () {
    await this.farmer.getMeasurementDevice(this.entity).saveSettings(this.settings)
    this.dialogRef.dismiss()
  }

  ngOnInit () {
  }
}

@Component({
  selector: 'superpro-overview',
  templateUrl: 'super-pro-measurements.html',
  styleUrls: ['super-pro.scss']
})
export class SuperProOverviewComponent { 

  @Input() device: Device

  text = TEXT
  title: string
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { 
    this.subscription?.unsubscribe()
    this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] 
  }
  
  constructor (
    public modalController: ModalController, private route: Router,
    public farmer: FarmerService, private agModal: AgModal,
    private http: HttpClient,
    private userService: AGUserService, private telemetry: TelemetryService) {}

  colors = agTypes.colors
  loading = new LoadingState()
  measurements: IDeviceMessurement[] = [];
  measurementsSubscriptionOptions
  cropNames
  latestData
  subscription: TimeseriesSubsciption

  deviceHasCrop () {
    return this.device.type != agTypes.farmerDeviceTypes.superproCombi.value
  }

  showMeasurementMap () {
    this.route.navigateByUrl('/device/' + this.device.id.id + '/map')
  }
  async showSettings () {
    let settings = clone(this.farmer.getMeasurementDevice(this.device).settings)
    
    const dialogRef = await this.modalController.create({
      component: SuperProSettingsComponent,
      componentProps: {settings: settings, entity: this.device}
    });
    const { data } = await this.agModal.openModal(dialogRef)
  }
  async resubscribe () {
    if (this.subscription) this.subscription.unsubscribe()
    this.loading.loading(true)
    
    this.subscription = SubscribeMeasurements(this.http, this.telemetry, this.device.id, (measurements) => {
      console.log('new measurements', measurements)
      this.measurements = measurements
      this.hasContent = measurements.length > 0
      this.loading.success()
      return true
    }, (isLoading) => { this.loading.loading(isLoading) })
  }
  isLoaded = false
  hasContent = false
  ngOnInit () {
    this.cropNames = [];
    for (let property in agTypes.farmerCropNames) {
        this.cropNames.push({
            nameId: property,
            value: agTypes.farmerCropNames[property]
        });
    }
    this.loading.events.subscribe(x => {
      this.isLoaded = x.is_success()
    })
    let ctrl = this.farmer.getMeasurementDevice(this.device)
    ctrl.load()
    this.listen(
      ctrl.onUpdate.subscribe(() => {
        this.title = ctrl.settings.label
      })
    )
    this.resubscribe()

    
  }

  async openAddMeasurementDialog (measurement?: IDeviceMessurement) {
    const dialogRef = await this.modalController.create({
      component: AddMeasurementDialogComponent,
      componentProps: {entity: this.device, measurement: measurement}
    });
    // NOTE: for some reason, the measurement subscribtions will not always react to changes
    //       need to manually update the measurement value:
    const { data } = await this.agModal.openModal(dialogRef)
    if (data) {
      this.measurements = this.measurements.map(m => {
        if (m.ts == data.ts) return data
        return m
      })
      this.resubscribe()
    }
  }

  isCustomer () {
    return true
  }
  getCropName (cropNameId) {
    if (this.cropNames && this.cropNames.length) {
      for (let i = 0; i < this.cropNames.length; i++) {
        if (this.cropNames[i].nameId === cropNameId) {
          return this.cropNames[i].value;
        }
      }
    }
    return "-";
  }
}


function utcDate () {
  let date = new Date()
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

function utcTimestamp () {
  let date = new Date()
  let ts = new Date(date.getTime() + date.getTimezoneOffset() * 60000).getTime() / 1000;
  return Math.round(ts)
}

const IMAGE_KEY = 'image'

@Component({
  selector: 'add-measure-dialog',
  template: `

<ion-header>
    <ion-toolbar>
    <ion-title [translate]="text.general.edit_measurement"></ion-title>
    <ion-buttons slot="primary">
        <ion-button (click)="close()" >
            <ion-icon name="close-outline"></ion-icon>
        </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class='measurement-dialog' style="">
  <div class="background" (click)="dialogRef.dismiss()" ></div>
  <div class="dialog-card" style="margin-bottom: 100px;">
  <ion-item *ngIf="hasCrop">
    <ion-label>{{text.general.crop_type|translate}}:</ion-label>
    <!--ok-text="Okay" cancel-text="Cancel"-->
    <ion-select [(ngModel)]="editMeasurement.cropType" interface="popover" >
      <ion-select-option value="">{{text.general.no_crop|translate}}</ion-select-option>
      <ion-select-option *ngFor="let crop of crops" [value]="crop.key">{{crop.title|translate}}</ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item>
    <ion-label>{{text.general.moisture|translate}}:</ion-label>
    <ion-input type="number" [(ngModel)]="editMeasurement.moistureContent"></ion-input>
  </ion-item>
  <ion-item *ngIf="canMeasureTemperature">
    <ion-label>{{text.general.temperature|translate}}:</ion-label>
    <ion-input type="number" [(ngModel)]="editMeasurement.temperatureContent"></ion-input>
  </ion-item>
  <div style="height: 300px; padding: 10px; position: relative">
    <!--(onMarkerMove)="onMarkerMove($event)" -->
    <devices-map [options]="options" 
    (onMove)="onMarkerMove($event)" (onPlace)="onMarkerPlaced($event)"></devices-map>
  </div>
    <!--<div style="height: 300px; padding: 10px; position: relative">
    <div style="height: 100%; width: 100%">
      <div style="width: 100%; height: 100%; position: relative;">
        <div style="width: 100%; height: 100%;" devicesMap (onMarkerMove)="onMarkerMove($event)" [options]="options"></div>
        <ion-icon class="map-button" style="right: 10px; bottom: 10px;" (click)="locate()" name="locate"></ion-icon>
        <ion-icon class="map-button" style="left: 10px; bottom: 10px;" (click)="placeMarker()" name="location"></ion-icon>
      </div>
    </div>
  </div>-->
  <ion-item *ngIf="!editMode">
    <ion-label>{{text.general.time|translate}}:</ion-label>
    <!--<div class="item item-icon-left" ion-datetime-picker ng-model="measurementDate">
        <i class="icon ion-ios-grid-view-outline positive"></i>
        Basic datetime picker:
        <strong>{{measurementDate| date: "yyyy-MM-dd H:mm"}}</strong>
    </div>-->
    <!-- display-format="DD/MMMM/YYYY hh:mm" picker-format="DD/MMMM/YYYY hh:mm" -->
    <!--<ion-datetime [disabled]="editMode" (ionChange)="setDate($event)"  
      [value]="measurementDate" [max]="now"></ion-datetime>-->
    <ion-datetime-button datetime="datetime"></ion-datetime-button>

    <ion-modal [keepContentsMounted]="true">
      <ng-template>
        <ion-datetime 
          [locale]="locale"
          show-default-buttons="true" 
          id="datetime"   
          (ionChange)="setDate($event)"
          [(ngModel)]="measurementDate"
          [max]="now" ></ion-datetime>
           <!--*ngIf="measurementDate" [max]="now"-->
          <!--(ionChange)="setDate($event)" [value]="measurementDate | date" -->
      </ng-template>
    </ion-modal>
  </ion-item>
  <div>
  <div>
    <ion-textarea [(ngModel)]="editMeasurement.comment" placeholder="{{text.general.comment|translate}}"></ion-textarea>
  </div>
  <div>
    <ion-button (click)="takePhoto()" [translate]="text.general.take_photo"></ion-button>
  </div>
  <div *ngIf="photo" >
    <img [src]="photo" />
  </div>
  
  </div>
</div>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed" (click)="addMeasurement()">
    <ion-fab-button [disabled]="!validate()" color="primary">
        <ion-icon name="checkmark-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
  `,
  styleUrls: ['super-pro.scss'],
  styles: [`

.background {
  filter: blur(3px);
  position: absolute;
  z-index: -1;
}

.dialog-card {
  margin-bottom: 10px;
  border-radius: 7px;
  margin: 5px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
}
.measurement-dialog {
}
.map-button {
  position: absolute; z-index: 9999;
  background: white;
  border-radius: 50px;
  width: 25px; height: 25px;
  padding: 5px;
}
.asset-comment-input {
  width: 100%;
  padding: 9px;
  resize: none;
  border-color: #d5dce0;
  border-radius: 2px;
  outline: none;
}
    `]
})
export class AddMeasurementDialogComponent {
  @Input() entity: Device
  @Input() measurement: IDeviceMessurement
  //@ViewChild(DevicesMapDirective) map:DevicesMapDirective;

  constructor (
    public dialogRef: ModalController,  // TODO: make private
    private http: HttpClient,
    private attributeService: AttributeService, 
    private geo: LocationService,
    private translate: TranslateService
  ) {}

  text = TEXT
  crops = Object.values(FARMER_CROPS)
  editMeasurement: IDeviceMessurement
  options: ILeafletOptions
  editMode = false
  loading = true
  canMeasureTemperature = false
  hasCrop = false
  measurementDate
  now = new Date().toISOString()
  
  locale = DEFAULT_LOCALE
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  updateLocale () {
    this.locale = this.translate.currentLang
  }

  close () { this.dialogRef.dismiss() }

  photo = ''
  origPhoto = ''
  async takePhoto () {
    const image = await Camera.getPhoto({
      quality: 90, height: 400,
      allowEditing: false,
      resultType: CameraResultType.DataUrl
    });
    this.photo = image.dataUrl
  }

  

  loadEditPhoto () {
    this.attributeService.getEntityTimeseries(
      this.entity.id, [IMAGE_KEY], this.editMeasurement.ts - 1, this.editMeasurement.ts + 1, 1
      ).subscribe((data) => {
        //console.log('photo loaded', this.editMeasurement.ts, data)
        if (data[IMAGE_KEY]) {
          this.photo = data[IMAGE_KEY][0].value;
          this.origPhoto = data[IMAGE_KEY][0].value;
        }
      })
  }

  validate () {
    let m = this.editMeasurement
    let base = m && typeof m.moistureContent == 'number' 
           //&& typeof m.latitude == 'number' && typeof m.longitude == 'number'
    return base && (!this.canMeasureTemperature || typeof m.temperatureContent == 'number')
  }

  onMarkerMove (event: IMarkerEvent) {
    this.set_latlng(event.latlng)
  }
  onMarkerPlaced (latlng: leaflet.LatLng) {
    this.set_latlng(latlng)
  }

  set_latlng (latlng) {
    Object.assign(this.editMeasurement, {
      latitude: latlng.lat, longitude: latlng.lng
    })
    this.refresh_markers(this.editMeasurement)
  }

  /*
  locate () { this.map.locate() }

  placeMarker () {
    //this.locate()
    let latlng = this.map.getCenter()
    this.options = {
      latitude: latlng.lat, longitude: latlng.lng,
      markers: {default: {icon: MARKER_ICON,
        longitude: latlng.lng, latitude: latlng.lat, tooltip: '', type: MEASUREMENT_TYPE}}
    }
    this.set_latlng(latlng)
  }*/
  
  setDate (evt) {
    let ts = new Date(this.measurementDate).getTime()
    this.editMeasurement.ts = ts
    //console.log(' DATE CHANGE:', this.measurementDate, ts)
    return
    let date = new Date(evt.target.value);
    let myDate: String = new Date(date.getTime()).toISOString();
    //let myDate = new Date(date.getTime() - date.getTimezoneOffset()*60000).toISOString();
      console.log('SET DATE', myDate, evt.target.value)
    this.measurementDate = date // myDate // new Date(evt.target.value).toISOString()
  }

  ngOnInit () {
    this.listen(this.translate.onLangChange.subscribe(x => this.updateLocale()))
    this.updateLocale()

    let date = new Date();
    let myDate = new Date(date.getTime() - date.getTimezoneOffset()*60000).toISOString();
    this.now = myDate
    this.measurementDate = myDate // date.toISOString() // myDate // new Date().toISOString()
    this.canMeasureTemperature = this.entity.type == agTypes.farmerDeviceTypes.superproCombi.value
    this.hasCrop = this.entity.type != agTypes.farmerDeviceTypes.superproCombi.value
    
    this.photo = ''
    this.origPhoto = ''
    if (this.measurement) {
      this.editMode = true
      this.editMeasurement = Object.assign({}, this.measurement)
      this.loadEditPhoto()
    } else {
      this.editMeasurement = {
        comment: '', cropType: '', moistureContent: undefined, ts: new Date().getTime(),
        latitude: undefined, longitude: undefined, temperatureContent: undefined
      }
      
    }
    //console.log('get measurement date', this.editMeasurement.ts)
    //this.measurementDate = this.toDate(this.editMeasurement.ts)
    //console.log('measurement date', this.measurementDate)
    let options: ILeafletOptions = {
      latitude: this.editMeasurement.latitude || null,
      longitude: this.editMeasurement.longitude || null,
      markers: {}
    }
    this.options = options
    this.refresh_markers(options)
  }

  refresh_markers (latlng: {latitude: number, longitude: number}) {
    console.log('refresh markers', {...latlng}, {...this.options})
    let options = {...this.options}
    if (latlng.latitude && latlng.longitude) {
      options.markers = {default: {icon: MARKER_ICON,
        longitude: latlng.longitude, latitude: latlng.latitude, tooltip: '', type: MEASUREMENT_TYPE}
      }
    } else {
      let latlng = this.geo.current.coords // this.map.getCenter()
      if (latlng) {
        options.markers = {
          default: {icon: MARKER_ICON,
            longitude: latlng.longitude, latitude: latlng.latitude, tooltip: '', type: MEASUREMENT_TYPE
          }
        }
        Object.assign(this.editMeasurement, {
          latitude: latlng.latitude, longitude: latlng.longitude
        })
      }
    }
    Object.assign(this.options, options)
    this.options = options
  }

  ngAfterViewInit() {
    this.loading = false
  }

  async addMeasurement () {
    await this.save()
    this.dialogRef.dismiss(this.editMeasurement)
  }

  async save () {
    if (!this.validate()) throw new Error('invalid')
    
    let ts = this.editMeasurement.ts
    if (!ts) ts = new Date().getTime()
    let postMeasurement = Object.assign({}, this.editMeasurement)
    delete postMeasurement.ts

    let timestamp = utcTimestamp()
    let saMeasurement: SAMoistureMeasurement = {
      comment: postMeasurement.comment,
      description: 'App Created Measurement',
      latitude: postMeasurement.latitude,
      longitude: postMeasurement.longitude,
      measurement_type: 'app_moisture',
      measurement_uuid: uuidv4(),
      time_of_transfer: timestamp,
      timestamp: timestamp,
      moisture_content: postMeasurement.moistureContent,
      temperature: postMeasurement.temperatureContent,
      calibration_offset: 0,
      crop_name: postMeasurement.cropType
    }
    let values = removeNullValues(saMeasurement)
    
    let data = {ts: ts, values: values}
    let url = '/api/plugins/telemetry/' + this.entity.id.entityType + '/' + this.entity.id.id + '/timeseries/scope';
    await this.http.post(url, data).toPromise()
    //console.log('save photo?', this.photo && this.photo != this.origPhoto, this.origPhoto)
    if (this.photo && this.photo != this.origPhoto) {
      await this.http.post(url, {
        ts: ts, values: {image: this.photo}
      }).toPromise()
      this.origPhoto = this.photo
    }
  }

}
