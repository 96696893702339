import { Injectable } from "@angular/core";
import { AttributeService } from "./entity.service";
import { AGUserService } from "./user.services";
import { SwPush } from '@angular/service-worker';
import { EntityId, EntityType } from '../models/entity.model';
import { AttributeScope } from '../models/telemetry.model';


import { getMessaging, getToken, onMessage, isSupported, Unsubscribe } from "firebase/messaging";
import { AuthService } from "./auth.service";
import { map, switchMap } from "rxjs/operators";

import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';
import { onAuthenticationChange } from "../util";
import { Store } from "@ngrx/store";
import { AppState } from "../state";
import { captureException, captureMessage } from "@sentry/angular";


import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from "@capacitor/push-notifications";
import { LocalNotifications } from "@capacitor/local-notifications"
//import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';

import { BehaviorSubject } from 'rxjs'

const KEY = 'registrationTokens'

//import { FCM } from '@awesome-cordova-plugins/fcm/ngx';
import { Capacitor } from "@capacitor/core";
import { PermissionService } from "../menus";

let FCM_KEY = "BE_OuriOia6hBGw8W2CiipkooA1OWfBu63KX0-Ns0ohALy5JFlxzRJIAKz3YWI6WsiKEM8wtuoRftoTCHKPJ7XE"

interface IMessaingServiceState {
  hasPermission: boolean
}

@Injectable()
export class MessagingService {
  token: string | null = null
  channel = new BroadcastChannel('notifyChannel');
  isLeader = false
  elector = createLeaderElection(this.channel, {
    fallbackInterval: 2000, // optional configuration for how often will renegotiation for leader occur
    responseTime: 1000, // optional configuration for how long will instances have to respond
  });
  _subscriber: Unsubscribe
  //state = new BehaviorSubject<IMessaingServiceState>({hasPermission: false})

  constructor (
    //private swPush: SwPush,
    //private userService: AGUserService,
    private authService: AuthService,
    private attributeService: AttributeService,
    private store: Store<AppState>,
    private permissions: PermissionService
    //private notifications: LocalNotifications
    //private fcm: FCM
  ) {
    this.initialize()
  }

  initialize () {
    console.log('FCM: initialize')
    onAuthenticationChange(this.store).subscribe(x => {
      console.log('FCM: authentication changed', x)
      if (x) this.watch()
      else this.clear()
    })
    
    let site_name = new URL(location.href).hostname
    if (site_name.startsWith('localhost')) {
      console.warn('is localhost, skipping leader election for notifications')
    } else {
      this.elector.awaitLeadership().then(()=> {
        console.log('this tab is now leader');
        this.isLeader = true
      })
    }
  }

  clear () {
    if (this._subscriber) {
      console.log('unsubscribing cloud messages')
      this._subscriber()
    }
    if (this._interval) window.clearInterval(this._interval)
  }
  _interval
  async watch () {
    this.clear()
    this.checkPermissions()
    /*this._interval = setInterval(() => {
      this.checkPermissions()
    }, 60 * 1000)*/
  }

  async setupServiceWorker () {
    const messaging = getMessaging();
    let supported = await isSupported()
    console.log('is service worker supported:', supported)
    if (!navigator.serviceWorker || !supported) {
      console.warn('service worker messaging is not supported', supported, navigator.serviceWorker)
      if (window.Notification) {
        console.log('enable local notifications')
        this._subscriber = onMessage(messaging, payload => {
          // NOTE: not sure when this will call
          console.log('on window push message', payload)
          new window.Notification(payload.data?.title, {
            body: payload.data?.body,
          });
        })
      }
    } else if (window.Notification) {
      console.log('enable service worker notifications')
      
      navigator.serviceWorker.ready.then((reg) => {
        console.log('service worker registered') //, this, reg)
        reg.addEventListener('notificationclick', (event) => {
          // NOTE: not sure this is ever called
          console.log('clicked notification', event)
        })
        this._subscriber = onMessage(messaging, payload => {
          // NOTE: shows when window is active or visible
          console.log('on service push message', payload)
          if (payload && payload.notification) {
            let n = payload.notification
            if (this.isLeader || !this.elector.hasLeader) {
              console.log('show notification', this.elector.hasLeader, this.isLeader)
              reg.showNotification(n.title, n)
              window.focus()
            } else {
              console.log('not leader, skip notification')
            }
          }
          
        })
      })
    }
  }

  // https://stackoverflow.com/questions/58325574/firebase-fcm-javascript-error-when-request-permission-error-status-500
  // https://github.com/firebase/firebase-js-sdk/issues/2364#issuecomment-570820017
  async turnOnNotification (): Promise<string> {
    try {
      const messaging = getMessaging();
      let token = await getToken(messaging, {vapidKey: FCM_KEY});
      this.setupServiceWorker()
      return token
    } catch (err) {
      if (err.code === "messaging/token-unsubscribe-failed") {
        return await this.turnOnNotification();
      } else {
        if (err.code != "messaging/unsupported-browser" && err.code != 'messaging/failed-service-worker-registration') {
          console.warn(' -- failed to subscribe to notifications --')
          captureException(err)
          //captureMessage('user failed to subscribe to notifications')
        }
        throw err
      }
    } 
  };

  async subscribeFirebaseCloudMessaging () {
    return new Promise<string|null>((resolve, reject) => {
      //const messaging = getMessaging();
      console.log('subscribe firebase messaging')
      this.checkPermissions()
    })
  }

  async getCustomerToken () {
    return this.authService.getAuth().pipe(
      switchMap(auth => {
        let customerId: EntityId<EntityType.USER> = {
          entityType: EntityType.USER, id: auth.authUser.userId
        }
        return this.attributeService.getEntityAttributes(
          customerId, AttributeScope.SERVER_SCOPE, [KEY])
      }),
      map(attrs => {
        return attrs.find(attr => attr.key == KEY)
      })
    ).toPromise()
  }

  async saveCustomerToken (token: string) {
    if (!token) return
    let attr = await this.getCustomerToken()
    
    let value: string[] = []
    
    try {
      if (attr == undefined) {
        value = []
      } else if (Array.isArray(attr.value)) {
        value = attr.value
      } else if (attr.value == undefined) {
        value = []
      } else {
        value = JSON.parse(attr.value) as string[]
      }
    } catch (err) {
      captureMessage('FCM: failed to parse thingsboard notification attribute: ' + attr, 'error')
      console.error('FCM: failed to parse thingsboard value', attr)
    }

    
    if (value.findIndex(v => v == token) != -1) {
      console.log('FCM: notification key already registered')
      return
    }
    value.push(token)

    await this.authService.getAuth().pipe(
      switchMap(auth => {
        
        let customerId: EntityId<EntityType.USER> = {
          entityType: EntityType.USER, id: auth.authUser.userId
        }
        return this.attributeService.saveEntityAttributes(
          customerId, AttributeScope.SERVER_SCOPE, [
            {key: KEY, value: value}
          ]
        )
      })
    ).toPromise()
    this.token = token
    console.log('FCM: saved notification key')
  }

  async getDeliveredNotifications () {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList.notifications.length);
    notificationList.notifications.map(x => {
      console.log('  - ', x.title, '-', x.body)
    })
  }

  async checkPermissions (): Promise<boolean> {
    let hasPermission = await this._checkPermissions()
    //this.state.next({hasPermission: hasPermission})
    this.permissions.setPermission('NOTIFICATION', hasPermission)
    return hasPermission
  }

  async _checkPermissions (): Promise<boolean> {
    if (!Capacitor.isNativePlatform()) {
      if (!window.Notification?.requestPermission) {
        return false
      }
      let result = await window.Notification.requestPermission()
      if (result === 'granted') {
        if (!this.token) {
          console.log('FCM: web permission granted, will turn on notifications')
          try {
            this.token = await this.turnOnNotification()
          } catch (err) {
            console.log('FCM: firebase failed to register notifications')
            return false
          }
          
        }
        return true
      } else {
        console.log('notification not granted access', result)
        //this.permissions.notifyPermissionDenied('Alarm notifications denied')
        return false
      }
    } else {
      let perm = await LocalNotifications.requestPermissions()
      console.log('FCM: requested notification permission', perm.display)
      if (perm.display == 'denied') {
        console.log('FCM: denied')
        //this.permissions.notifyPermissionDenied('Notifications denied access')
        return false
      } else {
        if (!this.token) {
          this.registerMessagingToken()
        }
        return true
      }
    }
  }

  async registerNative () {
    //let granted = await this.notifications.requestPermission()
    console.log('FCM: register native')
    await PushNotifications.addListener('registration', token => {
      console.info('Registration token: ', token.value);
    });
  
    await PushNotifications.addListener('registrationError', err => {
      console.error('Registration error: ', err.error);
    });
  
    await PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('Push notification received: ', notification);
      this.getDeliveredNotifications()
    });
  
    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      console.log('Push notification action performed', notification.actionId, notification.inputValue);
    });

    // external required step
    // register for push
    let permission = await PushNotifications.requestPermissions();
    console.log('FCM: permission:', permission.receive, permission)
    await PushNotifications.register();
    console.log('FCM: register complete!')

    FCM.getToken()
      .then((r) => {
        console.log('FCM: got token', r.token)
        this.saveCustomerToken(r.token)
      })
      .catch((err) => console.log(err));

    // Remove FCM instance
    /*FCM.deleteInstance()
      .then(() => alert(`Token deleted`))
      .catch((err) => console.log(err));*/

    // Enable the auto initialization of the library
    FCM.setAutoInit({ enabled: true }).then(() => {
      console.log('FCM: auto init enabled')
      //alert(`Auto init enabled`)
    });

    // Check the auto initialization status
    FCM.isAutoInitEnabled().then((r) => {
      console.log("FCM: Auto init is " + (r.enabled ? "enabled" : "disabled"));
    });
    await this.getDeliveredNotifications()
    
  }

  async registerMessagingToken () {
    if (Capacitor.isNativePlatform()) {
      await this.registerNative()
    } else {
      let canUseMessaging = await isSupported()
      if (!window.Notification || !canUseMessaging) return console.warn('FCM: app messaging is not supported by this client', canUseMessaging)
      
      let token = await this.subscribeFirebaseCloudMessaging()
      console.log('FCM: messaging token:', token)
      await this.saveCustomerToken(token)
    }
  }

}
