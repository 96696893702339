import { Component, Input, TemplateRef, ViewChild } from "@angular/core"
import { ModalController } from "@ionic/angular"
import { Subscription } from "rxjs"
import { ISiloSettings, SENSOR_TYPE } from "src/app/services/types.service"
import { IRangeValue, ListCrops, LoadingState } from "src/app/util"
import { ICableMarker } from "./farmer-cable-placement"
import { FarmerSilo } from "./farmer-silo.model"


@Component({
  selector: 'farmer-building-settings',
  styles: [`
  
.cell-checkbox {
  display: block;
  width: 25px; height: 25px;
  margin: 5px;
}
  `],
  template: `

<ng-template #cellTemplate let-cell="cell" > 
  <ion-checkbox class="cell-checkbox" (ionChange)="onCheckDisabled(cell, $event)" [(ngModel)]="disabled[cell.column.entityId.id + ':' + cell.key]" 
  ></ion-checkbox>
</ng-template>
<ion-header>
    <ion-toolbar>
    <ion-title>{{silo.label}} Settings</ion-title>
    <ion-buttons slot="primary">
        <ion-button (click)="dismiss()" >
            <ion-icon name="close-outline"></ion-icon>
        </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content >
  <ag-loading [loading]="loading" message="Loading Silo..." (retry)="reload()"></ag-loading>
  <div *ngIf="loading.is_success()" class="dialog-content">
    <ion-list>
      <ion-item>
          <ion-label position="stacked">Device name</ion-label>
          <ion-input [(ngModel)]="settings.label" placeholder="Label"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label>Crop Type</ion-label>
        <ion-select [(ngModel)]="settings.crop_type" interface="popover" ok-text="Okay" cancel-text="Cancel">
          <ion-select-option value="">No Crop</ion-select-option>
          <ion-select-option *ngFor="let crop of crops" [value]="crop.key">{{crop.name}}</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <ion-list>
      <ion-list-header>
          Alarms Threshold
      </ion-list-header>
      <ion-item lines="none">
          <ion-label position="stacked">Temperature Range</ion-label>
          <ion-range [(ngModel)]="sample_temperature_threshold" dualKnobs="true"  min="-20" max="60" color="secondary">
              <ion-label style="width: 20px" slot="start">{{sample_temperature_threshold.lower}}</ion-label>
              <ion-label style="width: 20px" slot="end">{{sample_temperature_threshold.upper}}</ion-label>
          </ion-range>
      </ion-item>
      <ion-item lines="none" *ngIf="silo.hasMoisture()">
          <ion-label position="stacked">Moisture Range</ion-label>
          <ion-range class="range-slider" [(ngModel)]="sample_emc_threshold" dualKnobs="true" pin  min="0" max="100" color="secondary">
              <ion-label style="width: 20px" slot="start">{{sample_emc_threshold.lower}}</ion-label>
              <ion-label style="width: 20px" slot="end">{{sample_emc_threshold.upper}}</ion-label>
          </ion-range>
      </ion-item >
      
      <!--<ion-item lines="none">
          <ion-label position="stacked">Ambient Temperature</ion-label>
          <ion-range [(ngModel)]="ambient_temperature_threshold" dualKnobs="true" pin  min="-40" max="100" color="secondary">
              <ion-label style="width: 20px" slot="start">{{ambient_temperature_threshold.lower}}</ion-label>
              <ion-label style="width: 20px" slot="end">{{ambient_temperature_threshold.upper}}</ion-label>
          </ion-range>
      </ion-item >
      
      <ion-item lines="none">
          <ion-label position="stacked">Ambient Moisture</ion-label>
          <ion-range [(ngModel)]="ambient_emc_threshold" dualKnobs="true" pin  min="0" max="100" color="secondary">
              <ion-label style="width: 20px" slot="start">{{ambient_emc_threshold.lower}}</ion-label>
              <ion-label style="width: 20px" slot="end">{{ambient_emc_threshold.upper}}</ion-label>
          </ion-range>
      </ion-item>-->
    </ion-list>
    

    <ion-list>
      <ion-list-header>
          Silo Dimensions
      </ion-list-header>
      <ion-item>
          <ion-label position="stacked">Total height (meter)</ion-label>
          <ion-input type="number" [(ngModel)]="settings.height" placeholder="Total height (meter)"></ion-input>
      </ion-item>
      <ion-item>
          <ion-label position="stacked">Eave height (meter)</ion-label>
          <ion-input type="number" [(ngModel)]="settings.eave_height" placeholder="Eave height (meter)"></ion-input>
      </ion-item>
      <ion-item>
          <ion-label position="stacked">Diameter (meter)</ion-label>
          <ion-input type="number" [(ngModel)]="settings.diameter" placeholder="Diameter (meter)"></ion-input>
      </ion-item>

      <div style="padding: 20px; background: #f7f7f7; z-index: 99999; position: relative;">
        <farmer-cable-placement [diameter]="settings.diameter" [silo]="silo" (onMarkersUpdated)="onMarkersUpdated($event)" ></farmer-cable-placement>
      </div>

      <!--<div>
        <farmer-silo-table [silo]="silo" [sensorType]="table_sensor_type"
          [cellTemplate]="cellTemplate" [devices]="getCableDevices()"
        ></farmer-silo-table>
      </div>-->
      
    </ion-list>

  </div>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed" (click)="accept()">
      <ion-fab-button color="primary">
          <ion-icon name="checkmark-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
</ion-content>
  `,
  styleUrls: ['farmer-silo.scss']
})
export class FarmerSiloSettings {
  public loading = new LoadingState()
  
  @Input() silo: FarmerSilo
  @Input() settings: ISiloSettings
  @ViewChild('cellTemplate') cellTemplate: TemplateRef<any>
  
  constructor (private modal: ModalController) {}

  table_sensor_type = SENSOR_TYPE.TEMP_AND_HUM
  sample_temperature_threshold: IRangeValue
  sample_emc_threshold: IRangeValue
  ambient_temperature_threshold: IRangeValue
  ambient_emc_threshold: IRangeValue
  crops = ListCrops()

  _subscriptions: Subscription[] = []
  ngOnInit () {
    this.sample_temperature_threshold = {
      lower: this.settings.sample_alarm_min_temperature, 
      upper: this.settings.sample_alarm_max_temperature
    }
    this.sample_emc_threshold = {
      lower: this.settings.sample_alarm_min_emc, 
      upper: this.settings.sample_alarm_max_emc
    }
    this.loading.loading(true)
    this._subscriptions.push(this.silo.updated.subscribe(_ => {
      if (this.silo.isLoaded()) this.loading.success()
    }))
    if (this.silo.isLoaded()) this.loading.success()
    /*this.ambient_temperature_threshold = {
      lower: this.settings.ambient_alarm_min_temperature, 
      upper: this.settings.ambient_alarm_max_temperature
    }
    this.ambient_emc_threshold = {
      lower: this.settings.ambient_alarm_min_emc, 
      upper: this.settings.ambient_alarm_max_emc
    }*/
  }
  reload () { this.silo.load() }
  ngOnDestroy () {
    this._subscriptions.map(s => s.unsubscribe())
  }

  onCheckDisabled (cell, event) {
    console.log('CHECK DISABLED', cell)
  }
  disabled = {}

  updatedMarkers: ICableMarker[]
  onMarkersUpdated (markers: ICableMarker[]) {
    console.log('MARKERS CHANGED', markers)
    this.updatedMarkers = markers
  }
  getCableDevices () {
    if (!this.updatedMarkers) return this.silo.devices
    return this.updatedMarkers.map(x => x.device)
  }

  dismiss () { this.modal.dismiss() }

  async saveSettings () {
    this.settings.sample_alarm_min_temperature = this.sample_temperature_threshold.lower
    this.settings.sample_alarm_max_temperature = this.sample_temperature_threshold.upper
    
    this.settings.sample_alarm_min_emc = this.sample_emc_threshold.lower
    this.settings.sample_alarm_max_emc = this.sample_emc_threshold.upper
    
    /*this.settings.ambient_alarm_min_temperature = this.ambient_temperature_threshold.lower
    this.settings.ambient_alarm_max_temperature = this.ambient_temperature_threshold.upper
    this.settings.ambient_alarm_min_emc = this.ambient_emc_threshold.lower
    this.settings.ambient_alarm_max_emc = this.ambient_emc_threshold.upper*/

    await this.silo.saveSettings(this.settings)
    if (this.updatedMarkers) {
      await this.silo.saveMarkers(this.updatedMarkers)
    }
  }

  

  async accept () {
    await this.saveSettings()
    this.dismiss()
  }
}


