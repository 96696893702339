import { environment } from "src/environments/environment"

const _TEXT = {
  
  general: {
    temperature: 'Temperature',
    humidity: 'Humidity',
    moisture: 'Moisture',

    interval: 'Interval',
    server_down: 'Server temporary down',
    forbidden: 'Forbidden',

    meters: 'Meters', // meters
    email: 'E-mail', // 'E-mail'
    help: 'Help', // Help
    password: 'Password', // Password

    select_language: 'Select language', // Select Language

    first_name: 'First name', // First name
    last_name: 'Last name', // Last name

    latest: 'Latest', // Latest
    menu: 'Menu', // Menu

    accept: 'Accept', // Accept
    
    // time window
    realtime: 'Realtime', // Realtime
    history: 'History', // History
    last: 'Last', // Last
    start_time: 'Start time', // Start Time
    end_time: 'End time', // End Time
    method: 'Method', // Method

    // used in building sensor cards
    latest_temperature: 'Latest Temperature', // Latest Temperature
    latest_humidity: 'Latest Humidity', // Latest Humidity
    latest_emc: 'Latest Moisture', // Latest EMC
    
    no_internet: 'No internet connection', // No Internet Connection
    connecting: 'Connecting', // Connecting
    reconnecting: 'Reconnecting', // Reconnecting
    retry: 'Retry', // Retry

    continue: 'Continue', // Continue
    min: 'Min', // Min
    max: 'Max', // Max
    average: 'Average', // Average
    emc: 'Moisture', // 'emc',
    sensor: 'Sensor', // Sensor
    serial: 'Serial', // Serial
    alarm: 'Alarm', // Alarm
    saving: 'Saving..', // Saving..
    loading: 'Loading..', // Loading...
    ok: 'Ok', // Ok
    okay: 'Okay', // Okay
    no: 'No', // No
    yes: 'Yes', // Yes
    cancel: 'Cancel', // Cancel
    //confirm: 'ag.general.confirm', // Confirm
    create: 'Create', // Create
    devices: 'Devices', // Devices
    alarms: 'Alarms', // Alarms
    building: 'Building', // Building
    buildings: 'Buildings', // Buildings
    checkout: 'Checkout', // Checkout
    add_device: 'Add device', // Add Device
    device_name: 'Device name', // Device name
    crop: 'Crop', // Crop
    crop_type: 'Crop type', // Crop Type
    no_crop: 'No crop', // No Crop
    select_crop: 'Select Crop',
    settings: 'Settings', // Settings
    label: 'Label', // Label
    time: 'Time', // Time
    comment: 'Comment', // Comment

    battery: 'Battery', // Battery
    signal: 'Signal', // Signal

    place_on_map: 'Place on map',
    activate_location: 'Activate location',

    // find trying to add devices to building
    

    // maybe belongs in settings
    is_required: '{{field}} is required', // {{field}} is required
    no_logging: 'No logging', // 'No Logging'
    logging: 'Logging', // Logging
    ambient_temperature: 'Ambient temperature', // Ambient Temperature
    ambient_moisture: 'Ambient Moisture', // Ambient Moisture
    alarm_threshold: 'Alarms threshold', // Alarms Threshold
    report_interval: 'Report interval', // Report Interval
    select_reporting: 'Select reporting', // Select Reporting
    
    temperature_range: 'Temperature range', // Temperature Range
    moisture_range: 'Moiture range', // Moisture Range
    select_device: 'Select device', // Select Device

    // measurement devices
    add_measurement: 'Add measurement', // Add Measurement
    edit_measurement: 'Edit measurement', // Edit Measurement
    measurement_map: 'Measurement map', // Measurement Map
    take_photo: 'Take photo', // Take Photo
    
  },
  device: {
    loading_device: 'Loading device..', // Loading Device...
    remove_device: 'Remove device', // Remove Device
    device_load_error: 'Failed to load device', // 'failed to load device'
    no_subscription: "Device has no data subscription, checkout to activate device", // Device has no data subscription, checkout to activate device
  },
  menu: {
    add_new_device: 'Add new device', // Add New Device
    add_new_building: 'Add new building', // Add New Building
    map_view: 'Map view', // Map View
    alarms: 'Alarms', // Alarms
    billing: 'Billing', // Billing
    refresh: 'Refresh', // Refresh
    about: 'About', // About
    help: 'Help', // Help
  },
  profile: {
    send_telemetry_data_title: "Request telemetry data",
    send_telemetry_data_info: "Send telemetry report to e-mail",
    send_telemetry_data_success: "You will receive an e-mail within the next few minutes",
    send_telemetry_data_failed: "Failed to request telemetry report, please try again later",
    email_weekly_report: 'E-mail weekly report',
    show_ambient_as_humidity: 'Show ambient as humidity',
    advanced: 'Advanced',
    moisture_calibration: 'Moisture Calibration',
    activating: 'ag.profile.activating', // 'Activating Account...'
    activated: 'ag.profile.activated', // Account Activated
    activate_failed: 'ag.profile.activate_failed', // 'failed to activate account'
    reset_password: 'ag.profile.reset_password', // Reset Password
    new_password: 'ag.profile.new_password', // New Password
    set_password: 'ag.profile.set_password', // Set Password
    reset_failed: 'ag.profile.reset_failed', // 'Failed to reset password, please try again later or contact support'
    reset_failed_title: 'ag.profile.reset_failed_title', // 'Reset password failed'
    reset_success_title: 'ag.profile.reset_success_title', // 'Reset password success'
    reset_success: 'ag.profile.reset_success', // 'Password has been reset, you can now login with your new password'
  
    my_profile: 'ag.profile.my_profile', // My Profile
    email_notifications: 'ag.profile.email_notifications', // E-mail Notifications
    change_password: 'ag.profile.change_password', // Change Password
    logout: 'ag.profile.logout', // Logout
    login: 'ag.profile.login', // Login
    register: 'ag.profile.register', // Register
    not_a_member: 'ag.profile.not_a_member', // Not a member yet
    sign_in: 'ag.profile.sign_in', // Sign-in
    sign_up: 'ag.profile.sign_up', // Sign-up
    already_registered: 'ag.profile.already_registered', // Already registered
    delete_account: 'ag.profile.delete_account', // Delete Account
    current_password: 'ag.profile.current_password', // 'Current Password'
    change_password_failed: 'ag.profile.change_password_failed', // 'failed to change password'
    
    delete_account_title: 'ag.profile.delete_account_title', // 'Request Delete Account'
    delete_confirm_input_message: 'ag.profile.delete_confirm_input_message',
    delete_account_confirm: 'ag.profile.delete_account_confirm', // "Are you sure that you want to permanently delete your user-account and all data associated with it?"
    delete_account_success: 'ag.profile.delete_account_success', // "Your request have been received. You will receive a confirmation mail when your request for deletion have been processed. Please contact sales@supertech.dk for any question."
    delete_account_failed: 'ag.profile.delete_account_failed', // "Failed to send request. Please contact us directly on sales@supertech.dk"

    forgot_password: 'ag.profile.forgot_password', // Forgot Password
    forgot_password_help: 'ag.profile.forgot_password_help', // Type your e-mail reset your password
    forgot_password_success: 'ag.profile.forgot_password_success', // a reset-link has been sent to your e-mail. Check your mailbox.
    forgot_password_failed: 'ag.profile.forgot_password_failed', // Failed to send reset link

    email_required: 'ag.profile.email_required', // 'please provide your e-mail'
    password_too_short: 'ag.profile.password_too_short', // 'password must be at least {{length}} characters'
    first_name_required: 'ag.profile.first_name_required', // 'set a first name'
    last_name_required: 'ag.profile.last_name_required', // 'set a last name'
    tos_required: 'ag.profile.tos_required', // 'please accept terms of services'

    login_failed: 'ag.profile.login_failed', // "Failed to login"
    activate_your_account: 'ag.profile.activate_your_account', // Activate your account
    account_not_activated_help: 'ag.profile.account_not_activated_help', // "Check your e-mail to activate your account"
    resend_activation_link: 'ag.profile.resend_activation_link', // 'Resend Activation Mail'
    send_activation_link_success: 'ag.profile.send_activation_link_success', // "a activation link has been sent to {{email}}. Check your mailbox."
    send_activation_link_failed: 'ag.profile.send_activation_link_failed', // "Failed to send activation link"
  
    tos_agree: 'ag.profile.tos_agree', // I agree to Terms & Conditions
    tos_read: 'ag.profile.tos_read', // Read Terms & Conditions
  },
  payment: {
    status: {
      paid: 'ag.payment.status.paid', // Paid
      not_paid: 'ag.payment.status.not_paid', // Not paid
    },
    current_subscriptions: 'ag.payment.current_subscriptions', // Current subscriptions
    renews_on: 'ag.payment.renews_on', // Renews on {{date}}
    cancel_on: 'ag.payment.cancel_on', // Cancel on {{date}}
    billed_every: 'ag.payment.billed_every', // Billed every
    manage_subscriptions: 'ag.payment.manage_subscriptions', // Manage subscriptions
    billing: 'ag.payment.billing', // Billing
    not_renewing_info: 'ag.payment.not_renewing_info', // 'Will Cancel at end of term'
    cancelled: 'ag.payment.cancelled', // 'Cancelled'
    quantity_changed: 'ag.payment.quantity_changed', // Quantity is scheduled to update from {{quantity}} to {{scheduled}} at next renewal: {{date}}
  
    confirmed: 'ag.payment.confirmed', // Payment confirmed
    confirmed_info: 'ag.payment.confirmed_info', // An order confirmation has been sent to your email
    sub_total: 'ag.payment.sub_total', // Sub total
    total: 'ag.payment.total', // Total

    unsubscribed_message: 'ag.payment.unsubscribed_message', // 'You have unsubscribed devices'

    // MESSAGES
    activating: 'ag.payment.activating', // "Activating..."
    awaiting_payment: 'ag.payment.awaiting_payment', // Awaiting payment
    trials_end_at: 'ag.payment.trial_ends_at', // Trial ends {{date}}
    device_checkout_required: 'ag.payment.device_checkout_required', // "Device has no subscription, checkout to activate"
    building_checkout_required: 'ag.payment.building_checkout_required', // "Building contains unsubscribed devices, checkout to activate"
    subscription_cancelled: 'ag.payment.subscription_cancelled', // 'subscription is cancelled'
    cancel_scheduled: 'ag.payment.cancel_scheduled', // 'scheduled to cancel {{date}}'

    // CHECKOUT
    checkout_failed: 'ag.payment.checkout_failed', // 'failed to checkout, please try again later or contact support'
    billing_information: 'ag.payment.billing_information', // Billing Information
    billing_email: 'ag.payment.billing_email', // Billing E-mail
    country: 'ag.payment.country', // Country
    select_country: 'ag.payment.select_country', // Select Your Country
    iam_company: 'ag.payment.iam_company', // I'm a Company
    company_name: 'ag.payment.company_name', // Company Name
    vat_number: 'ag.payment.vat_number', // Vat Number
    select_payment_method: 'ag.payment.select_payment_method', // Select payment method
    checkout_notice: 'ag.payment.checkout_notice', // NOTE: claim all your devices before checkout to avoid multiple invoices
    accept_payment: 'ag.payment.accept_payment', // Accept Payment
    choose_plan: 'ag.payment.choose_plan', // Choose your plan
    per_unit_month: 'ag.payment.per_unit_month', // per unit a month

    card_method_title: 'ag.payment.card_method_title', // 'Credit Card Payment'
    card_method_info: 'ag.payment.card_method_info', // 'get started immediatly'
    bank_method_title: 'ag.payment.bank_method_title', // 'Bank Transfer Payment'
    bank_method_info: 'ag.payment.bank_method_info', // 'only 3 years subscriptions are available'
  },
  settings: {
    use_building: 'ag.settings.use_building', // Some settings are disabled while device is in a building. Use building-settings.
    confirm_remove_device: 'ag.settings.confirm_remove_device', // Are you use you want to remove this device?
    confirm_remove_device_title: 'ag.settings.confirm_remove_device_title', // 'Confirm Remove Device'
    confirm_remove_building_title: 'ag.settings.confirm_remove_building_title', // Confirm Remove Building
    confirm_remove_building: 'ag.settings.confirm_remove_building' // Are you use you want to remove this building?
  },
  building: {
    loading: 'ag.building.loading', // Loading building...
    no_available_devices: 'ag.general.no_available_devices', // 'no available devices'
    temperature_sensor_map: 'ag.building.temperature_sensor_map', // Temperature Sensor Map
    assigned_devices: 'ag.building.assigned_devices', // Assigned Devices
    moisture_sensor_map: 'ag.building.moisture_sensor_map', // Moisture Sensor Map
    humidity_sensor_map: 'Humidity Sensor Map', // Humidity Sensor Map
    create_building: 'ag.building.create_building', // 'Create Building'
    building_name: 'ag.building.building_name', // 'Building Name'
    select_sensors: 'ag.building.select_sensors', // 'Select cable sensors above to show history'
    remove_device: 'ag.building.remove_device', // 'Remove device from building'
    remove_device_confirm: 'ag.building.remove_device_confirm', // 'Are you sure you want to remove {{name}} from building ?'
    edit_device_placements: 'ag.building.edit_device_placements', // Edit Device Placements
    add_device: 'ag.building.add_device', // Add Device To Building
    has_unsubscribed_devices: 'ag.building.has_unsubscribed_devices', // Building have unsubscribed devices, checkout to see building data
    has_no_devices: 'ag.building.has_no_devices', // Building has no devices
    open_settings_to_add_devices: 'ag.building.open_settings_to_add_devices', // Open Settings to assign devices to building
    failed_to_load_building: 'ag.building.failed_to_load_building', // 'failed to load building'
    length: 'ag.building.length', // Building length
    width: 'ag.building.width', // Building width
    remove: 'ag.building.remove', // Remove Building
    no_devices_assigned: 'ag.building.no_devices_assigned', // No devices are assigned to building. 
    add_device_help: 'ag.building.add_device_help', // Click Add Device to assign a device to this building
  },
  alarm: {
    clearing: 'ag.alarm.clearing', // Clearing Alarms...
    clear_all: 'ag.alarm.clear-all', // 'Clear All'
    clear: 'ag.alarm.clear', // Clear Alarms
  },
  chart: {
    no_data: 'ag.plot.no-data',
    temperature_label: "ag.chart.temperature-label", // sensor.name + ' Temp.'
    moisture_label: "ag.chart.moisture-label", // sensor.name + ' EMC.'
    temperature_chart_name: 'ag.chart.temperature_chart_name', // Temperature History
    emc_chart_name: 'ag.chart.emc_chart_name', // EMC History
    humidity_chart_name: 'ag.chart.humidity_chart_name', // Humidity History
  },
  claim: {
    device_name: 'ag.claim.device-name', // Device name
    secret_key: 'ag.claim.secret-key', // Secret key
    scan_qr: 'ag.claim.scan-qr', // Scan QR code
    use_manual_code: "ag.claim.use-manual-code", // Use manual claim code
    claim_error: 'ag.error.claim-error', // 'Failed to claim device'
    not_valid_qr: 'ag.error.not-valid-qr', // 'not a valid device code'
    no_qr_found: 'ag.error.no-qr-found', // 'no qr code found'
    claim_device: "ag.claim.claim-device" // Create Device
  },
  permission: {
    camera_denied: "ag.permission.camera-denied", // Access denied to Camera
    notifications_denied: "ag.permission.notifications-denied", // Access denied to Notifications"
    location_denied: "ag.permission.location_denied", // Access denied to location
    open_application_settings: 'ag.permission.open_application_settings', // Open Application Settings
    update_permissions_help: 'ag.permission.update_permissions_help', // to update permissions and click retry
    browser_denied_help: 'ag.permission.browser_denied_help', // Please check your browser site settings and click retry
  },
  signal: {
    unknown: 'ag.signal.unknown', // Unknown
    no_signal: 'ag.signal.no_signal', // 'No Signal'
    marginal: 'ag.signal.marginal', // 'Marginal'
    ok: 'ag.signal.ok', // 'OK'
    good: 'ag.signal.good', // 'Good'
    excellent: 'ag.signal.excellent', // 'Excellent'
  }
}

let _DEFAULT_TRANSLATIONS = {}

function _UpdateTextKey (root: object, path: string[]) {
  for (var k in root) {
    let value = root[k]
    let key = [...path, k].join('.')
    
    
    if (typeof value == 'string') {
      if (environment.key != 'live') {
        value = '[' + value + ']'
      }
      _DEFAULT_TRANSLATIONS[key] = value
      root[k] = key
    } else {
      _UpdateTextKey(value, [...path, k])
    }
  }
}
_UpdateTextKey(_TEXT, ['text'])
export const TEXT = _TEXT

export const DEFAULT_TRANSLATIONS = _DEFAULT_TRANSLATIONS
