<style>
  .progress {
    z-index: 99999;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background: white;
  }
</style>
<ion-app>
  <ion-router-outlet id="main-content" ></ion-router-outlet>
  <ag-plot-tooltip></ag-plot-tooltip>
  <ag-connection-dialog></ag-connection-dialog>
  <ag-upgrade-dialog></ag-upgrade-dialog>
</ion-app>