import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { AGUserService } from './user.services';
import { JwtHelperService } from '@auth0/angular-jwt';
import { catchError, map, mergeMap, tap, switchMap } from 'rxjs/operators';
import { getQueryParam, updateQueryParam } from '../util';
import { AppState, AuthUser, getCurrentAuthUser } from '../state';
import { Authority, PageData, User } from '../models/entity.model';
import { Store } from '@ngrx/store';
import { ActionAuthAuthenticated, ActionAuthLoadUser, ActionAuthUnauthenticated, AuthPayload, defaultHttpOptions, LoginRequest, LoginResponse, OAuth2ClientInfo, PublicLoginRequest } from '../models/auth.model';
import { defaultHttpOptionsFromConfig } from '../models/auth.model';
import { captureException, captureMessage } from '@sentry/angular';
import { TEXT } from '../texts';


function debug () {

}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private userService: AGUserService,
    private router: Router
  ) {
  }

  redirectUrl: string;
  oauth2Clients: Array<OAuth2ClientInfo> = null;

  private refreshTokenSubject: ReplaySubject<LoginResponse> = null;
  private jwtHelper = new JwtHelperService();

  private getUser (userId: string): Observable<User> {
    return this.http.get<User>(`/api/user/${userId}`, defaultHttpOptionsFromConfig());
  }

  private static _storeGet(key) {
    return localStorage.getItem(key);
  }

  private static isTokenValid(prefix) {
    const clientExpiration = AuthService._storeGet(prefix + '_expiration');
    return clientExpiration && Number(clientExpiration) > (new Date().valueOf() + 2000);
  }

  public static isJwtTokenValid() {
    return AuthService.isTokenValid('jwt_token');
  }

  static clearTokenData() {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('jwt_token_expiration');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expiration');
  }

  public static getJwtToken() {
    return AuthService._storeGet('jwt_token');
  }

  public reloadUser() {
    this.loadUser(true).subscribe(
      (authPayload) => {
        this.notifyAuthenticated(authPayload);
        this.notifyUserLoaded(true);
      },
      (err) => {
        //captureException(err)
        // NOTE: do not send unauthenticated, otherwise it might logout user on error
      }
    );
  }


  public login(loginRequest: LoginRequest): Observable<LoginResponse> {
    loginRequest = {...loginRequest, username: loginRequest.username.trim().toLowerCase()}
    return this.http.post<LoginResponse>('/api/auth/login', loginRequest, defaultHttpOptions()).pipe(
      tap((loginResponse: LoginResponse) => {
          this.setUserFromJwtToken(loginResponse.token, loginResponse.refreshToken, true);
        }
      ));
  }

  public publicLogin(publicId: string): Observable<LoginResponse> {
    const publicLoginRequest: PublicLoginRequest = {
      publicId
    };
    return this.http.post<LoginResponse>('/api/auth/login/public', publicLoginRequest, defaultHttpOptions());
  }

  public sendResetPasswordLink(email: string) {
    email = email.trim().toLowerCase()
    return this.http.post('/api/noauth/resetPasswordByEmail',
      {email}, defaultHttpOptions());
  }

  public activate(activateToken: string, password: string, sendActivationMail: boolean): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`/api/noauth/activate?sendActivationMail=${sendActivationMail}`,
      {activateToken, password}, defaultHttpOptions()).pipe(
      tap((loginResponse: LoginResponse) => {
          this.setUserFromJwtToken(loginResponse.token, loginResponse.refreshToken, true);
        }
      ));
  }

  public resetPassword(resetToken: string, password: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>('/api/noauth/resetPassword', {resetToken, password}, defaultHttpOptions()).pipe(
      tap((loginResponse: LoginResponse) => {
          this.setUserFromJwtToken(loginResponse.token, loginResponse.refreshToken, true);
        }
      ));
  }

  public changePassword(currentPassword: string, newPassword: string) {
    return this.http.post('/api/auth/changePassword',
      {currentPassword, newPassword}, defaultHttpOptions());
  }

  public activateByEmailCode(emailCode: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`/api/noauth/activateByEmailCode?emailCode=${emailCode}`,
      null, defaultHttpOptions());
  }

  public resendEmailActivation(email: string) {
    const encodeEmail = encodeURIComponent(email);
    return this.http.post(`/api/noauth/resendEmailActivation?email=${encodeEmail}`,
      null, defaultHttpOptions());
  }

  public loginAsUserEmail (email: string) {
    return this.http.get<PageData<User>>('/api/users?page=0&pageSize=10&textSearch=' + encodeURIComponent(email)).pipe(
      switchMap(users => {
        if (users.data.length > 0) {
          let userId = users.data[0].id.id
          return this.loginAsUser(userId)
        }
        throw new Error('no such user')
      })
    )
  }

  public loginAsUser(userId: string) {
    return this.http.get<LoginResponse>(`/api/user/${userId}/token`, defaultHttpOptions()).pipe(
      tap((loginResponse: LoginResponse) => {
          this.setUserFromJwtToken(loginResponse.token, loginResponse.refreshToken, true);
        }
      ));
  }

  public logout(captureLastUrl: boolean = false) {
    if (captureLastUrl) {
      this.redirectUrl = this.router.url;
    }
    this.http.post('/api/auth/logout', null, defaultHttpOptions(true, true))
      .subscribe(() => {
          this.clearJwtToken();
        },
        () => {
          this.clearJwtToken();
        }
      );
  }

  private notifyUserLoaded(isUserLoaded: boolean) {
    this.store.dispatch(new ActionAuthLoadUser({isUserLoaded}));
  }

  /*
  public gotoDefaultPlace(isAuthenticated: boolean) {
    const authState = getCurrentAuthState(this.store);
    const url = this.defaultUrl(isAuthenticated, authState);
    this.zone.run(() => {
      this.router.navigateByUrl(url);
    });
  }*/

  /*
  public loadOAuth2Clients(): Observable<Array<OAuth2ClientInfo>> {
    return this.http.post<Array<OAuth2ClientInfo>>(`/api/noauth/oauth2Clients`,
      null, defaultHttpOptions()).pipe(
      catchError(err => of([])),
      tap((OAuth2Clients) => {
        this.oauth2Clients = OAuth2Clients as Observerable<OAuth2ClientInfo>;
      })
    );
  }

  private forceDefaultPlace(authState?: AuthState, path?: string, params?: any): boolean {
    if (authState && authState.authUser) {
      if (authState.authUser.authority === Authority.TENANT_ADMIN || authState.authUser.authority === Authority.CUSTOMER_USER) {
        if ((this.userHasDefaultDashboard(authState) && authState.forceFullscreen) || authState.authUser.isPublic) {
          if (path === 'profile') {
            if (this.userHasProfile(authState.authUser)) {
              return false;
            } else {
              return true;
            }
          } else if (path.startsWith('dashboard.') || path.startsWith('dashboards.') &&
              authState.allowedDashboardIds.indexOf(params.dashboardId) > -1) {
            return false;
          } else {
            return true;
          }
        }
      }
    }
    return false;
  }

  public defaultUrl(isAuthenticated: boolean, authState?: AuthState, path?: string, params?: any): UrlTree {
    let result: UrlTree = null;
    if (isAuthenticated) {
      if (!path || path === 'login' || this.forceDefaultPlace(authState, path, params)) {
        if (this.redirectUrl) {
          const redirectUrl = this.redirectUrl;
          this.redirectUrl = null;
          result = this.router.parseUrl(redirectUrl);
        } else {
          result = this.router.parseUrl('home');
        }
        if (authState.authUser.authority === Authority.TENANT_ADMIN || authState.authUser.authority === Authority.CUSTOMER_USER) {
          if (this.userHasDefaultDashboard(authState)) {
            const dashboardId = authState.userDetails.additionalInfo.defaultDashboardId;
            if (authState.forceFullscreen) {
              result = this.router.parseUrl(`dashboard/${dashboardId}`);
            } else {
              result = this.router.parseUrl(`dashboards/${dashboardId}`);
            }
          } else if (authState.authUser.isPublic) {
            result = this.router.parseUrl(`dashboard/${authState.lastPublicDashboardId}`);
          }
        } else if (authState.authUser.authority === Authority.SYS_ADMIN) {
          this.adminService.checkUpdates().subscribe((updateMessage) => {
            if (updateMessage && updateMessage.updateAvailable) {
              this.store.dispatch(new ActionNotificationShow(
                {message: updateMessage.message,
                          type: 'info',
                          verticalPosition: 'bottom',
                          horizontalPosition: 'right'}));
            }
          });
        }
      }
    } else {
      result = this.router.parseUrl('login');
    }
    return result;
  }*/

  getAuth () {
    return this.loadUser(true).pipe(
      catchError((req, err) => {
        if (req.status > 500) {
          throw new Error(TEXT.general.server_down)
        }
        throw new Error(req.statusText)
      })
    )
  }

  private loadUser(doTokenRefresh): Observable<AuthPayload> {
    const authUser = getCurrentAuthUser(this.store);
    if (!authUser) {
      const publicId = getQueryParam('publicId');
      const accessToken = getQueryParam('accessToken');
      const refreshToken = getQueryParam('refreshToken');
      const username = getQueryParam('username');
      const password = getQueryParam('password');
      const loginError = getQueryParam('loginError');
      if (publicId) {
        return this.publicLogin(publicId).pipe(
          mergeMap((response) => {
            this.updateAndValidateToken(response.token, 'jwt_token', false);
            this.updateAndValidateToken(response.refreshToken, 'refresh_token', false);
            return this.procceedJwtTokenValidate();
          }),
          catchError((err) => {
            updateQueryParam('publicId', null);
            throw Error();
          })
        );
      } else if (accessToken) {
        updateQueryParam('accessToken', null);
        if (refreshToken) {
          updateQueryParam('refreshToken', null);
        }
        try {
          this.updateAndValidateToken(accessToken, 'jwt_token', false);
          if (refreshToken) {
            this.updateAndValidateToken(refreshToken, 'refresh_token', false);
          } else {
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('refresh_token_expiration');
          }
        } catch (e) {
          return throwError(e);
        }
        return this.procceedJwtTokenValidate();
      } else if (username && password) {
        updateQueryParam('username', null);
        updateQueryParam('password', null);
        const loginRequest: LoginRequest = {
          username,
          password
        };
        return this.http.post<LoginResponse>('/api/auth/login', loginRequest, defaultHttpOptions()).pipe(
          mergeMap((loginResponse: LoginResponse) => {
              this.updateAndValidateToken(loginResponse.token, 'jwt_token', false);
              this.updateAndValidateToken(loginResponse.refreshToken, 'refresh_token', false);
              return this.procceedJwtTokenValidate();
            }
          )
        );
      } else if (loginError) {
        this.showLoginErrorDialog(loginError);
        updateQueryParam('loginError', null);
        return throwError(Error());
      }
      return this.procceedJwtTokenValidate(doTokenRefresh)
    } else {
      return this.procceedJwtTokenValidate()
      //return of({} as AuthPayload);
    }
  }

  private showLoginErrorDialog(loginError: string) {
    //console.error('IMPLEMENT SHOW ERROR LOGIN DIALOG')
    /*this.translate.get(['login.error', 'action.close']).subscribe(
      (translations) => {
        const dialogConfig: MatDialogConfig = {
          disableClose: true,
          data: {
            title: translations['login.error'],
            message: loginError,
            ok: translations['action.close']
          }
        };
        this.dialog.open(AlertDialogComponent, dialogConfig);
      }
    );*/
  }

  private procceedJwtTokenValidate(doTokenRefresh?: boolean): Observable<AuthPayload> {
    const loadUserSubject = new ReplaySubject<AuthPayload>();
    this.validateJwtToken(doTokenRefresh).subscribe(
      () => {
        let authPayload = {} as AuthPayload;
        const jwtToken = AuthService._storeGet('jwt_token');
        authPayload.authUser = this.jwtHelper.decodeToken(jwtToken);
        if (authPayload.authUser && authPayload.authUser.scopes && authPayload.authUser.scopes.length) {
          authPayload.authUser.authority = Authority[authPayload.authUser.scopes[0]];
        } else if (authPayload.authUser) {
          authPayload.authUser.authority = Authority.ANONYMOUS;
        }
        if (!authPayload || !authPayload.authUser) {
          loadUserSubject.error(new Error('no authentication data'));
          //this.logout();
          return loadUserSubject
        }
        if (authPayload.authUser.isPublic) {
          authPayload.forceFullscreen = true;
        }
        if (authPayload.authUser.isPublic) {
          this.loadSystemParams(authPayload).subscribe(
            (sysParams) => {
              authPayload = {...authPayload, ...sysParams};
              loadUserSubject.next(authPayload);
              loadUserSubject.complete();
            },
            (err) => {
              loadUserSubject.error(err);
            }
          );
        } else if (authPayload.authUser.userId) {
          this.getUser(authPayload.authUser.userId).subscribe(
            (user) => {
              authPayload.userDetails = user;
              authPayload.forceFullscreen = false;
              /*if (this.userForceFullscreen(authPayload)) {
                authPayload.forceFullscreen = true;
              }*/
              this.loadSystemParams(authPayload).subscribe(
                (sysParams) => {
                  authPayload = {...authPayload, ...sysParams};
                  let userLang;
                  if (authPayload.userDetails.additionalInfo && authPayload.userDetails.additionalInfo.lang) {
                    userLang = authPayload.userDetails.additionalInfo.lang;
                  } else {
                    userLang = null;
                  }
                  this.notifyUserLang(userLang);
                  loadUserSubject.next(authPayload);
                  loadUserSubject.complete();
                },
                (err) => {
                  loadUserSubject.error(err);
                  //this.logout();
                });
            },
            (err) => {
              loadUserSubject.error(err);
              //this.logout();
            }
          );
        } else {
          loadUserSubject.error(null);
        }
      },
      (err) => {
        loadUserSubject.error(err);
      }
    );
    return loadUserSubject;
  }

  
  private loadIsUserTokenAccessEnabled(authUser: AuthUser): Observable<boolean> {
    if (authUser.authority === Authority.SYS_ADMIN ||
        authUser.authority === Authority.TENANT_ADMIN) {
      return this.http.get<boolean>('/api/user/tokenAccessEnabled', defaultHttpOptions());
    } else {
      return of(false);
    }
  }

  private loadSystemParams(authPayload: AuthPayload): Observable<any> {
    const sources: Array<Observable<any>> = [this.loadIsUserTokenAccessEnabled(authPayload.authUser)
                                            //this.fetchAllowedDashboardIds(authPayload),
                                            //this.timeService.loadMaxDatapointsLimit()
                                          ];
    return forkJoin(sources)
      .pipe(map((data) => {
        const userTokenAccessEnabled: boolean = data[0];
        const allowedDashboardIds: string[] = data[1];
        return {userTokenAccessEnabled, allowedDashboardIds};
      }));
  }

  public refreshJwtToken(): Observable<LoginResponse> {
    let response: Observable<LoginResponse> = this.refreshTokenSubject;
    if (this.refreshTokenSubject === null) {
        this.refreshTokenSubject = new ReplaySubject<LoginResponse>(1);
        response = this.refreshTokenSubject;
        const refreshToken = AuthService._storeGet('refresh_token');
        const refreshTokenValid = AuthService.isTokenValid('refresh_token');
        this.setUserFromJwtToken(null, null, false);
        if (!refreshTokenValid) {
          this.refreshTokenSubject.error(new Error('authentication token expired'))// this.translate.instant('access.refresh-token-expired')));
          this.refreshTokenSubject = null;
        } else {
          const refreshTokenRequest = {
            refreshToken
          };
          const refreshObservable = this.http.post<LoginResponse>('/api/auth/token', refreshTokenRequest, defaultHttpOptions());
          refreshObservable.subscribe((loginResponse: LoginResponse) => {
            this.setUserFromJwtToken(loginResponse.token, loginResponse.refreshToken, false);
            this.refreshTokenSubject.next(loginResponse);
            this.refreshTokenSubject.complete();
            this.refreshTokenSubject = null;
          }, () => {
            this.clearJwtToken();
            this.refreshTokenSubject.error(new Error('Login failed, please try again')) //this.translate.instant('access.refresh-token-failed')));
            this.refreshTokenSubject = null;
          });
        }
    }
    return response;
  }

  private validateJwtToken(doRefresh): Observable<void> {
    const subject = new ReplaySubject<void>();
    if (!AuthService.isTokenValid('jwt_token')) {
      if (doRefresh) {
        this.refreshJwtToken().subscribe(
          () => {
            subject.next();
            subject.complete();
          },
          (err) => {
            subject.error(err);
          }
        );
      } else {
        this.clearJwtToken();
        subject.error(null);
      }
    } else {
      //console.log('token okay')
      subject.next();
      subject.complete();
    }
    return subject;
  }

  public refreshTokenPending() {
    return this.refreshTokenSubject !== null;
  }

  public setUserFromJwtToken(jwtToken, refreshToken, notify) {
    if (!jwtToken) {
      AuthService.clearTokenData();
      if (notify) {
        this.notifyUnauthenticated();
      }
    } else {
      this.updateAndValidateToken(jwtToken, 'jwt_token', true);
      this.updateAndValidateToken(refreshToken, 'refresh_token', true);
      if (notify) {
        this.notifyUserLoaded(false);
        this.loadUser(false).subscribe(
          (authPayload) => {
            this.notifyUserLoaded(true);
            this.notifyAuthenticated(authPayload);
          },
          () => {
            this.notifyUserLoaded(true);
            this.notifyUnauthenticated();
          }
        );
      } else {
        this.loadUser(false).subscribe();
      }
    }
  }

  public parsePublicId(): string {
    const token = AuthService.getJwtToken();
    if (token) {
      const tokenData = this.jwtHelper.decodeToken(token);
      if (tokenData && tokenData.isPublic) {
        return tokenData.sub;
      }
    }
    return null;
  }

  private notifyUnauthenticated() {
    this.store.dispatch(new ActionAuthUnauthenticated());
  }

  private notifyAuthenticated(authPayload: any) { // AuthPayload
    this.store.dispatch(new ActionAuthAuthenticated(authPayload));
  }

  private notifyUserLang(userLang: string) {
    //console.error('IMPLEMENT notifyUserLang')
    //this.store.dispatch(new ActionSettingsChangeLanguage({userLang}));
  }

  private updateAndValidateToken(token, prefix, notify) {
    let valid = false;
    const tokenData = this.jwtHelper.decodeToken(token);
    const issuedAt = tokenData.iat;
    const expTime = tokenData.exp;
    if (issuedAt && expTime) {
      const ttl = expTime - issuedAt;
      if (ttl > 0) {
        const clientExpiration = new Date().valueOf() + ttl * 1000;
        localStorage.setItem(prefix, token);
        localStorage.setItem(prefix + '_expiration', '' + clientExpiration);
        valid = true;
      }
    }
    if (!valid && notify) {
      this.notifyUnauthenticated();
    }
  }

  private clearJwtToken() {
    this.setUserFromJwtToken(null, null, true);
  }

  /*
  private userForceFullscreen(authPayload: AuthPayload): boolean {
    return (authPayload.authUser && authPayload.authUser.isPublic) ||
      (authPayload.userDetails && authPayload.userDetails.additionalInfo &&
        authPayload.userDetails.additionalInfo.defaultDashboardFullscreen &&
        authPayload.userDetails.additionalInfo.defaultDashboardFullscreen === true);
  }

  private userHasProfile(authUser: AuthUser): boolean {
    return authUser && !authUser.isPublic;
  }

  private userHasDefaultDashboard(authState: AuthState): boolean {
    if (authState && authState.userDetails && authState.userDetails.additionalInfo
      && authState.userDetails.additionalInfo.defaultDashboardId) {
      return true;
    } else {
      return false;
    }
  }

  private fetchAllowedDashboardIds(authPayload: AuthPayload): Observable<string[]> {
    if (authPayload.forceFullscreen && (authPayload.authUser.authority === Authority.TENANT_ADMIN ||
      authPayload.authUser.authority === Authority.CUSTOMER_USER)) {
      const pageLink = new PageLink(100);
      let fetchDashboardsObservable: Observable<PageData<DashboardInfo>>;
      if (authPayload.authUser.authority === Authority.TENANT_ADMIN) {
        fetchDashboardsObservable = this.dashboardService.getTenantDashboards(pageLink);
      } else {
        fetchDashboardsObservable = this.dashboardService.getCustomerDashboards(authPayload.authUser.customerId, pageLink);
      }
      return fetchDashboardsObservable.pipe(
        map((result) => {
          const dashboards = result.data;
          return dashboards.map(dashboard => dashboard.id.id);
        })
      );
    } else {
      return of([]);
    }
  }*/
}
