import { Component, ElementRef, EventEmitter, Injectable, Input, Output, ViewChild } from "@angular/core";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { captureMessage } from "@sentry/angular";
import { agTypes } from "src/app/ag-types";
import { Asset, Device, EntityType } from "src/app/models/entity.model";
import { FarmerService } from "src/app/services/device.service";
import { EntityService } from "src/app/services/entity.service";
import { ToastService } from "src/app/services/toast.service";
import { FarmerType, IEntityAttribute } from "src/app/services/types.service";
import { deviceImage, deviceLabel, isBrowser, isQrDevice } from "src/app/util";
import { IScanResult } from "./farmer-device-scanner";
import { Html5Qrcode } from "html5-qrcode"
import { Capacitor } from "@capacitor/core";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { TranslateService } from "@ngx-translate/core";
import { TEXT } from "src/app/texts";
import { ATTRIBUTES, ENTITIES, IEntityType } from "src/app/constant";


@Component({
  selector: 'farmer-device-config',
  styleUrls: ['farmer-devices.scss'],
  template: `
<form #form (ngSubmit)="accept()" >
  <ion-item *ngFor="let attr of attributes; let i = index" ><!--class="device-field"-->
    <ion-label position="stacked">{{attr.name}}:</ion-label>
    <ion-input [name]="attr.name" (ionChange)="validate()" *ngIf="attr.type == 'string'" [attr.name]="attr.key" [placeholder]="attr.name" [(ngModel)]="settings[attr.key]"
                [tabIndex]="i" autocomplete="off"></ion-input>
    <ion-input [name]="attr.name" (ionChange)="validate()" *ngIf="attr.type == 'number'" [attr.name]="attr.key" [placeholder]="attr.name" type="number" [(ngModel)]="settings[attr.key]"
                [tabIndex]="i" autocomplete="off"></ion-input>
    <!-- TODO: add translation to Select -->
    <!--<ion-select [name]="attr.name" (ionChange)="validate()" *ngIf="attr.scope == 'relation'" [attr.name]="attr.key" [placeholder]="'Select ' + attr.name" [(ngModel)]="settings[attr.key]" interface="popover"
                [tabIndex]="i" autocomplete="off">
      <ion-select-option *ngFor="let item of getAttributeOptions(attr)" [value]="item.id.id">{{item.label}}</ion-select-option>
    </ion-select>-->
    <div class="error-label" *ngIf="errors[attr.key]">{{errors[attr.key]}}</div>
  </ion-item>
  <button class="create-button" id="device-create-button" type="submit" expand="block" [disabled]="!isValidSettings()">
    {{text.general.create | translate}}
  </button>
</form>
  `
})
export class FarmerDeviceConfig {
  //@Input() type: FarmerType<any,any>
  @Input() attributes: IEntityAttribute<any>[]
  @Output() onAccept = new EventEmitter<any>()

  @ViewChild('form') form: ElementRef<HTMLFormElement>; 

  text = TEXT
  settings = {} as any
  errors = {}

  constructor (
    private farmer: FarmerService,
    private translate: TranslateService
  ) {}

  translateAttributeKey (key: string) {
    let attribute = ATTRIBUTES.find(x => x.key == key)
    if (!attribute) {
      return (key[0].toUpperCase() + key.slice(1)).replace('_', ' ')
    }
    return this.translate.instant(attribute.name);
  }

  ngOnInit () {
    this.attributes.map(attr => {
      if (attr.default) this.settings[attr.key] = attr.default
      attr.name = this.translateAttributeKey(attr.key as string)
    })
    this.validate()
  }

  ngAfterViewInit () {
    const setFocus = () => {
      let inp = this.form.nativeElement.querySelector('input')
      if (inp) inp.focus()
      else setTimeout(setFocus, 300)
    }
    setFocus()
  }

  validate () {
    this.errors = {}
    this.attributes.map(attr => {
      let val = this.settings[attr.key]
      if (!val) {
        this.errors[attr.key] = this.translate.instant(this.text.general.is_required, {field: attr.name})
      }
    })
  }

  getAttributeOptions (attr: IEntityAttribute<any>) {
    return this.farmer.getAttributeOptions(attr)
  }

  isValidSettings () {
    return this.attributes.find(attr => {
      return !(this.settings[attr.key])
    }) == undefined
  }

  getSiloAssets () {
    return this.farmer.assetEntities.filter(x => x.type == agTypes.farmerDeviceTypes.silo.value)
  }
  accept () {
    this.onAccept.emit(this.settings)
  }
}


interface INewDevice {
  name: string, type: string, secretKey: string, label: string,
  typename: string, width?: number, length?: number
}
function _newDevice (deviceType=''): INewDevice {
  return {
    name: '', type: deviceType, secretKey: '', label: '', typename: ''
  }
}



@Injectable()
export class CameraService {

  constructor () {}

  hasPermission = true
  hasError = false
  cameras: {id: string, name: string, label: string}[] = []

  async checkNativePermission () {
    if (!Capacitor.isNativePlatform()) return true
    let result = await BarcodeScanner.checkPermission({ force: true });
    return result.granted
  }

  async loadCameras () {
    // NOTE: we only need to load cameras once, but also Html5Qrcode will give an error when loading twice
    if (this.cameras.length > 0 && !this.hasError && this.hasPermission) return true
    console.log('loading cameras..')
    let devices = []
    this.hasPermission = await this.checkNativePermission()
    this.hasError = false
    try {
      devices = await Html5Qrcode.getCameras()
    } catch (err) {
      console.error('html5Qrcode failed to get cameras')
      console.error(err)
      if (!Capacitor.isNativePlatform()) this.hasPermission = false
      // it will sometimes fail on native even if permission
      //// this.hasPermission = await this.checkNativePermission()
      //return await this.checkNativePermission()
      /*
      if (err.err && err.err.find("NotAllowedError") != -1) {
        console.error(err.err)
        this.hasPermission = false
      } else {
        return await this.checkNativePermission()
      }
      this.hasError = true*/
    }
    console.log('loaded cameras', this.hasPermission, devices.length)
    if (!devices || !devices.length || !this.hasPermission) return this.hasPermission
    
    this.cameras = devices.map(d => {
      return {id: d.id, label: d.label, name: d.label}
    }).filter(x => {
      return !x.label.toLowerCase().includes('front')
    })
    this.cameras.reverse()
    this.cameras.map(x => {
      console.log('camera:', x.label, x.id)
    })
    return this.hasPermission
  }
}

const SELECT_STEP = "Select";
const CONFIG_STEP = "Config";
const CLAIM_STEP = "Claim"
const SCAN_STEP = 'Scan'
const MANUAL_STEP = 'Manual'

@Component({
  selector: 'farmer-add-device',
  template: `
<ion-header>
  <ion-toolbar>
  <ion-buttons class="page-tab-list">
    <ion-button (click)="setDevicesPage()" class="page-tab" [class]="{active: isDevicesPage()}">
      {{text.general.devices | translate}}
    </ion-button>
    <ion-button (click)="setAssetsPage()" class="page-tab" [class]="{active: isAssetsPage()}">
      {{text.general.buildings | translate}}
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="primary">
      <ion-button (click)="dismiss()" >
          <ion-icon name="close-outline"></ion-icon>
      </ion-button>
      </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="select-farmer-device-dialog" >

  <div style="margin-top: 10px !important;" fxLayout="column" fxFlex *ngIf="addDeviceStep == 'Select'" class="fade">
      <div [attr.id]="deviceType.key" (click)="selectDeviceType(deviceType.key)" 
          fxLayout="row" class=" ag-card select-item device-type-card" *ngFor="let deviceType of deviceTypes">
        <div fxFlex fxLayout="column" class="select-item-content">
          <div class="select-item-title">{{deviceType.name | translate}}</div>
          <div class="select-item-description">{{deviceType.description | translate}}</div>
        </div>
        <div class="img-container" fxLayout="column" fxLayoutAlign="center center">
          <img src="{{getDeviceImg(deviceType.key)}}" [class]="deviceType.key" class="device-pic">
        </div>
      </div>
  </div>

  <div fxFlex *ngIf="addDeviceStep == 'Scan'" style="height: 100%;">
    <div *ngIf='!camera.hasPermission || camera.hasError' 
      style="position: absolute; top: 100px; z-index: 99999;" class='ag-card'>
      <permission-error type="CAMERA"
        [message]="text.permission.camera_denied" (onRetry)="loadCameras()" ></permission-error>
    </div>

    <div style="position: absolute; right: 20px; bottom: 50px; z-index: 99999;">
      <button style="padding: 10px; background: none; color: white;" (click)="close()">
        {{text.general.cancel | translate}}  
      </button>
    </div>

    <div *ngIf="isNative && showCamera()" id='AGROLOG_WEBCAM_SCAN' [cameraId]="cameraId" agQrScan
        (onResult)="onScanResult($event)" (onError)="onScanError($event)" (onLog)="log($event)"></div>
    
    <div *ngIf="!isNative && showCamera()" fxLayout="column" fxLayoutAlign="center center" style="height: 100%; background: black;">
      <div style="background: black; height: 100%; width: 100%; color: white;">
        <video *ngIf="activeCamera" id='AGROLOG_WEBCAM_SCAN' [cameraId]="cameraId"  agQrScan style="width: 100%; max-height: 80%"
        (onResult)="onScanResult($event)" (onError)="onScanError($event)" (onLog)="log($event)"></video>
        <ion-select  [(ngModel)]="cameraId" (ngModelChange)="onSelectCamera()" interface="popover" ok-text="Okay" cancel-text="Cancel">
          <ion-select-option *ngFor="let camera of camera.cameras" [value]="camera.id">
            {{camera.label}}
          </ion-select-option>
        </ion-select>
      </div>
    </div>
  </div>

  <div fxFlex *ngIf="addDeviceStep == 'Claim'" style="display: flex; height: 100%; flex-direction: column; justify-content: center;">
    <div *ngIf="newDevice.type == 'cellular_sensor_spear'" class="device-card" style="flex-grow: 0;border-radius: 10px;margin-bottom: 0;padding: 23px 10px;">
      <img src="/assets/images/qrscan_help.jpg" style="border-radius: 6px;" />      
    </div>
    <div *ngIf="newDevice.type == 'c-pro'" class="device-card" style="flex-grow: 0;border-radius: 10px;margin-bottom: 0;padding: 23px 10px;">
      you can find the QR-code on the display by going to Settings -> Register    
    </div>
    <div class="device-card" style="flex-grow: 0;border-radius: 10px;">
      <div fxLayout="row" class="device-header" style="justify-content: center;">
        <div fnLayout="row" fxLayoutAlign="center center" style="margin-right: 25px;">
          <img style="max-height: 120px;" src="{{getDeviceImg(newDevice.type)}}" class="device-pic">
        </div>
        <div style="display: flex; flex-direction: column; justify-content: center;">
          <div class="device-title" style="align-self: center">{{title | translate}}</div>
          <ion-button style="--border-radius: 15px; text-transform: none;" size="large" (click)="startQrScan()" class="scan-qr-button">
            <ion-icon src="/assets/svg/iconset/qr_icon.svg"></ion-icon>
            <ion-label id='label' style="margin: 10px">{{text.claim.scan_qr | translate}}</ion-label>
          </ion-button>
        </div>
      </div>
    </div>
    <ion-text class="manual-claim-text" (click)="setStep('Manual')">{{text.claim.use_manual_code | translate}}</ion-text>    
  </div>
  <div class="device-card" *ngIf="addDeviceStep == 'Manual'">
    <div fxLayout="row" class="device-header">
      <div class="img-container" fnLayout="row" fxLayoutAlign="center center">
        <img src="{{getDeviceImg(newDevice.type)}}" class="device-pic">
      </div>
      <div class="device-title">{{newDevice.name}}</div>
      
    </div>
    <ion-item>
      <ion-label position="stacked">{{text.claim.device_name | translate}}</ion-label>
      <ion-input [(ngModel)]="newDevice.name" placeholder="{{text.claim.device_name | translate}}"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">{{text.claim.secret_key | translate}}</ion-label>
      <ion-input [(ngModel)]="newDevice.secretKey" placeholder="{{text.claim.secret_key | translate}}"></ion-input>
    </ion-item>
    <section>
      <ion-button expand="block" (click)="claimDevice()" [disabled]="!isInputValid()">
        {{text.claim.claim_device | translate}}
      </ion-button>
    </section>
  </div>
  <div class="device-card" *ngIf="addDeviceStep == 'Config'" >
    
    <div fxLayout="row" class="device-header">
      <div class="img-container" fnLayout="row" fxLayoutAlign="center center">
        <img src="{{getDeviceImg(newDevice.type)}}" class="device-pic">
      </div>
      <div class="device-title">{{newDevice.name}}</div>
    </div>
    
    <div class="device-content">
      <!--[type]="newDevice.type" -->
      <farmer-device-config [attributes]="configAttributes" (onAccept)="complete($event)"></farmer-device-config>
    </div>
  </div>
</ion-content>
  `,
  styleUrls: ['farmer-devices.scss']
})
export class AddDeviceDialogComponent {
  @Input() page: EntityType = EntityType.DEVICE
  agTypes = agTypes;
  addDeviceStep = SELECT_STEP
  currentDeviceType = null;
  newDevice = _newDevice()
  device: Device | Asset
  loading = false
  deviceTypes: IEntityType[] = []
  
  isScanning = false
  isLoading = false
  isNative = Capacitor.isNativePlatform()
  configAttributes: IEntityAttribute<any>[]

  title = ''
  text = TEXT

  scanLogs: string[] = []

  constructor (
    private platform: Platform,
    private dialogRef: ModalController,
    private deviceService: FarmerService,
    private entityService: EntityService,
    private toast: ToastService,
    private alertController: AlertController,
    public camera: CameraService,
    private translate: TranslateService
  ) {}

  onSelectCamera () {
    console.log('select camera')
  }


  showCamera () {
    //console.log('check show camera', this.isScanning, this.camera.hasPermission, this.camera.hasError)
    return this.isScanning && this.camera.hasPermission && !this.camera.hasError
  }

  checkPermissions () {}
  
  cameraId: string
  get activeCamera () {
    if (this.cameraId) return this.cameraId
    else if (this.camera.cameras.length == 0) return null
    return this.camera.cameras[0].id
  }
  async loadCameras () {
    await this.camera.loadCameras()
    if (this.camera.cameras.length > 0) {
      console.log('using first loaded camera:', this.camera.cameras[0].id, 'isScanning=', this.isScanning, 'hasPermission=', this.camera.hasPermission, 'hasError=', this.camera.hasError)
      this.cameraId = this.camera.cameras[0].id
    } else {
      console.warn('no cameras found!')
      // isScanning && !isNative && camera.hasPermission && !camera.hasError
    }
  }

  get farmer () { return this.deviceService }
  getConfigAttributes () {
    let type = this.farmer.getModelType(this.newDevice.type)
    let attrs = type.getCreateAttributes()
    attrs = attrs.map(attr => {
      // TODO: hacky
      if (attr.key == 'label' && this.device) { 
        attr = {...attr, default: this.device.name}
        attr.default = this.device.name
      }
      return attr
    })
    return attrs
  }

  setStep (step: string) {
    if (step == CONFIG_STEP) {
      console.log('set step config', this.newDevice)
      this.configAttributes = this.getConfigAttributes()
    } 
    if (step == SCAN_STEP) {
      this.loadCameras()
      this.isScanning = true
    } else {
      this.isScanning = false
    }
    this.addDeviceStep = step
  }

  async finalize (settings) {
    if (this.isQrDevice()) {
      await this.farmer.saveEntity(this.device, settings)
    } else {
      await this.farmer.createEntity(this.newDevice.type, settings)
    }
    this.close()
  }

  complete (settings) {
    this.finalize(settings)
  }
  isDevicesPage () { return this.page == EntityType.DEVICE}
  isAssetsPage () { return this.page == EntityType.ASSET}
  setPage (page: EntityType) {
    this.page = page
    this.setStep(SELECT_STEP)
    this._loadDeviceTypes()
  }
  setDevicesPage () { this.setPage(EntityType.DEVICE) }
  setAssetsPage () { this.setPage(EntityType.ASSET) }

  _loadDeviceTypes () {
    this.deviceTypes = []
    let excludedDevices = [
      agTypes.farmerDeviceTypes.silo.value,
      agTypes.farmerDeviceTypes.slmCable.value,
      agTypes.farmerDeviceTypes.sltCable.value,
      agTypes.farmerDeviceTypes.tmsGateway.value
    ]
    ENTITIES.map(x => {
      if (excludedDevices.includes(x.key)) return
      if (x.entityType != this.page) return
      this.deviceTypes.push(x)
    })
    /*for (var k in agTypes.farmerDeviceTypes) {
      let type = agTypes.farmerDeviceTypes[k]
      if (excludedDevices.includes(type.value))
        continue
      if (type.entityType != this.page) {
        continue
      }
      this.deviceTypes.push(type)
    }*/
  }

  startQrScan () { 
    this.setStep(SCAN_STEP)
  }
  stopQrScan () {
    this.setStep(CLAIM_STEP)
  }

  log (msg: string) { this.scanLogs.push(msg) }

  dismiss () { this.dialogRef.dismiss() }

  get platforms () { return this.platform.platforms().concat([document.URL]) }
  get isBrowser () { return isBrowser(this.platform) }

  ngOnInit () {
    this.device = null
    this.isLoading = false
    this.newDevice = _newDevice()
    this._loadDeviceTypes()
  }

  isBuilding () {
    return this.newDevice.type == 'building' // TODO: use constant
  }

  isQrDevice () {
    return isQrDevice(this.newDevice.type)
  }

  isInputValid () {
    if (this.isBuilding()) {
      return this.newDevice.name && this.newDevice.length > 0 && this.newDevice.width > 0
    } else if (this.isQrDevice()) {
      return this.newDevice.name && this.newDevice.secretKey
    } else {
      return this.newDevice.name
    }
  }

  async onScanError (err) {
    //console.error('SCAN ERROR:', err)
    this.stopQrScan()
    let alertComplete = await this.alertController.create({
      message: err.toString(),
      buttons: [
        {
          text: this.translate.instant(this.text.general.ok),
          role: this.translate.instant(this.text.general.cancel)
        }
      ]
    });
    await alertComplete.present();
  }

  onScanResult (result: IScanResult) {
    //console.log('SCAN RESULT:', result)
    this.stopQrScan()
    Object.assign(this.newDevice, {
      name: result.name, secretKey: result.secretKey
    })
    this.claimDevice()
  }

  async addDevice () {
    await this.entityService.saveEntity(this.device)
    this.close()
  }

  selectDeviceType (deviceType) {
    console.log('select device type', deviceType, this.deviceTypes)
    this.currentDeviceType = deviceType;
    this.title = this.deviceTypes.find(t => t.key == deviceType).name
    this.newDevice = _newDevice(deviceType)
    this.setStep(isQrDevice(deviceType) ? CLAIM_STEP : CONFIG_STEP)
  }

  getDeviceImg (deviceType) {
    return deviceImage(deviceType)
  }
  deviceTypeLabel (deviceType: string) {
    return deviceLabel(deviceType)
  }
  //deviceTypeDescription (deviceType: string) { return deviceDescription(deviceType) }

  async claimDevice () {
    this.loading = true
    let success = true
    try {
      let data = await this.deviceService.claimDevice(this.newDevice.name, this.newDevice.secretKey, {ignoreErrors: true})
      this.device = data.device
      this.close()
    } catch (err) {
      captureMessage('user failed to claim device ' + this.newDevice.name, 'info')
      this.toast.notify({
        message: this.translate.instant(this.text.claim.claim_error), 
        type: 'error'
      })
      success = false
      this.setStep(CLAIM_STEP)
    } finally {
      this.loading = false
    }
  }

  close () {
    console.log('close create device dialog')
    this.dialogRef.dismiss()
  }

  cancel() {
    this.dialogRef.dismiss()
  }
}
