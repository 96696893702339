

import { Action, ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, INIT, MetaReducer, select, Store, UPDATE } from '@ngrx/store'
import { take } from 'rxjs/operators'
import { AuthPayload } from './models/auth.model'
import { Authority, User } from './models/entity.model'
import { AggregationType } from './models/telemetry.model'
import { LocalStorageService } from './services/local-storage.service'

import { environment } from '../environments/environment';

const emptyUserAuthState: AuthPayload = {
  authUser: null,
  userDetails: null,
  userTokenAccessEnabled: false,
  forceFullscreen: false,
  allowedDashboardIds: []
};

export const initialState: AuthState = {
  isAuthenticated: false,
  isUserLoaded: false,
  lastPublicDashboardId: null,
  ...emptyUserAuthState
};

export enum AuthActionTypes {
  AUTHENTICATED = '[Auth] Authenticated',
  UNAUTHENTICATED = '[Auth] Unauthenticated',
  LOAD_USER = '[Auth] Load User',
  UPDATE_USER_DETAILS = '[Auth] Update User Details',
  UPDATE_LAST_PUBLIC_DASHBOARD_ID = '[Auth] Update Last Public Dashboard Id',
  UPDATE_HOST = '[Host] Update Host'
}


export class ActionAuthAuthenticated implements Action {
  readonly type = AuthActionTypes.AUTHENTICATED;

  constructor(readonly payload: AuthPayload) {}
}

export class ActionAuthUnauthenticated implements Action {
  readonly type = AuthActionTypes.UNAUTHENTICATED;
}

export class ActionAuthLoadUser implements Action {
  readonly type = AuthActionTypes.LOAD_USER;

  constructor(readonly payload: { isUserLoaded: boolean }) {}
}

export class ActionAuthUpdateUserDetails implements Action {
  readonly type = AuthActionTypes.UPDATE_USER_DETAILS;

  constructor(readonly payload: { userDetails: User }) {}
}

export class ActionAuthUpdateLastPublicDashboardId implements Action {
  readonly type = AuthActionTypes.UPDATE_LAST_PUBLIC_DASHBOARD_ID;

  constructor(readonly payload: { lastPublicDashboardId: string }) {}
}

export type AuthActions = ActionAuthAuthenticated | ActionAuthUnauthenticated |
  ActionAuthLoadUser | ActionAuthUpdateUserDetails | ActionAuthUpdateLastPublicDashboardId;


export interface IAppHostState {
  api: string
  ws: string
}

export class ActionInitState implements Action {
  readonly type = 'Initialize State'
  constructor (readonly payload: AppState) {}
}

const API_HOST: IAppHostState = {
  api: environment.api,
  ws: environment.ws
}

function isTestSite () {
  let site_name = new URL(location.href).hostname
  return site_name.startsWith('localhost') || site_name.startsWith('farmer-app-dev') || site_name.startsWith('46.4.74.70')
}

function defaultHost (): IAppHostState {
  let host = API_HOST
  return host
}

export class ActionUpdateHost implements Action {
  readonly type = AuthActionTypes.UPDATE_HOST;

  constructor(readonly payload: IAppHostState) {}
}

export function hostReducer(
  state: IAppHostState = defaultHost(),
  action: any
): IAppHostState {
  switch (action.type) {
    case AuthActionTypes.UPDATE_HOST:
      //console.log('UPDATE HOST', action.payload)
      return action.payload;
    default:
      return state;
  }
}
export function settingsReducer(
  state: TimeframeState = {
    startTs: now() - (1000 * 60 * 60 * 24 * 1),
    interval: 1000 * 60 * 5,
    agg: AggregationType.AVG
  },
  action: any
): TimeframeState {
  return state
}


export function authReducer(
  state: AuthState = initialState,
  action: AuthActions
): AuthState {
  switch (action.type) {
    case AuthActionTypes.AUTHENTICATED:
      return { ...state, isAuthenticated: true, ...action.payload };

    case AuthActionTypes.UNAUTHENTICATED:
      return { ...state, isAuthenticated: false, ...emptyUserAuthState };

    case AuthActionTypes.LOAD_USER:
      return { ...state, ...action.payload, isAuthenticated: action.payload.isUserLoaded ? state.isAuthenticated : false,
        ...action.payload.isUserLoaded ? {} : emptyUserAuthState };

    case AuthActionTypes.UPDATE_USER_DETAILS:
      return { ...state, ...action.payload};

    case AuthActionTypes.UPDATE_LAST_PUBLIC_DASHBOARD_ID:
      return { ...state, ...action.payload};

    default:
      return state;
  }
}




export function initStateFromLocalStorage(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return (state, action) => {
    const newState = reducer(state, action);
    if ([INIT.toString(), UPDATE.toString()].includes(action.type)) {
      let initialState = LocalStorageService.loadInitialState()
      let resultState = { ...newState, ...initialState, testing: isTestSite(), host: defaultHost() }      
      console.log('initial app state', resultState)
      return resultState;
    }
    return newState;
  };
}

export function featuresReducer(
  state: IFeatures = {},
  action: any
): IFeatures {
  switch (action.type) {
    case 'UPDATE_FEATURES':
      console.log(' - setting features', action.payload)
      return { ...state, ...action.payload };
    default:
      return state;
  }
}


export const reducers: ActionReducerMap<AppState> = {
  //load: loadReducer,
  host: hostReducer,
  auth: authReducer,
  settings: settingsReducer,
  testing: () => isTestSite(),
  features: featuresReducer
  //settings: settingsReducer,
  //notification: notificationReducer
};

export const metaReducers: MetaReducer<AppState>[] = [
  initStateFromLocalStorage
];



/*
if (!env.production) {
  metaReducers.unshift(storeFreeze);
  metaReducers.unshift(debug);
}

export const effects: Type<any>[] = [
  SettingsEffects,
  NotificationEffects
];

export interface AppState {
  load: LoadState;
  auth: AuthState;
  settings: SettingsState;
  notification: NotificationState;
}

*/


// @Injectable()

/*
export class AppState {
  //host: string = 'http://panel.agrolog.io' 
  //wshost: string = 'ws://panel.agrolog.io'
  //token: string = TEST_TOKEN
  host: IAppHostState
  auth = new AuthState()
  settings = new TimeframeState()
  notifications: NotificationState
}
*/

export interface IFeatures {
  serverDown?: boolean
}

export interface AppState {
  //load: LoadState;
  host: IAppHostState
  auth: AuthState;
  settings: TimeframeState;
  testing: boolean;
  features: IFeatures
  //notification: NotificationState;
}


function now () { return new Date().getTime() }

export class TimeframeState {
  startTs: number = now() - (1000 * 60 * 60 * 24 * 1)
  interval: number = 1000 * 60 * 5
  agg: AggregationType = AggregationType.AVG
}


export interface AuthUser {
  sub: string;
  scopes: string[];
  userId: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  tenantId: string;
  customerId: string;
  isPublic: boolean;
  authority: Authority;
}


export class AuthState {
  isAuthenticated: boolean;
  isUserLoaded: boolean;
  authUser: AuthUser;
  userDetails: User;
  userTokenAccessEnabled: boolean;
  allowedDashboardIds: string[];
  forceFullscreen: boolean;
  lastPublicDashboardId: string;
}

export function _getCurrentAuthState(store: AppState): AuthState {
  return store.auth
}

export function _getCurrentAuthUser(store: AppState): AuthUser {
  return store.auth.authUser
}








export const selectAuthState = createFeatureSelector<AppState, AuthState>(
  'auth'
);
export const selectIsUserLoaded = createSelector(
  selectAuthState,
  (state: AuthState) => state.isUserLoaded
);



export const selectUserDetails = createSelector(
  selectAuthState,
  (state: AuthState) => state.userDetails
);

export const selectUserTokenAccessEnabled = createSelector(
  selectAuthState,
  (state: AuthState) => state.userTokenAccessEnabled
);
export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => state.isAuthenticated
);
export const selectAuth = createSelector(
  selectAuthState,
  (state: AuthState) => state
);
export const selectAuthUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.authUser
);

export function getCurrentAuthState(store: Store<AppState>): AuthState {
  let state: AuthState;
  store.pipe(select(selectAuth), take(1)).subscribe(
    val => state = val
  );
  return state;
}

export function getCurrentAuthUser(store: Store<AppState>): AuthUser {
  let authUser: AuthUser;
  store.pipe(select(selectAuthUser), take(1)).subscribe(
    val => authUser = val
  );
  return authUser;
}