import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { init as sentryAngularInit, captureMessage, captureException }  from "@sentry/angular";
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import build from 'src/build.json'

console.log('build version:', build.version)
console.log('environment:', environment)

import { enableProdMode } from '@angular/core';

if (environment.production) {
  enableProdMode();
}

const SENTRY_DNS = "https://10b6a895641e45dbb2c40fe3edecf3e4@sentry2.agrolog.io/2"
// old sentry dns: "https://1ae292a3412e494e8af3123f9a9b4272@sentry.agrolog.io/11",
sentryAngularInit({
  dsn: SENTRY_DNS, 
  environment: environment.name, 
  release: build.version, maxBreadcrumbs: 0
})
/*
beforeSend(event) {
    console.log('send event:', event)
    return event
  }
*/

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(success => {
    console.log(`startup success`)
    //captureMessage('new session: ' + JSON.stringify(environment))
  })
  .catch(error => {
    captureException(error)
    alert("Error occured: " + error.message);
    console.error(error)
  });

defineCustomElements(window);