import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { Store, select } from "@ngrx/store";
import { ICrop } from "src/app/constant";
import { EntityId } from "src/app/models/entity.model";
import { AuthService } from "src/app/services/auth.service";
import { FarmerService } from "src/app/services/device.service";
import { AttributeService } from "src/app/services/entity.service";
import { TelemetryService, TimeseriesSubsciption } from "src/app/services/telemetry";
import { AGUserService } from "src/app/services/user.services";
import { AppState, selectIsAuthenticated } from "src/app/state";
import { TEXT } from "src/app/texts";
import { Index, ListCrops, LoadingState } from "src/app/util";
import { Subscription } from "rxjs";


interface ICropCalibration {
  crops: Index<number>
}

const CalibrationKey = 'moisture-calibrations'

@Component({
  template: `
<ion-header>
    <ion-toolbar>
    <ion-title>Callibration</ion-title>
    <ion-buttons slot="primary">
        <ion-button (click)="dismiss()" >
            <ion-icon name="close-outline"></ion-icon>
        </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="dialog">
  <!--<ag-loading [loading]="saving" message="{{text.general.saving | translate}}" (retry)="saveSettings()"></ag-loading>-->
  <div class="dialog-background"></div>
  
  <div class="dialog-content">
    
    <ion-list *ngIf="loading.is_success()">
      <ag-loading [loading]="loading" message=""></ag-loading>
      <ion-item >
        <ion-label>{{text.general.select_crop | translate}}</ion-label>
        <!-- [(ngModel)]="settings.crop_type"  -->
        <ion-select (ionChange)="onSelectCrop($event)" interface="popover" ok-text="Okay" cancel-text="Cancel">
            <ion-select-option [hidden]="true" value=""></ion-select-option>
            <ion-select-option *ngFor="let crop of crops" [value]="crop.key">{{crop.title|translate}}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item *ngFor="let crop of displayCrops">
        <ion-icon (click)="removeCalibration(crop.crop.key)" name="trash-outline"></ion-icon>
        <ion-label>{{crop.crop.title | translate}}</ion-label>
        <ion-input type='number' (ionChange)="onCalibrationEdited(crop.crop.key)" [(ngModel)]="crop.value"></ion-input>
      </ion-item>
    </ion-list>
  </div>

</ion-content>
  `,
  styles: [`

  `]
})
export class CropCallibrationComponent {
  constructor (
    private router: Router,
    private auth: AuthService, 
    private telemetry: TelemetryService,
    private attributeService: AttributeService,
    private dialogRef: ModalController
  ) {}
  text = TEXT
  showRedirect = false
  calibrations: ICropCalibration | null = {crops: {}}
  crops = ListCrops()
  cropIndex: Index<ICrop> = {}
  telemetrySubscription: TimeseriesSubsciption | null = null
  
  entity: EntityId<any>
  displayCrops: {crop: ICrop, value: number, edited: boolean}[] = []

  loading = new LoadingState()

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { 
    if (this.telemetrySubscription) {
      this.telemetrySubscription.unsubscribe()
    }
    this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] 
    this.displayCrops = []
    this.calibrations = {crops: {}}
    this.loading.loading(true)
  }


  ngOnInit () {
    //console.log('init crop calibrations')
    this.cropIndex = {}
    for (var crop of this.crops) {
      this.cropIndex[crop.key] = crop
    }
    this.listen(
      this.auth.getAuth().subscribe(auth => {
        this.entity = auth.userDetails.id
        this.telemetrySubscription = this.telemetry.latest(this.entity, {
          keys: [CalibrationKey]
        }, {
          onData: (resp) => {
            let value = resp.data[CalibrationKey]
            if (value != undefined) {
              try {
                this.onCropCalibration(
                  JSON.parse(value[0][1])
                )
              } catch (err) {
                this.onCropCalibration(null)
              }
            }
            return true
          },
          onLoading: (x) => 0
        })
      })
    )
  }

  onCropCalibration (value: ICropCalibration | null) {
    //console.log('on crop calibration', value)
    if (value == null) {
      this.calibrations = {crops: {}}
    } else {
      this.calibrations = value
    }
    for (var key in this.calibrations.crops) {
      let crop = this.crops.find(x => x.key == key)
      if (!crop) continue
      let value = this.calibrations.crops[key]
      let displayCrop = this.displayCrops.find(x => x.crop.key == key)
      if (displayCrop && !displayCrop.edited) {
        displayCrop.value = value
      } else if (displayCrop == undefined) {
        this.displayCrops.push({
          crop: crop, edited: false, value: value
        })
      }
    }
    this._update()
    this.loading.success()
  }

  onSelectCrop (evt) {
    let crop = this.crops.find(x => x.key == evt.detail.value)
    //console.log('onSelectCrop', crop)
    if (crop) {
      let displayCrop = this.displayCrops.find(x => x.crop.key == crop.key)
      if (displayCrop != undefined) return console.warn('crop already exist: ' + displayCrop.crop.key)
      this.displayCrops.push({
        crop: crop, edited: true, value: 0
      })
      this.save()
    }

    evt.target.value = ''
    this._update()
  }

  _update () {
    let allKeys = this.displayCrops.map(x => x.crop.key)
    this.crops = ListCrops().filter(x => {
      return !allKeys.includes(x.key)
    })
    this.displayCrops.sort((a,b) => a.crop.placement - b.crop.placement)
  }

  onCalibrationEdited (key: string) {
    //console.log('on calibration edited', key)
    let displayCrop = this.displayCrops.find(x => x.crop.key == key)
    if (displayCrop) {
      displayCrop.edited = true
      this.save()
    }
  }

  removeCalibration (key: string) {
    this.displayCrops = this.displayCrops.filter(x => x.crop.key != key)
    this._update()
    this.save()
  }

  async save () {
    let cropValues: Index<number> = {}
    for (var crop of this.displayCrops) {
      cropValues[crop.crop.key] = crop.value
    }
    let value: ICropCalibration = {crops: cropValues}
    //console.log('save', value)
    await this.attributeService.saveEntityTimeseries(
      this.entity, 'scope', [{
        key: CalibrationKey, value: JSON.stringify(value)
      }]
    ).toPromise()
  }
  dismiss () {
    this.dialogRef.dismiss()
  }
}
