import { Injectable, NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { LoginFormComponent } from './components/login-page/login-page';
import { FarmerDeviceDetailComponent, FarmerDevicesComponent } from './components/farmer-devices/farmer-devices';
import { AlarmPageComponent } from './components/alarm-page/alarm-page';
import { DevicesMapPage } from './components/device-map/devices-map';
import { MeasurementMapComponent } from './components/super-pro/super-pro';
import { BillingPage } from './components/login-page/billing';
import { FarmerBuildingDevicesPage, FarmerBuildingPage } from './components/farmer-building/farmer-building';
import { FarmerSiloPage } from './components/farmer-silo/farmer-silo';
import { FarmerSiloHistoryPage } from './components/farmer-silo/farmer-silo-history';
import { FarmerBuildingHistoryPage } from './components/farmer-building/farmer-building-history';
import { DeviceChart } from './components/farmer-devices/farmer-device-chart';
import { CustomerActivateComponent, ResetPasswordComponent } from './components/login-page/activate-user';
import { AppState } from './state';
import { Store } from '@ngrx/store';
import { onAuthenticationChange } from './util';
import { AdminPage } from './app.component';
import { DeleteAccountPageComponent } from './components/login-page/delete-account-page';
import { CropCallibrationComponent } from './components/login-page/callibration';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  isActive = false
  constructor(
    private router: Router,
    private store: Store<AppState>
  ) {
    onAuthenticationChange(this.store).subscribe(x =>
      this.isActive = x
    )
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.isActive) {
      const returnUrl = state.url;
      this.router.navigate(['/auth'], { queryParams: { returnUrl: returnUrl } });
      //this.router.navigateByUrl('/auth')
    }
    return this.isActive
  }
}


@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {
  isActive = false
  constructor(
    private router: Router,
    private store: Store<AppState>
  ) {
    onAuthenticationChange(this.store).subscribe(x =>
      this.isActive = !x
    )
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.isActive) {
      this.router.navigateByUrl('/')
    }
    return this.isActive
  }
}

const routes: Routes = [
  {
    path: '_admin',
    component: AdminPage,
    canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
  },
  {
    path: 'activate',
    component: CustomerActivateComponent,
    canActivate: [NoAuthGuard], runGuardsAndResolvers: 'always'
  },
  {
    path: 'activateUser',
    component: CustomerActivateComponent,
    canActivate: [NoAuthGuard], runGuardsAndResolvers: 'always'
  },
  {
    path: 'reset',
    component: ResetPasswordComponent,
    canActivate: [NoAuthGuard], runGuardsAndResolvers: 'always'
  },
  {
    path: 'auth',
    component: LoginFormComponent,
    canActivate: [NoAuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: '',
    component: FarmerDevicesComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'delete-account',
    component: DeleteAccountPageComponent,
    canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
  },
  {
    path: 'crop-calibrations',
    component: CropCallibrationComponent,
    canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
  },
  {
    path: 'billing',
    component: BillingPage,
    canActivate: [AuthGuard], runGuardsAndResolvers: 'always'
  },
  {
    path: 'alarms', canActivate: [AuthGuard],
    component: AlarmPageComponent, runGuardsAndResolvers: 'always'
  },
  {
    path: 'map', canActivate: [AuthGuard],
    component: DevicesMapPage, runGuardsAndResolvers: 'always'
  },
  {
    path: 'device/:entityId', canActivate: [AuthGuard],
    component: FarmerDeviceDetailComponent, runGuardsAndResolvers: 'always'
  },
  {
    path: 'device/:entityId/map', canActivate: [AuthGuard],
    component: MeasurementMapComponent, runGuardsAndResolvers: 'always'
  },
  {
    path: 'device/:entityId/chart', canActivate: [AuthGuard],
    component: DeviceChart, runGuardsAndResolvers: 'always'
  },
  {
    path: 'building/:entityId', canActivate: [AuthGuard],
    component: FarmerBuildingPage, runGuardsAndResolvers: 'always'
  },
  {
    path: 'silo/:entityId', canActivate: [AuthGuard],
    component: FarmerSiloPage, runGuardsAndResolvers: 'always'
  },
  {
    path: 'silo/:entityId/history', canActivate: [AuthGuard],
    component: FarmerSiloHistoryPage, runGuardsAndResolvers: 'always'
  },
  {
    path: 'building/:entityId/devices', canActivate: [AuthGuard],
    component: FarmerBuildingDevicesPage, runGuardsAndResolvers: 'always'
  },
  {
    path: 'building/:entityId/history', canActivate: [AuthGuard],
    component: FarmerBuildingHistoryPage, runGuardsAndResolvers: 'always'
  }//, { path: '**', redirectTo: ''}
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
  ) {
  }


}
