<div class="cellular-device-overview" style="position: relative;" fxLayout="column">
  <div *ngIf="device && showData && !loading" class="flex-auto" fxFlex fxLayout="column">
    
    <div *ngIf="isPaid == false" class="ag-card flex-auto" style="padding: 10px">
      {{text.device.no_subscription|translate}}
      <button [translate]="text.general.checkout" (click)="checkout()"></button>
    </div>

    <div *ngIf="hasBuilding" (click)="openBuildingPage()" fxLayout="column" class="ag-card stats-container">
      {{building.entity.label}}
    </div>

    <div *ngIf="isPaid == true" fxLayout="column">
      <entity-alarm-group *ngIf="alarmItems.length > 0" [alarms]="alarmItems" name="{{text.general.alarms|translate}}"></entity-alarm-group>
      
      <div *ngFor="let sensor of sensors; trackBy:sensorKey" fxLayout="column" class="ag-card stats-container">
        <!--<div fxFlex class="tb-widget-loading" [hidden]="!temperaturesLoading" fxLayout="column" fxLayoutAlign="center center">
        </div>-->
        <div fxFlex fxLayout="row" class="flex-auto">
          <div class="ag-value-header" fxFlex>{{sensor.title | translate}}</div>
          <div style="font-size: 12px;"><ag-time-since [ts]="sensor.latestTs"></ag-time-since></div>
        </div>
        <div class="ag-values-row flex-auto" fxFlex fxLayout="row">
          <div *ngIf="sensor.isTemperatureType" class="ag-value-row" fxFlex fxLayout="row" fxLayoutAlign="start center" > 
            <ag-sensor-value type='TEMPERATURE' [value]="sensor.temperatureData?.value" ></ag-sensor-value>
          </div>
            
          <div *ngIf="sensor.isMoistureType" class="ag-value-row" fxFlex fxLayout="row" fxLayoutAlign="start center">
            <ag-sensor-value *ngIf="alwaysHumidity && sensor.key == 'ambient'" type='MOISTURE' [value]="sensor.humidityData?.value"></ag-sensor-value>
            <ag-sensor-value *ngIf="!alwaysHumidity || sensor.key != 'ambient'" [cropType]="sensor.cropType" type='MOISTURE' [value]="sensor.moistureData?.value" ></ag-sensor-value>
          </div>
        </div>
      </div>
      <div *ngFor="let chart of charts; trackBy:chartKey" id="cellular-chart-card" [class]="{'ag-plot-zoom': plotZoom, 'ag-plot-no-zoom': !plotZoom}" class="ag-card" fxLayout="column"> 
        <div class="md-padding chart-heading" [translate]="chart.name"></div>
        <ion-icon name="expand-outline" (click)="showChart()" style="position: absolute; right: 20px; top: 20px;"></ion-icon>
        <div fxFlex class="temp-history-container md-padding">
          <ag-plot [height]="260" [figures]="chart.figures" [entity]="device.id" [axis]="chart.axes" [timeseries]="chart.timeseries"></ag-plot>
        </div>
        <div class='ag-plot-bottom'>
          <div class="ag-plot-legend">
            <div class="ag-legend-item" *ngFor="let ts of chart.timeseries">
              <div class="ag-legend-icon" [style.background]="ts.color"></div>
              <div class="ag-legend-value">{{ts.label}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ag-card" style="height: 300px;">
      <devices-map [devices]="[device]" ></devices-map>
    </div>

    <div fxLayout="column" class="ag-card stats-container flex-auto">
      <div class="ag-values-row flex-auto" style="margin: 0;" fxFlex fxLayout="row">
        <!-- battery widget is deprecated
        <div class="ag-value-row" style="margin-left: 10px; margin-right: 100px;" fxFlex fxLayout="row" fxLayoutAlign="start center" >
          <div style="width: 50px;">
            <battery-level [percent]="ctrl.battery_level"></battery-level>
          </div> 
          <div fxLayout="column" class="ag-value-section">
            <span style="font-size: 20px;" class="ag-value">{{levelLabel(ctrl.battery_level)}}</span> 
            <div class="ag-value-label" [translate]="text.general.battery"></div>
          </div>
        </div>
        -->
        <div class="ag-value-row flex-auto" fxFlex fxLayout="row" fxLayoutAlign="start center" >
          <div style="width: 35px;">
            <signal-strength [value]="signalValue"></signal-strength>
          </div>
          <div fxLayout="column" class="ag-value-section">
            <!--<span style="font-size: 20px;" class="ag-value">{{levelLabel(signalValue.percent)}}</span>-->
            <span style="font-size: 16px;" class="ag-value" [translate]="text.general.signal"></span>
            <div class="ag-value-label" [translate]="signalValue.name"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div style="height: 100px"></div>
</div>
