import { EventEmitter } from "@angular/core"
import { Subscription } from "rxjs"
import { agTypes } from "src/app/ag-types"
import { CROPS } from "src/app/constant"
import { Asset, Device, EntityId, EntityType } from "src/app/models/entity.model"
import { ITelemetryValue } from "src/app/models/telemetry.model"
import { FarmerService } from "src/app/services/device.service"
import { TimeseriesSubsciption } from "src/app/services/telemetry"
import { ISensorType, ISiloCableSettings, ISiloSettings, MOISTURE_SENSOR, TEMPERATURE_SENSOR } from "src/app/services/types.service"
import { clone, Index, isSensorLinesDevice, isTMSDevice } from "src/app/util"
import { ICableMarker } from "./farmer-cable-placement"

interface IRelation<T extends EntityType> {
  to: EntityId<T>
}

interface ISignalValue {
  value: number, scale: number, name: string
}

export class SiloTMSGateway {
  constructor (public farmer: FarmerService, public entity: Device) {
    this.model.load()
  }
  onModelUpdate () {}

  silo: FarmerSilo
  model = this.farmer.createModel(this.farmer.types.tsmGateway, this.entity)
  
  get settings () { return this.model.settings }
  get battery_level () { return this.model.settings.battery_level }
  get signal_strength () { return this.model.settings.signal_strength }

  get telemetry () { return this.farmer.telemetry }
  get attributeService () { return this.farmer.attributeService }
  get entityService () { return this.farmer.entityService }

  set_entity (device: Device) {}

  getValues() { return [] }


  get latestTs () {
    return this.model.getLatestTs(['battery_level', 'signal_strength'])
  }

  get signal_value () {
    let x = this.signal_strength
    let s = x + 2
    if (s < 2) {
      return {value: s, scale: 0, name: 'No Signal'}
    } else if (s >= 2 && s <= 9) {
      return {value: s, scale: 1, name: 'Marginal'}
    } else if (s >= 10 && s <= 14) {
      return {value: s, scale: 2, name: 'OK'}
    } else if (s >= 15 && s <= 19) {
      return {value: s, scale: 3, name: 'Good'}
    } else if (s >= 20) {
      return {value: s, scale: 4, name: 'Excellent'}
    }
  }
  
}

export interface IBuildingState {
  settings: ISiloSettings, devices: Device[]
}

export class FarmerSilo {
  updated = new EventEmitter<this>()
  onLoaded = new EventEmitter<this>()
  devices: Device[] = []
  gateway: SiloTMSGateway

  model = this.farmer.createModel(this.farmer.types.silo, this.entity)

  get telemetry () { return this.farmer.telemetry }
  get http () { return this.farmer.http }
  get attributeService () { return this.farmer.attributeService }
  get entityService () { return this.farmer.entityService }

  get settings () { return this.model.settings }
  
  get label () { return this.asset.label }
  get loaded () { return this.isLoaded() }
  get entity () { return this.asset }

  constructor (private farmer: FarmerService, public asset: Asset) {
    this.model.onUpdate.subscribe(e => {
      //console.log('building silo updated', e)
      this.onModelUpdate(e)
    })
    this.farmer.subscribe(() => {
      this.load()
    })
    this.model.load()
  }

  getValues () { 
    return this.model.getValues([
      'current_min_temperature', 'current_avg_temperature', 'current_max_temperature',
      'current_min_emc', 'current_avg_emc', 'current_max_emc'
    ]) 
  }

  latestTemperatureTs: number | null = null
  latestMoistureTs: number | null = null

  set_entity (entity: Asset) {
    this.asset = entity
    /*Object.assign(this.settings, {
      name: this.asset.name, label: this.asset.label
    })*/
  }

  subscribe (cb: ()=>void) {
    if (this.loaded) cb()
    return this.updated.subscribe(cb)
  }

  temperatureKeys () {
    let keys = ['current_min_temperature', 'current_avg_temperature', 'current_max_temperature']
    return keys.map(k => this.model.attributes.get(k))
  }
  moistureKeys () {
    let keys = ['current_min_emc', 'current_avg_emc', 'current_max_emc']
    return keys.map(k => this.model.attributes.get(k))
  }

  isValueValid (value: ITelemetryValue<number>) {
    let now = (new Date().getTime() + new Date().getTimezoneOffset()*60*1000);
    let maxAge = now - (24 * 60 * 60 * 1000) // one day
    return value != undefined && value.value != undefined && typeof value.value == 'number' 
      && value.value > -99 && value.value < 99 && value.ts > maxAge
  }

  createState (): IBuildingState {
    return {settings: clone(this.settings), devices: this.devices.slice(0)}
  }

  onModelUpdate (settings: Partial<ISiloSettings>) {
    //console.log('building settings updated', settings)
    this.latestTemperatureTs = this.model.getLatestTs([
      'current_min_temperature', 'current_avg_temperature', 'current_max_temperature'
    ])
    this.latestMoistureTs = this.model.getLatestTs([
      'current_min_emc', 'current_avg_emc', 'current_max_emc'
    ])
    this.updated.emit(this)
  }

  async saveSettings (settings: Partial<ISiloSettings>) {
    //console.log('save silo settings', settings)
    await this.model.saveSettings(settings)
    this.updated.emit(this)
  }

  hasTemperature () {
    return this.getCables().find(x => x.isTemperature() || x.isMoisture()) != undefined
  }
  hasMoisture () {
    return this.getCables().find(x => x.isMoisture()) != undefined
  }

  getSensorTypes () {
    let types: ISensorType[] = []
    if (this.hasTemperature()) types.push(TEMPERATURE_SENSOR)
    if (this.hasMoisture()) types.push(MOISTURE_SENSOR)
    return types
  }

  getCables () {
    return this.devices.map(d => this.farmer.getSiloCable(d))
  }

  // TODO: this is a problem, that loading-state depends on farmer loading state
  //  not sure it's worth fixing for now
  isLoaded () { 
    return this.farmer.isLoaded() && this.model.isLoaded() && this.getCables().every(x => x.isLoaded())
  }

  async load () {
    this.devices = []
    let devices = this.farmer.getBuildingDevices(this.asset)
    for (var device of devices) {
      if (isSensorLinesDevice(device.type)) {
        this.addSensorLine(device)
      } else if (isTMSDevice(device.type)) {
        this.gateway = this.farmer.getTMSGateway(device)
        //this.addDevice(device)
      }
    }
    this.onLoaded.emit(this)
  }

  _subscriptions: Index<Subscription> = {}
  addSensorLine (device: Device) {
    this.devices.push(device)
    let ctrl = this.farmer.getSiloCable(device)
    //ctrl.setBuildingModel(this.model)
    ctrl.load()
    //await ctrl.waitLoaded()
    if (this._subscriptions[device.id.id]) {
      this._subscriptions[device.id.id].unsubscribe()
    }
    this._subscriptions[device.id.id] = ctrl.updated.subscribe(e => {
      //console.log('silo device updated', e)
      this.updated.emit(this)
    })
    /*this._subscriptions[device.id.id + '-data'] = ctrl.onDataUpdate.subscribe(e => {
      this.updated.emit(this)
    })*/
    this.updated.emit(this)
  }

  async getDeviceRelations () {
    let url = `/api/relations/info?fromId=${this.asset.id.id}&fromType=ASSET`
    return await this.http.get<IRelation<EntityType.DEVICE>[]>(url).toPromise()
  }

  async createRelationTo (device: Device) {
    //console.log('create relation to', device)
    let url = '/api/relation'
    await this.http.post(url, {
      additionalInfo: "",
      from: this.asset.id,
      to: device.id,
      type: 'Contains',
      typeGroup: "COMMON"
    }).toPromise()
  }
  async removeRelationTo (entityId: EntityId<EntityType.DEVICE>) {
    //console.log('remove relation to', entityId)
    let url = '/api/relation?relationTypeGroup=COMMON'
    url += '&fromId=' + this.asset.id.id
    url += '&fromType=' + this.asset.id.entityType 
    url += '&relationType=Contains'
    url += '&toId=' + entityId.id 
    url += '&toType=' + entityId.entityType
    await this.http.delete(url).toPromise()
  }
  
  async saveMarkers (markers: ICableMarker[]) {
    // TODO: create relation operations should run in farmer-service
    let newDevices: Device[] = []
    for (let marker of markers) {
      let device = this.devices.find(x => x.id.id == marker.device.id.id)
      if (!device) {
        await this.createRelationTo(marker.device)
        device = marker.device
      }
      newDevices.push(device)
      let ctrl = this.farmer.getSiloCable(device)
      await ctrl.saveSettings({pos_x: marker.realPos.x, pos_y: marker.realPos.y})
    }
    //console.log('newDevices', newDevices)
    for (var device of this.devices) {
      let hasDevice = newDevices.find(x => x.id.id == device.id.id) != undefined
      if (!hasDevice) {
        await this.removeRelationTo(device.id)
      }
    }
    this.devices = newDevices
    //await this.load()
    await this.farmer.reload()
    //console.log('saved building markers')
    this.updated.emit(this)
  }
}





export class FarmerSiloCable {
  model = this.farmer.createModel(this.farmer.types.cable, this.device)
  state = 'INITIAL'

  get settings () { return this.model.settings }
  
  get telemetry () { return this.farmer.telemetry }
  get http () { return this.farmer.http }
  get attributeService () { return this.farmer.attributeService }
  get entityService () { return this.farmer.entityService }
  get loaded () { return this.isLoaded() }

  updated = new EventEmitter<this>()
  onLoaded = new EventEmitter<this>()

  constructor (private farmer: FarmerService, public device: Device) {
    this.model.onUpdate.subscribe(e => {
      //console.log('building silo updated', e)
      this.onModelUpdate(e)
    })
    this.farmer.subscribe(() => {
      this.load()
    })
    this.model.load()
  }

  isMoisture () { return this.device.type == agTypes.farmerDeviceTypes.slmCable.value }
  isTemperature () { return this.device.type == agTypes.farmerDeviceTypes.sltCable.value }

  shortName () {
    let id = this.settings.uid
    if (id) {
      let prefix = this.isMoisture() ? 'M' : 'T'
      return prefix + id
    }
  }

  isValueValid (value: ITelemetryValue<number>) {
    let now = (new Date().getTime() + new Date().getTimezoneOffset()*60*1000);
    let maxAge = now - (24 * 60 * 60 * 1000) // one day
    return value != undefined && value.value != undefined && typeof value.value == 'number' 
      && value.value > -99 && value.value < 99 && value.ts > maxAge
  }

  onModelUpdate (settings: Partial<ISiloCableSettings>) {
    this.subscribeLatest()
    this.updated.emit(this)
  }

  async saveSettings (settings: Partial<ISiloCableSettings>) {
    await this.model.saveSettings(settings)
    this.updated.emit(this)
  }

  set_entity (entity: Device) {
    this.device = entity
    Object.assign(this.settings, {
      name: this.device.name, label: this.device.label
    })
  }

  isLoaded () { return this.model.isLoaded() }

  load () {
    /*this.devices = []
    let devices = this.farmer.getBuildingDevices(this.asset)
    for (var device of devices) {
      this.addDevice(device)
    }
    this.loaded = true*/
    this.onLoaded.emit(this)
  }


  latest: Index<ITelemetryValue<number>> = {}
  latestSubscriber: TimeseriesSubsciption
  subscribeLatest () {
    if (this.latestSubscriber) {
      this.latestSubscriber.unsubscribe()
    }
    let keys = []
    for (var i=1; i<this.settings.sensor_count+1; i++) {
      keys.push(
        'temperature-' + i, 'humidity-' + i
      )
      Object.keys(CROPS).map(crop => {
        keys.push('emc-' + crop + '-' + i)
      })
    }
    //this.cropType = CropType(cropType) as CROP_TYPE
    this.latestSubscriber = this.farmer.globalContext.latest(this.device.id, {
      keys: keys
    }, {
      onData: (resp) => {
        for (var key in resp.data) {
          let data = resp.data[key]
          let ts = data[0][0]
          let value = data[0][1]
          this.latest[key] = {key: key, ts: ts, value: parseFloat(value)}
        }
        //let ts = [this.temperatureData?.ts, this.moistureData?.ts].filter(x => x != undefined)
        //this.latestTs = ts.length > 0 ? Math.max(...ts) : null
        this.updated.emit(this)
        return true
      }, 
      onLoading () {

      }
    })
    return this.latestSubscriber
  }
}

