import { EventEmitter } from "@angular/core"
import moment from "moment"
import { Subscription } from "rxjs"
import { CROP_TYPE, SENSORS } from "src/app/constant"
import { Device } from "src/app/models/entity.model"
import { ITelemetryValue } from "src/app/models/telemetry.model"
import { EntityModel, FarmerService } from "src/app/services/device.service"
import { ISensorData, ISpearSettings, SENSOR_TYPE } from "src/app/services/types.service"
import { CropType } from "src/app/util"


export class FarmerDeviceSensor {
  title: string
  constructor (
    private device: FarmerCellularSpear, 
    private entity: Device, private state: ISensorData) {
    this.title = SENSORS.find(x => x.key == state.key && x.type == state.type).name
  }

  //get title () { return getSensorTranslateKey(this.state) }
  get type () { return this.state.type }

  get key () { return this.state.key }
  get name () { return this.state.name }
  get farmer () { return this.device.farmer }
  
  emcKey (crop: CROP_TYPE) {
    return 'emc-' + crop.replace(/\s/g, '_').toLowerCase() + '-' + this.key
  }
  moistureKey () {
    return this.cropType ? this.emcKey(this.cropType) : 'humidity-' + this.key
  }
  temperatureKey () {
    return 'temperature-' + this.key
  }
  humidityKey () {
    return 'humidity-' + this.key
  }

  get sensorType () { return this.state.type }
  hasSensorType (type: SENSOR_TYPE) {
    if (this.hasTemperature() && (type == SENSOR_TYPE.TEMP || type == SENSOR_TYPE.TEMP_AND_HUM))
      return true
    else if (this.hasMoisture() && (type == SENSOR_TYPE.HUM || type == SENSOR_TYPE.TEMP_AND_HUM))
      return true
    return false
  }
  
  // TODO: try remove these two function, use "isTemperatureType" instead
  hasTemperature () {
    return this.state.type == SENSOR_TYPE.TEMP_AND_HUM || this.state.type == SENSOR_TYPE.TEMP
  }
  hasMoisture () {
    return this.state.type == SENSOR_TYPE.HUM || this.state.type == SENSOR_TYPE.TEMP_AND_HUM
  }


  isTemperatureType = false
  isMoistureType = false

  latestSubscriber
  latestTs: number | null = null
  cropType: CROP_TYPE | null = null
  temperatureData: ITelemetryValue<number>
  moistureData: ITelemetryValue<number>
  humidityData: ITelemetryValue<number>
  loaded = false

  getValues () {
    let result: ITelemetryValue<any>[] = []
    if (this.temperatureData) result.push(this.temperatureData)
    if (this.moistureData) result.push(this.moistureData)
    return result
  }

  onDataUpdate = new EventEmitter<FarmerDeviceSensor>()

  dataUpdated () {
    this.onDataUpdate.emit()
    this.device.dataUpdated()
  }

  updateCropType (cropType: CROP_TYPE | null) {
    this.cropType = CropType(cropType) as CROP_TYPE
    let tempKey = this.temperatureKey()
    let moistureKey = this.moistureKey()
    let humidityKey = this.humidityKey()
    // NOTE: always include humidity to support showing ambient as humidity
    let keys = [tempKey, moistureKey, humidityKey] 

    //console.log('update crop type', keys)
    if (this.latestSubscriber) {
      this.latestSubscriber.unsubscribe()
    }
    
    this.latestSubscriber = this.farmer.globalContext.latest(this.entity.id, {
      keys: keys
    }, {
      onData: (resp) => {
        for (var key in resp.data) {
          let data = resp.data[key]
          let ts = data[0][0]
          let value = data[0][1]
          if (key == tempKey) {
            if (value != undefined)
              this.temperatureData = {key: tempKey, ts: ts, value: parseFloat(value)}
          } 
          if (key == moistureKey) {
            if (value != undefined)
              this.moistureData = {key: moistureKey, ts: ts, value: parseFloat(value)}
          } 
          if (key == humidityKey) {
            this.humidityData = {key: humidityKey, ts: ts, value: parseFloat(value)}
          }
        }
        //console.log('received new telemetry', resp.data)
        let ts = [this.temperatureData?.ts, this.moistureData?.ts].filter(x => x != undefined)
        //console.log('latest ts', this.entity.name, moment(ts).format(), ts, resp.data)
        this.latestTs = ts.length > 0 ? Math.max(...ts) : null
        this.isTemperatureType = this.hasTemperature()
        this.isMoistureType = this.hasMoisture()
        //console.log('latest value', this.state, resp)
        this.dataUpdated()
        return true
      }, 
      onLoading (isLoading) {
        this.loaded = !isLoading
      }
    })
    //console.log('update crop')
    this.dataUpdated()
    return this.latestSubscriber
  }
}



export class FarmerCellularSpear {
  onUpdate = new EventEmitter<FarmerCellularSpear>()
  onDataUpdate = new EventEmitter<FarmerCellularSpear>()
  onLoaded = new EventEmitter<this>()

  // new EntityModel(this, this.entity, SpearAttributes)
  model = this.farmer.createModel(this.farmer.types.celluarSpear, this.entity)
  

  public sensors: FarmerDeviceSensor[] = []

  async getQrValue () {
    return await this.model.fetchQrValue()
  }

  getValues (): ITelemetryValue<number>[] {
    if (!this.sensors || !this.sensors.length) return []
    return this.sensors[0].getValues()
  }

  get settings () { return this.model.settings }

  get battery_level () { return this.settings.battery_level }
  get signal_strength () { return this.settings.signal_strength }
  
  constructor (
    public farmer: FarmerService, public entity: Device) {
      this.onEntityUpdated(entity)
      this.model.onUpdate.subscribe(e => {
        this.onAttributesUpdated(e)
      })
      this.model.load()
    }

  onBuildingUpdated () {

  }

  subscribe (cb: ()=>void) {
    cb()
    return this.onUpdate.subscribe(cb)
  }

  set_entity (entity: Device) {
    this.entity = entity
    /*Object.assign(this.settings, {
      name: this.entity.name, label: this.entity.label
    })*/
  }

  async saveSettings (settings: Partial<ISpearSettings>) {
    await this.model.saveSettings(settings)
  }

  // TODO: typescript for buildingModel
  public buildingModel: EntityModel<any,any> | null = null
  buildingSubscription: Subscription
  setBuildingModel (model: EntityModel<any,any> | null) {
    if (this.buildingSubscription) {
      this.buildingSubscription.unsubscribe()
    }
    this.buildingModel = model
    if (this.buildingModel) {
      this.buildingSubscription = this.buildingModel.onUpdate.subscribe(e => {
        this.onAttributesUpdated({crop_type: e.crop_type})
        // TODO: this fixes a bug there the devices in a building will not update if building changes crop-type
        this.model.onUpdate.emit(e)
      })
    }
    this.updateCropType()
  }

  dataUpdated () {
    this.onDataUpdate.emit(this)
  }

  onEntityUpdated (entity: Device) {
    this.entity = entity
    /*Object.assign(this.settings, {
      name: this.entity.name, label: this.entity.label
    })*/
    this.onUpdate.emit(this)
  }

  onAttributesUpdated (updated: Partial<ISpearSettings>) {
    if (Object.keys(updated).length > 0) {
      //console.log('spear model updated', this.entity.name, updated)
      if (updated.sensors != undefined)
        this.updateSensors()
      else if (updated.crop_type != undefined)
        this.updateCropType()
      this.onUpdate.emit(this)
    }
  }
  
  isLoaded () { return this.model.loading.is_success() }
  async waitLoaded () {
    if (this.sensors.length > 0) return
    return new Promise<void>((resolve, reject) => {
      let sub = this.onUpdate.subscribe(e => {
        if (this.sensors.length > 0 && this.model.loading.is_complete()) {
          sub.unsubscribe()
          if (this.model.loading.is_failed()) reject()
          else resolve()
        }
      })
    })
  }

  load () {
    return this.onUpdate
  }

  get id () { return this.entity.id }

  updateCropType () {
    //console.log('update spear crop type', this.settings.crop_type, this.buildingModel?.settings.crop_type)
    this.sensors.map(sensor => {
      if (this.buildingModel) {
        sensor.updateCropType(this.buildingModel.settings.crop_type)
      } else {
        sensor.updateCropType(this.settings.crop_type)
      }
    })
    //this.onUpdate.emit(this)
  }

  updateSensors () {
    let sensors = this.settings.sensors
    this.sensors = sensors.map(sensor => {
      return this.makeSensor(sensor)
    })
    this.updateCropType()
  }

  hasTemperature () {
    return this.sensors.find(x => x.hasTemperature()) != undefined
  }
  hasMoisture () {
    return this.sensors.find(x => x.hasMoisture()) != undefined
  }

  private makeSensor (data: ISensorData) {
    let sensor = this.sensors.find(s => s.key == data.key)
    if (sensor) return sensor
    if (!data.type) data.type = SENSOR_TYPE.TEMP_AND_HUM
    //let sensor_data = SENSORS.find(x => x.key == data.key && x.type == data.type)
    //console.error('FOUND SENSOR', sensor_data)
    return new FarmerDeviceSensor(this, this.entity, data)
  }
  

}

