import { agTypes } from '../../ag-types'
import { AgModal, ALL_HOURS, clone, DAY, formatSinceNow, indexColor, IRangeValue, ITimeInterval, ListCrops, LoadingState } from '../../util'

import { Component, Input } from "@angular/core";
import { AGUserService } from 'src/app/services/user.services';
import { UnitService } from 'src/app/services/unit';
import { Device, IAlarm } from 'src/app/models/entity.model';
import { TelemetryService } from 'src/app/services/telemetry';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, MenuController, ModalController, PopoverController } from '@ionic/angular';
import { AssetCommentsComponent } from '../asset-comments/asset-comments';
import { FarmerService } from 'src/app/services/device.service';
import { TimeQuerySettings } from 'src/app/menus';
import { IChartInfo, IFlotAxisOptions } from '../ag-plot/ag-plot';
import { ISensorData, ISpearSettings } from 'src/app/services/types.service';
import { FarmerCellularSpear, FarmerDeviceSensor } from './spear.model';
import { getSignalValue, ISignalValue } from '../base-components';
import { Subscription } from 'rxjs';
import { PaymentService } from 'src/app/services/payment.service';

import { isEqual } from 'lodash-es'
import * as omitDeep from 'omit-deep-lodash'
import { captureException } from '@sentry/angular';
import { AlarmService, IAlarmItem } from 'src/app/services/alarm.service';
import { createAlarmGroups, IAlarmGroup } from '../alarm-page/alarm-page';
import { TEXT } from 'src/app/texts';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../login-page/profile-page';
//const omitDeep = require("omit-deep-lodash");

type SPEAR_PAGE = 'OVERVIEW' | 'SETTINGS' | 'COMMENTS'


@Component({
  selector: 'spear-page',
  template: `<ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button defaultHref="/"></ion-back-button>
      </ion-buttons>
      <ion-title>{{title}}</ion-title>
      <ion-buttons slot="primary">
        <ion-button (click)="openTimeWindowMenu($event)">
          <ion-icon slot="icon-only" name="time-outline" ></ion-icon>
        </ion-button>
        <ion-button (click)="openSettings()" >
          <ion-icon slot="icon-only" name="settings-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content id="spear-content">
    <spear-overview *ngIf="page == 'OVERVIEW'" [device]="device"></spear-overview>
  </ion-content>`,
  styleUrls: ['spear-overview.scss']
})
export class SpearPageComponent {
  @Input() device: Device
  
  page: SPEAR_PAGE = 'OVERVIEW'
  editSettings: ISpearSettings

  title: string

  constructor (
    public menu: MenuController,
    private farmer: FarmerService,
    private telemetry: TelemetryService,
    private modalController: ModalController,
    private router: Router, 
    public popoverController: PopoverController,
    private route: ActivatedRoute,
    private agModal: AgModal
  ) {}

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }
  
  ngOnInit () {
    this.listen(this.route.queryParams.subscribe(params => {
      if (params['p'])
        this.page = params['p'];
    }));
    let ctrl = this.farmer.getCellularSpear(this.device)
    this.listen(ctrl.load().subscribe(() => {
      //console.warn('TITLE CHANGED', ctrl.settings)
      this.title = ctrl.settings.label
    }))
    this.title = ctrl.settings.label
  }
  

  hasBuilding () {
    let ctrl = this.farmer.getCellularSpear(this.device)
    //console.log('has building', ctrl.buildingModel, ctrl)
    return ctrl.buildingModel != null
  }

  async openComments () {
    const dialogRef = await this.modalController.create({
      component: AssetCommentsComponent, componentProps: {asset: this.device}
    });
    const { data } = await this.agModal.openModal(dialogRef)
  }

  async openSettings () {
    this.editSettings = clone(this.farmer.getCellularSpear(this.device).settings)
    
    const dialogRef = await this.modalController.create({
      component: SpearSettingsComponent,
      componentProps: {settings: this.editSettings, entity: this.device}
    });
    const { data } = await this.agModal.openModal(dialogRef)
  }

  async openTimeWindowMenu (ev) {
    let popover = await this.popoverController.create({
      component: TimeQuerySettings,
      event: ev,
      translucent: true
    });
    return popover.present();
  }

  isSpear (deviceType: string) {
      return deviceType == agTypes.farmerDeviceTypes.cellularSensorSpear.value
  }
  isOverviewPage () { return this.page == 'OVERVIEW' }
  isSettingsPage () { return this.page == 'SETTINGS' }
  navigate (page: SPEAR_PAGE) {
    this.page = page
    this.router.navigate([], {queryParams: {p: page}, relativeTo: this.route})
  }
}


@Component({
  selector: 'spear-settings',
  templateUrl: 'spear-settings.html',
  styleUrls: ['spear-overview.scss']
})
export class SpearSettingsComponent {
  @Input() entity: Device
  @Input() settings: ISpearSettings

  text = TEXT
  showQr = false
  qr = ''
  canSave: boolean = true
  saving = new LoadingState()

  sample_temperature_threshold: IRangeValue
  sample_emc_threshold: IRangeValue
  ambient_temperature_threshold: IRangeValue
  ambient_emc_threshold: IRangeValue
  crops = ListCrops()

  /*LoggingIntervals: ITimeInterval[] = [
    {minutes: 0, name: TEXT.general.no_logging},
    {minutes: 10, name: '10 [TRANSLATE ?]'},
    {minutes: 30, name: '30 min'}
  ].concat(ALL_HOURS).concat(DAY)*/
  ReportIntervals: ITimeInterval[] = [
  ].concat(ALL_HOURS).concat([DAY])

  constructor (
    private farmer: FarmerService, private dialogRef: ModalController,
    public alertController: AlertController, private route: Router,
    private payment: PaymentService, private translate: TranslateService
  ) {}

  get hasBuilding () {
    let ctrl = this.farmer.getCellularSpear(this.entity)
    return ctrl.buildingModel != null
  }
  hasMoisture () { return this.farmer.getCellularSpear(this.entity).hasMoisture() }

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }
  

  isPaid = null
  ngOnInit () {
    this.saving.success()
    this.sample_temperature_threshold = {
      lower: this.settings.sample_alarm_min_temperature, 
      upper: this.settings.sample_alarm_max_temperature
    }
    this.sample_emc_threshold = {
      lower: this.settings.sample_alarm_min_emc, 
      upper: this.settings.sample_alarm_max_emc
    }
    this.ambient_temperature_threshold = {
      lower: this.settings.ambient_alarm_min_temperature, 
      upper: this.settings.ambient_alarm_max_temperature
    }
    this.ambient_emc_threshold = {
      lower: this.settings.ambient_alarm_min_emc, 
      upper: this.settings.ambient_alarm_max_emc
    }
    this.listen(this.saving.events.subscribe(x => {
      this.canSave = !x.is_complete()
    }))

    this.setupQr()
  }
  async setupQr () {
    this.showQr = new URL(location.href).searchParams.has('qr')
    if (this.showQr)
      this.qr = await this.farmer.getCellularSpear(this.entity).getQrValue()
  }
  dismiss (data?) { this.dialogRef.dismiss(data) }

  async unclaimDevice () {
    let message = this.translate.instant(this.text.settings.confirm_remove_device)
    let unclaimPrompt = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant(this.text.settings.confirm_remove_device_title),
      message: '<strong>' + message + '</strong>',
      buttons: [
        {
          text: this.translate.instant(this.text.general.no),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: this.translate.instant(this.text.general.yes),
          handler: async () => {
            await this.farmer.unclaimDevice(this.entity)
            this.dismiss({redirect: '/'})
          }
        }
      ]
    });

    await unclaimPrompt.present();
  }

  async saveSettings () {
    this.saving.loading(true)
    try {
      this.settings.sample_alarm_min_temperature = this.sample_temperature_threshold.lower
      this.settings.sample_alarm_max_temperature = this.sample_temperature_threshold.upper
      
      this.settings.sample_alarm_min_emc = this.sample_emc_threshold.lower
      this.settings.sample_alarm_max_emc = this.sample_emc_threshold.upper
      
      this.settings.ambient_alarm_min_temperature = this.ambient_temperature_threshold.lower
      this.settings.ambient_alarm_max_temperature = this.ambient_temperature_threshold.upper

      this.settings.ambient_alarm_min_emc = this.ambient_emc_threshold.lower
      this.settings.ambient_alarm_max_emc = this.ambient_emc_threshold.upper
      await this.farmer.getCellularSpear(this.entity).saveSettings(this.settings)
      this.dialogRef.dismiss()
    } finally {
      this.saving.success()
    }
  }
}


@Component({
  selector: 'spear-overview',
  templateUrl: 'spear-overview.html',
  styleUrls: ['spear-overview.scss']
})
export class SpearOverviewComponent { 
  @Input() device: Device

  constructor (
    private farmer: FarmerService,
    private unitService: UnitService, 
    private userService: AGUserService,
    private router: Router,
    private unit: UnitService,
    private payment: PaymentService,
    private alarmService: AlarmService,
    private translate: TranslateService,
    private profile: ProfileService
    ) {}

  showChart () {
    this.router.navigateByUrl(`/device/${this.device.id.id}/chart`)
  }
  text = TEXT
  isPaid = null
  colors = agTypes.colors
  alwaysHumidity = false

  plotZoom = false
  loading = false;
  //temperaturesLoading = false;
  showData = true // this.userService.getAuthority() === 'TENANT_ADMIN' || this.userService.getAuthority() === 'CUSTOMER_USER';
  tempHistoryUnits = this.unitService.temperatureUnitSymbol();
  agTypes = agTypes;
  
  flotSettings: {smoothLines: boolean, yaxes: IFlotAxisOptions[]} = {
      smoothLines: true,
      yaxes: [
        {show: true, position: 'left'}, 
        {show: true, position: 'right', tickFormatter: (val, obj) => val.toFixed() + '%'},
        {show: true, position: 'bottom'}
      ]
  };
  legendConfig = {};
  charts: IChartInfo[] = []

  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { this._subscriptions.map(s => s.unsubscribe()); this._subscriptions = [] }

  checkout () {
    this.payment.openPaymentDialog()
  }

  get crop_type () {
    return this.ctrl.settings.crop_type
  }

  sensors: FarmerDeviceSensor[] = []
  ctrl: FarmerCellularSpear
  
  
  get building () {
    return this.ctrl.buildingModel
  }
  openBuildingPage () {
    let id = this.building.entity.id.id
    this.router.navigateByUrl('/building/' + id)
  }

  levelLabel (x?: number) {
    if (x == undefined || x == null || isNaN(x)) return '-'
    return x.toFixed() + '%'
  }

  colorize(str) {
    for (var i = 0, hash = 0; i < str.length; hash = str.charCodeAt(i++) + ((hash << 5) - hash));
    let color = Math.floor(Math.abs((Math.sin(hash) * 10000) % 1 * 16777216)).toString(16);
    return '#' + Array(6 - color.length + 1).join('0') + color;
  }

  chartKey (index: number, chart: IChartInfo) {
    return chart.name
  }
  sensorKey (index: number, sensor: FarmerDeviceSensor) {
    return sensor.type + '-' + sensor.key
  }

  signalValue: ISignalValue = {name: '-', scale: -1, value: -1}
  init () {
    //console.warn('init spear', {...this.ctrl.model.settings}, this.ctrl.sensors.slice(0))
    if (this.device) this.isPaid = this.payment.getDeviceState(this.device)?.active
    this.signalValue = getSignalValue(this.ctrl.signal_strength)
    this.sensors = this.ctrl.sensors
    let index = 0
    
    let tempChart: IChartInfo = {
      name: this.text.chart.temperature_chart_name,
      timeseries: [], axes: [ 
        {show: true, position: 'left'},
        {show: true, position: 'bottom'}
      ], figures: []
    }
    let humChart: IChartInfo = {
      name: this.text.chart.humidity_chart_name,
      timeseries: [], axes: [ 
        {show: true, position: 'left', tickFormatter: (val, obj) => val.toFixed() + '%'},
        {show: true, position: 'bottom'}
      ], figures: []
    }

    let settings = this.ctrl.settings
    tempChart.figures.push({
      y: settings.sample_alarm_max_temperature, type: 'horizontal',
      color: "#D9461D", label: 'Max Temperature Alarm'
    })
    tempChart.figures.push({
      y: settings.sample_alarm_min_temperature, type: 'horizontal',
      color: "#D9461D", label: 'Min Temperature Alarm'
    })
    humChart.figures.push({
      y: settings.sample_alarm_max_emc, type: 'horizontal',
      color: "#D9461D", label: 'Max Moisture Alarm'
    })
    humChart.figures.push({
      y: settings.sample_alarm_min_emc, type: 'horizontal',
      color: "#D9461D", label: 'Min Moisture Alarm'
    })
    
    this.sensors.map(sensor => {
      
      if (sensor.hasTemperature()) {
        tempChart.timeseries.push({
          name: sensor.temperatureKey(), label: this.translate.instant(sensor.title),
          color: indexColor(index), unit: this.unit.temperatureUnitSymbol()
        })
        index += 1
      }

      if (sensor.hasMoisture()) {
        // TODO: could this overlap somehow ?
        if (sensor.cropType) {
          humChart.name = this.text.chart.emc_chart_name
        }
        let name = sensor.moistureKey()
        let unit = this.unit.moistureUnitSymbol()
        if (sensor.key == 'ambient' && this.alwaysHumidity) {
          name = sensor.humidityKey()
          unit = this.unit.humidityUnitSymbol()
        }
        humChart.timeseries.push({
          name: name, label: this.translate.instant(sensor.title),
          color: indexColor(index), unit: unit
        })
        index += 1
      }
      
    })

    let newCharts = []
    if (tempChart.timeseries.length > 0) newCharts.push(tempChart)
    if (humChart.timeseries.length > 0) newCharts.push(humChart)
    

    let a = omitDeep(this.charts, 'id', 'entityId', 'tickFormatter')
    let b = omitDeep(newCharts, 'entityId', 'tickFormatter')
    let hasChanges = !isEqual(a, b)

    if (hasChanges) {
      /*console.log('chart data updated', hasChanges, {sensors: this.sensors, old: this.charts.slice(0), new: newCharts.slice(0)})
      console.log(JSON.stringify(a))
      console.log(JSON.stringify(b))
      console.log(JSON.stringify(a) == JSON.stringify(b))*/
      this.charts = newCharts
    } else  {} //console.warn('no chart changes', hasChanges, {sensors: this.sensors, old: this.charts.slice(0), new: newCharts.slice(0)})
  }

  //alarms: IAlarm[] = []
  alarmItems: IAlarmItem[] = []
  //alarmGroups: IAlarmGroup[] = []
  hasBuilding = false
  ngOnInit () {
    this.ctrl = this.farmer.getCellularSpear(this.device)
    this.listen(this.ctrl.subscribe(() => this.init()))
    this.listen(this.payment.subscribe(() => {
      //console.log('---spear updated', this.device, this.payment.getDeviceState(this.device))
      if (this.device) this.isPaid = this.payment.getDeviceState(this.device)?.active
      this.hasBuilding = this.ctrl.buildingModel != null
    }))
    this.listen(this.profile.settings.subscribe(profileSettings => {
      this.alwaysHumidity = profileSettings.is_ambient_humidity
      this.init()
    }))
    this.listen(
      this.alarmService.subscribe(alarms => {
        let entityAlarms = alarms.filter(x => x.originator.id == this.device.id.id)
        let groups = createAlarmGroups(this.alarmService, entityAlarms)
        this.alarmItems = groups.alarms
        //this.alarmGroups = createAlarmGroups(this.alarmService, entityAlarms).groups
      })
    )
  }
  ngOnChanges (changes) {}

  //formatTs (ts) { return formatSinceNow(ts) }
}