import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { agTypes } from "src/app/ag-types";
import { Asset, Device, IAlarm } from "src/app/models/entity.model";
import { AlarmService } from "src/app/services/alarm.service";
import { FarmerService } from "src/app/services/device.service";
import { PaymentService } from "src/app/services/payment.service";
import { ISensorData, ISpearSettings } from "src/app/services/types.service";
import { TEXT } from "src/app/texts";
import { CropLabel, deviceHasCrop, deviceIcon, deviceImage, Index, LoadingState } from "src/app/util";
import { POSITION } from "../ag-plot/ag-plot";
import { FarmerCellularSpear, FarmerDeviceSensor } from "../cellular-spear/spear.model";
import { IProfileSettings, ProfileService } from "../login-page/profile-page";

interface ISensorItem {
  model: ISensorData, title: string
}

@Component({
  selector: 'farmer-cellular-spear-item',
  template: `

<!--  class="device-dashboard-widget ag-card flex-auto"-->
<div class="card-container" *ngIf="isLoaded">
  <div fxFlex fxLayout="column" class="card-content flex-auto" > 
    <div class="device-section flex-auto" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex fxLayout="column" class="card-header-label">
        <div class="device-label">{{name}}</div>
      </div>
      <div *ngIf="hasAlarm" style="text-transform: uppercase;" [style.color]="colors.maxColor" class='alarm-label'>
        {{text.general.alarm | translate}}
      </div>
      <ion-icon *ngIf="deviceIcon" [style.color]="hasAlarm ? colors.maxColor : ''" class="ag-device-icon" [src]="deviceIcon"></ion-icon>
    </div>

    <div *ngIf="isPaid == true && sensor" class="device-section" class="ag-values-row flex-auto" fxFlex fxLayout="row"> 
      <div *ngIf="sensor.isTemperatureType" class="ag-value-row" fxLayout="row" fxLayoutAlign="start center" > 
        <ag-sensor-value type='TEMPERATURE' [value]="sensor.temperatureData?.value" ></ag-sensor-value>
      </div>
      <div *ngIf="isPaid == true && sensor.isMoistureType" class="ag-value-row" fxLayout="row" fxLayoutAlign="start center" > 
        <ag-sensor-value *ngIf="alwaysHumidity && sensor.key == 'ambient'" type='MOISTURE' [value]="sensor.humidityData?.value"></ag-sensor-value>
        <ag-sensor-value *ngIf="!alwaysHumidity || sensor.key != 'ambient'" [cropType]="sensor.cropType" type='MOISTURE' [value]="sensor.moistureData?.value" ></ag-sensor-value>
      </div>
    </div>
  </div>

  <div *ngIf="isPaid == true" style="height: 100px; pointer-events: none;" class="ag-flot-row flex-auto" fxFlex fxLayout="column"> 
    <ag-plot fxFlex fxLayout="column" [grid]="flotSettings.grid" [axis]="flotSettings.axes" [entity]="device.id" [timeseries]="timeseries"></ag-plot>
  </div>
  
  <div *ngIf="isPaid == true" class="device-section" fxLayout="row">
    <div *ngFor="let sensor of sensors" 
         [class]="{active: isActiveSensor(sensor)}" 
         (click)="$event.stopPropagation(); onClickSensor($event, sensor)" class="sensor-button">
          <div class="sensor-button-text">{{sensor.title | translate}}</div>
    </div>
    <div *ngIf="sensor && sensor.latestTs" class="time-value">
      <ag-time-since [ts]="sensor.latestTs"></ag-time-since>
    </div>
  </div>
</div>

  `,
  styleUrls: ['../farmer-devices/farmer-device-item.scss']
})
export class FarmerCellularSpearItem {

  @Input() device: Device | Asset
  @Input() hasAlarm: boolean
  @Output() onClick = new EventEmitter<Device>();
  private ctrl: FarmerCellularSpear
  public sensor: FarmerDeviceSensor
  sensors: FarmerDeviceSensor[] = []
  name: string
  
  alwaysHumidity = false

  timeseries = []

  emc_values: Index<string> = {}

  text = TEXT
  colors = agTypes.colors
  sampleTemp = ''
  sampleEmc = ''
  sampleMoisture = '-'
  ambientTemp = ''
  ambientEmc = ''
  ambientMoisture = '-'

  sampleUpdateTime = 0
  ambientUpdateTime = 0

  tempHistoryUnit = ''
  loadingState = new LoadingState()

  moistureCrop = ''
  flotSettings = {
    shadowSize: 0,
    axes: [
      {
        position: 'left' as POSITION, showLabels: false
      },
      {
        showLabels: false,
        alignTicksWithAxis: 1,
        position: 'right' as POSITION,
      },
      {
        position: 'bottom' as POSITION,
        showLabels: false
      }
    ],
    grid: {
      outlineWidth: 0,
      margin: 5,
      minBorderMargin: 0,
      borderColor: 'transparent'
    },
    smoothLines: true
  };

  flotKeySettings = {
    lineWidth: 1
  };
  legendConfig = {};

  cropNames = [];

  settings: ISpearSettings = {
    name: ''
  }
  deviceIcon
  isLoaded

  _sensorSubscription: Subscription
  _subscriptions: Subscription[] = []
  listen (s: Subscription) { this._subscriptions.push(s) }
  ngOnDestroy () { 
    if (this._sensorSubscription) this._sensorSubscription.unsubscribe()
    this._subscriptions.map(s => s.unsubscribe()); 
    this._subscriptions = [] 
  }
  
  constructor (
    private farmer: FarmerService,
    private payment: PaymentService,
    private alarmService: AlarmService,
    private translateService: TranslateService,
    private profile: ProfileService
  ) {}
  
  get cropLabel () {
    return CropLabel(this.sensor.cropType)
  }

  onClickSensor (evt, sensor) {
    //console.log('CLICK SENSOR', evt, sensor)
    evt.stopPropagation()
    this.setActiveSensor(sensor)
    return false
  }

  //isLoaded () { return this.ctrl.isLoaded() }

  deviceHasCrop () { return deviceHasCrop(this.device.type) }
  
  setActiveSensor (sensor: FarmerDeviceSensor) {
    this.sensor = sensor
    
    //this.alwaysHumidity = sensor.key == 'ambient' && this.profileSettings.is_ambient_humidity
    
    //console.warn('set active sensor', sensor)
    if (this._sensorSubscription) this._sensorSubscription.unsubscribe()
    this._sensorSubscription = this.sensor.onDataUpdate.subscribe(sensor => {
      this.onSensorUpdated()
    })
    this.onSensorUpdated()
  }

  onSensorUpdated () {
    this.timeseries = []
    
    if (this.sensor.hasTemperature()) {
      this.timeseries.push({
        name: this.sensor.temperatureKey(),
        label: this.sensor.name + " Temp.",
        color: agTypes.colors.maxColor
      })
    }
    
    if (this.sensor.hasMoisture()) {
      this.timeseries.push({
        name: this.sensor.moistureKey(),
        label: this.sensor.name + "Moisture.",
        color: agTypes.colors.minColor,
        yaxis: 2
      })
    }
  }

  isActiveSensor (sensor: FarmerDeviceSensor) {
    return this.sensor && this.sensor.key == sensor.key
  }

  initSpear () {
    //console.warn('INIT SPEAR ITEM', this.ctrl.model._updateId, this.device.name, this.ctrl.model.settings.label, this.ctrl.settings)
    this.deviceIcon = deviceIcon(this.device.type)
    this.name = this.ctrl.settings.label
    this.sensors = this.ctrl.sensors
    if (!this.sensor && this.ctrl.sensors[0]) {
      this.setActiveSensor(this.ctrl.sensors[0])
    }
    this.isLoaded = true
    this.loadingState.success()
  }
  
  isPaid = null
  
  //profileSettings: IProfileSettings
  ngOnInit () {
    //console.warn('card init')
    this.ctrl = this.farmer.getCellularSpear(this.device as Device)
    this.listen(
      this.ctrl.model.onUpdate.subscribe(x => { this.initSpear() })
    )
    this.listen(this.profile.settings.subscribe(profileSettings => {
      this.alwaysHumidity = profileSettings.is_ambient_humidity
      console.log('profile settings updated', this.alwaysHumidity)
      this.initSpear()
    }))
    
    this.listen(
      this.payment.subscribe(() => {
        this.isPaid = this.payment.getEntityState(this.device)?.active
      } )
    )
    
    if (this.ctrl.model.isLoaded()) this.initSpear()
  }

  getCropName (cropNameId) {
      if (this.cropNames && this.cropNames.length) {
          for (let i = 0; i < this.cropNames.length; i++) {
              if (this.cropNames[i].nameId === cropNameId) {
                  return this.cropNames[i].value;
              }
          }
      }
      return "-";
  }

  //getDeviceIcon() {  }
  getDevicePic() { return deviceImage(this.device.type) }
}